<template>
    <div>
        <label :for="`upload-common-${id}`" class="btn" :style="{ cursor: disabled ? 'pointer' : 'not-allowed' }" :disabled="disabled"
            ><span><i class="fa fa-upload" aria-hidden="true"></i></span> {{ $props.title }}</label
        >
        <input v-if="disabled" type="file" :id="`upload-common-${id}`" ref="fileInput" hidden @change="handleFile" />
    </div>
</template>

<script>
import { campaignMethods } from '@/state/helpers';

export default {
    props: {
        id: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: 'Upload a file'
        },
        disabled: {
            type: Boolean,
            default: true
        }
    },
    data() {
        return {
            fileInfo: {
                originName: '',
                extension: '',
                fileSize: 0
            }
        };
    },

    methods: {
        ...campaignMethods,

        handleFile() {
            const fileInput = this.$refs.fileInput;
            this.displayFileInfo(fileInput);
        },

        displayFileInfo(receivedFile) {
            if (receivedFile.files.length > 0) {
                const file = receivedFile.files[0];
                this.fileInfo.originName = file.name;
                this.fileInfo.extension = file.name.split('.').pop(); // Lấy phần mở rộng của tệp
                this.fileInfo.type = file.type; // Lấy phần mở rộng của tệp
                this.fileInfo.fileSize = (file.size / 1024).toFixed(2); // Chuyển đổi size sang KB và làm tròn đến 2 chữ số thập phân
                let query = `origin_name=${this.fileInfo.originName}&extension=${this.fileInfo.extension}&file_size=${parseInt(this.fileInfo.fileSize)}&type=${this.fileInfo.type}`;
                this.uploadFile(query).then((data) => {
                    if (data.code == 200) {
                        data.data.origin_name = this.fileInfo.originName;
                        data.data.extension = this.fileInfo.extension;
                        data.data.fileSize = this.fileInfo.fileSize;
                        this.$emit('dataFile', data.data);
                        receivedFile.value = '';
                        const formData = new FormData();
                        formData.append('file', file);
                        var requestOptions = {
                            method: 'PUT',
                            body: file
                        };
                        fetch(data.data.url, requestOptions)
                            .then((response) => response.text())
                            .then((result) => console.log(result))
                            .catch((error) => console.log('error', error));
                    }
                });
            }
        }
    }
};
</script>

<style lang="scss" scoped>
label {
    display: inline-block;
    color: white;
    cursor: pointer;
}
.btn {
    color: #ffff;
    background-color: #409aff;
    border-radius: 5px;
    margin-bottom: 0;
    opacity: 0.8;
    &:hover {
        opacity: 1;
    }
}
</style>
