var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"no-close-on-backdrop":"","id":"modal-client","title":"新規登録","title-class":"font-18","scrollable":"","hide-footer":""}},[_c('form',{staticClass:"needs-validation",attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.formSubmitClient.apply(null, arguments)}}},[_c('div',{staticClass:"form-group row align-items-center"},[_c('label',{staticClass:"col-sm-3 mb-0"},[_vm._v(_vm._s(_vm.$t('client.name'))),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-sm-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formClient.name),expression:"formClient.name"}],staticClass:"form-control",class:{
                        'is-invalid': _vm.submittedClient && _vm.$v.formClient.name.$error
                    },attrs:{"id":"validationCustom05","type":"text"},domProps:{"value":(_vm.formClient.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formClient, "name", $event.target.value)}}}),(_vm.submittedClient && _vm.$v.formClient.name.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.formClient.name.required)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.required', { field: _vm.$t('client.name') })))]):_vm._e(),(!_vm.$v.formClient.name.maxLength)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.max255', { field: _vm.$t('client.name') })))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group row align-items-center"},[_c('label',{staticClass:"col-sm-3"},[_vm._v(_vm._s(_vm.$t('client.kana_name'))),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-sm-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formClient.kana_name),expression:"formClient.kana_name"}],staticClass:"form-control",class:{
                        'is-invalid': _vm.submittedClient && _vm.$v.formClient.kana_name.$error
                    },attrs:{"id":"validationCustom05","type":"text"},domProps:{"value":(_vm.formClient.kana_name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formClient, "kana_name", $event.target.value)}}}),(_vm.submittedClient && _vm.$v.formClient.kana_name.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.formClient.kana_name.required)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.required', { field: _vm.$t('client.kana_name') })))]):_vm._e(),(!_vm.$v.formClient.kana_name.katakana && _vm.$v.formClient.kana_name.maxLength)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('validateField.incorrectFormat', { field: _vm.$t('client.kana_name') })))]):_vm._e(),(!_vm.$v.formClient.kana_name.maxLength)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.max255', { field: _vm.$t('client.kana_name') })))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group row align-items-center"},[_c('label',{staticClass:"col-sm-3"},[_vm._v(_vm._s(_vm.$t('client.postal_code'))),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-sm-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formClient.postal_code),expression:"formClient.postal_code"}],staticClass:"form-control",class:{
                        'is-invalid': _vm.submittedClient && _vm.$v.formClient.postal_code.$error
                    },attrs:{"id":"validationCustom05","autocomplete":"off","type":"text"},domProps:{"value":(_vm.formClient.postal_code)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.formClient, "postal_code", $event.target.value)},_vm.inputCode]}}),(_vm.submittedClient && _vm.$v.formClient.postal_code.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.formClient.postal_code.required)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.required', { field: _vm.$t('client.postal_code') })))]):_vm._e(),(!_vm.$v.formClient.postal_code.maxLength)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.max20', { field: _vm.$t('client.postal_code') })))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group row align-items-center"},[_c('label',{staticClass:"col-sm-3"},[_vm._v(_vm._s(_vm.$t('client.province'))),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-sm-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formClient.province),expression:"formClient.province"}],staticClass:"form-control",class:{
                        'is-invalid': _vm.submittedClient && _vm.$v.formClient.province.$error
                    },attrs:{"id":"validationCustom05","autocomplete":"off","type":"text"},domProps:{"value":(_vm.formClient.province)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.formClient, "province", $event.target.value)},_vm.inputAddress]}}),(_vm.submittedClient && _vm.$v.formClient.province.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.formClient.province.required)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.required', { field: _vm.$t('client.province') })))]):_vm._e(),(!_vm.$v.formClient.province.maxLength)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.max255', { field: _vm.$t('client.province') })))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group row align-items-center"},[_c('label',{staticClass:"col-sm-3"},[_vm._v(_vm._s(_vm.$t('client.address_1'))),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-sm-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formClient.address_1),expression:"formClient.address_1"}],staticClass:"form-control",class:{
                        'is-invalid': _vm.submittedClient && _vm.$v.formClient.address_1.$error
                    },attrs:{"id":"validationCustom05","type":"text","placeholder":"市区町村"},domProps:{"value":(_vm.formClient.address_1)},on:{"input":[function($event){if($event.target.composing)return;_vm.$set(_vm.formClient, "address_1", $event.target.value)},_vm.inputAddress]}}),(_vm.submittedClient && _vm.$v.formClient.address_1.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.formClient.address_1.required)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.required', { field: _vm.$t('client.address_1') })))]):_vm._e(),(!_vm.$v.formClient.address_1.maxLength)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.max255', { field: _vm.$t('client.address_1') })))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group row align-items-center"},[_c('label',{staticClass:"col-sm-3"},[_vm._v("担当者"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-sm-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formClient.namePic),expression:"formClient.namePic"}],staticClass:"form-control",class:{
                        'is-invalid': _vm.submittedClient && _vm.$v.formClient.namePic.$error
                    },attrs:{"id":"validationCustom05","type":"text"},domProps:{"value":(_vm.formClient.namePic)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formClient, "namePic", $event.target.value)}}}),(_vm.submittedClient && _vm.$v.formClient.namePic.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.formClient.namePic.required)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.required', { field: _vm.$t('client.namePic') })))]):_vm._e(),(!_vm.$v.formClient.namePic.maxLength)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.max255', { field: _vm.$t('client.namePic') })))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group row align-items-center"},[_c('label',{staticClass:"col-sm-3 mw--form"},[_vm._v("部署")]),_c('div',{staticClass:"col-sm-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formClient.department),expression:"formClient.department"}],staticClass:"form-control",class:{
                        'is-invalid': _vm.submittedClient && _vm.$v.formClient.department.$error
                    },attrs:{"type":"text"},domProps:{"value":(_vm.formClient.department)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formClient, "department", $event.target.value)}}}),(_vm.submittedClient && _vm.$v.formClient.department.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.formClient.department.maxLength)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.max255', { field: '部署' })))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group row align-items-center"},[_c('label',{staticClass:"col-sm-3 mw--form",attrs:{"for":"validationCustom07"}},[_vm._v("メールアドレス")]),_c('div',{staticClass:"col-sm-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formClient.email),expression:"formClient.email"}],staticClass:"form-control",class:{
                        'is-invalid': _vm.submittedClient && _vm.$v.formClient.email.$error
                    },attrs:{"id":"validationCustom07","autocomplete":"off","type":"text"},domProps:{"value":(_vm.formClient.email)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formClient, "email", $event.target.value)}}}),(_vm.submittedClient && _vm.$v.formClient.email.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.formClient.email.maxLength)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.max255', { field: 'メールアドレス' })))]):_vm._e(),(!_vm.$v.formClient.email.email)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.incorrectFormat', { field: 'メールアドレス' })))]):_vm._e()]):_vm._e()])]),_c('div',[_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"text-center flex-fill"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('btn.register'))+" ")])])])])])])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }