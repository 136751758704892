<template>
    <b-modal no-close-on-backdrop id="modal-client" title="新規登録" title-class="font-18" scrollable hide-footer>
        <form class="needs-validation" @submit.prevent="formSubmitClient" autocomplete="off">
            <div class="form-group row align-items-center">
                <label class="col-sm-3 mb-0">{{ $t('client.name') }}<span class="text-danger">*</span></label>
                <div class="col-sm-9">
                    <input
                        id="validationCustom05"
                        v-model="formClient.name"
                        type="text"
                        class="form-control"
                        :class="{
                            'is-invalid': submittedClient && $v.formClient.name.$error
                        }"
                    />
                    <div v-if="submittedClient && $v.formClient.name.$error" class="invalid-feedback">
                        <span v-if="!$v.formClient.name.required">{{
                            $t('validateField.required', {
                                field: $t('client.name')
                            })
                        }}</span>
                        <span v-if="!$v.formClient.name.maxLength">{{
                            $t('validateField.max255', {
                                field: $t('client.name')
                            })
                        }}</span>
                    </div>
                </div>
            </div>
            <div class="form-group row align-items-center">
                <label class="col-sm-3">{{ $t('client.kana_name') }}<span class="text-danger">*</span></label>
                <div class="col-sm-9">
                    <input
                        id="validationCustom05"
                        v-model="formClient.kana_name"
                        type="text"
                        class="form-control"
                        :class="{
                            'is-invalid': submittedClient && $v.formClient.kana_name.$error
                        }"
                    />
                    <div v-if="submittedClient && $v.formClient.kana_name.$error" class="invalid-feedback">
                        <span v-if="!$v.formClient.kana_name.required">{{
                            $t('validateField.required', {
                                field: $t('client.kana_name')
                            })
                        }}</span>
                        <span v-if="!$v.formClient.kana_name.katakana && $v.formClient.kana_name.maxLength">
                            {{
                                $t('validateField.incorrectFormat', {
                                    field: $t('client.kana_name')
                                })
                            }}</span
                        >
                        <span v-if="!$v.formClient.kana_name.maxLength">{{
                            $t('validateField.max255', {
                                field: $t('client.kana_name')
                            })
                        }}</span>
                    </div>
                </div>
            </div>

            <div class="form-group row align-items-center">
                <label class="col-sm-3">{{ $t('client.postal_code') }}<span class="text-danger">*</span></label>
                <div class="col-sm-9">
                    <input
                        id="validationCustom05"
                        v-model="formClient.postal_code"
                        autocomplete="off"
                        type="text"
                        class="form-control"
                        :class="{
                            'is-invalid': submittedClient && $v.formClient.postal_code.$error
                        }"
                        @input="inputCode"
                    />
                    <div v-if="submittedClient && $v.formClient.postal_code.$error" class="invalid-feedback">
                        <span v-if="!$v.formClient.postal_code.required">{{
                            $t('validateField.required', {
                                field: $t('client.postal_code')
                            })
                        }}</span>
                        <span v-if="!$v.formClient.postal_code.maxLength">{{
                            $t('validateField.max20', {
                                field: $t('client.postal_code')
                            })
                        }}</span>
                    </div>
                </div>
            </div>

            <div class="form-group row align-items-center">
                <label class="col-sm-3">{{ $t('client.province') }}<span class="text-danger">*</span></label>
                <div class="col-sm-9">
                    <input
                        id="validationCustom05"
                        v-model="formClient.province"
                        autocomplete="off"
                        type="text"
                        class="form-control"
                        :class="{
                            'is-invalid': submittedClient && $v.formClient.province.$error
                        }"
                        @input="inputAddress"
                    />
                    <div v-if="submittedClient && $v.formClient.province.$error" class="invalid-feedback">
                        <span v-if="!$v.formClient.province.required">{{
                            $t('validateField.required', {
                                field: $t('client.province')
                            })
                        }}</span>
                        <span v-if="!$v.formClient.province.maxLength">{{
                            $t('validateField.max255', {
                                field: $t('client.province')
                            })
                        }}</span>
                    </div>
                </div>
            </div>

            <div class="form-group row align-items-center">
                <label class="col-sm-3">{{ $t('client.address_1') }}<span class="text-danger">*</span></label>
                <div class="col-sm-9">
                    <input
                        id="validationCustom05"
                        v-model="formClient.address_1"
                        type="text"
                        class="form-control"
                        :class="{
                            'is-invalid': submittedClient && $v.formClient.address_1.$error
                        }"
                        placeholder="市区町村"
                        @input="inputAddress"
                    />
                    <div v-if="submittedClient && $v.formClient.address_1.$error" class="invalid-feedback">
                        <span v-if="!$v.formClient.address_1.required">{{
                            $t('validateField.required', {
                                field: $t('client.address_1')
                            })
                        }}</span>
                        <span v-if="!$v.formClient.address_1.maxLength">{{
                            $t('validateField.max255', {
                                field: $t('client.address_1')
                            })
                        }}</span>
                    </div>
                </div>
            </div>

            <div class="form-group row align-items-center">
                <label class="col-sm-3">担当者<span class="text-danger">*</span></label>
                <div class="col-sm-9">
                    <input
                        id="validationCustom05"
                        v-model="formClient.namePic"
                        type="text"
                        class="form-control"
                        :class="{
                            'is-invalid': submittedClient && $v.formClient.namePic.$error
                        }"
                    />
                    <div v-if="submittedClient && $v.formClient.namePic.$error" class="invalid-feedback">
                        <span v-if="!$v.formClient.namePic.required">{{
                            $t('validateField.required', {
                                field: $t('client.namePic')
                            })
                        }}</span>
                        <span v-if="!$v.formClient.namePic.maxLength">{{
                            $t('validateField.max255', {
                                field: $t('client.namePic')
                            })
                        }}</span>
                    </div>
                </div>
            </div>

            <div class="form-group row align-items-center">
                <label class="col-sm-3 mw--form">部署</label>
                <div class="col-sm-9">
                    <input
                        v-model="formClient.department"
                        type="text"
                        class="form-control"
                        :class="{
                            'is-invalid': submittedClient && $v.formClient.department.$error
                        }"
                    />
                    <div v-if="submittedClient && $v.formClient.department.$error" class="invalid-feedback">
                        <span v-if="!$v.formClient.department.maxLength">{{
                            $t('validateField.max255', {
                                field: '部署'
                            })
                        }}</span>
                    </div>
                </div>
            </div>
            <div class="form-group row align-items-center">
                <label class="col-sm-3 mw--form" for="validationCustom07">メールアドレス</label>
                <div class="col-sm-9">
                    <input
                        id="validationCustom07"
                        v-model="formClient.email"
                        autocomplete="off"
                        type="text"
                        class="form-control"
                        :class="{
                            'is-invalid': submittedClient && $v.formClient.email.$error
                        }"
                    />
                    <div v-if="submittedClient && $v.formClient.email.$error" class="invalid-feedback">
                        <span v-if="!$v.formClient.email.maxLength">{{
                            $t('validateField.max255', {
                                field: 'メールアドレス'
                            })
                        }}</span>
                        <span v-if="!$v.formClient.email.email">{{
                            $t('validateField.incorrectFormat', {
                                field: 'メールアドレス'
                            })
                        }}</span>
                    </div>
                </div>
            </div>

            <div>
                <div class="d-flex justify-content-center">
                    <div class="text-center flex-fill">
                        <button type="submit" class="btn btn-primary">
                            {{ $t('btn.register') }}
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </b-modal>
</template>
<script>
import { formClient } from '@/config/var-common';
import { getAddressByZip, getPostCodeByAddress } from 'postcode-address-get';
import { regexNumber } from '@/utils/format';
import { email, maxLength, required, url } from 'vuelidate/lib/validators';
import { clientMethods } from '@/state/helpers';

export default {
    name: 'modalClient',
    props: {},
    data: function () {
        return {
            submittedClient: false,
            formClient: JSON.parse(JSON.stringify(formClient))
        };
    },
    watch: {},
    validations: {
        formClient: {
            name: { required, maxLength: maxLength(255) },
            namePic: { required, maxLength: maxLength(255) },
            phone_number: { maxLength: maxLength(20) },
            department: { maxLength: maxLength(255) },
            kana_name: {
                required,
                maxLength: maxLength(255),
                katakana() {
                    if (this.formClient.kana_name && this.formClient.kana_name.length < 255) {
                        const katakanaRegex = /^[\u30A0-\u30FF\s]+$/u;

                        if (katakanaRegex.test(this.formClient.kana_name)) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                    return true;
                }
            },
            postal_code: { required, maxLength: maxLength(20) },
            province: { required, maxLength: maxLength(255) },
            address_1: { required, maxLength: maxLength(255) },
            address_2: { maxLength: maxLength(255) },
            url: { url },
            email: { email, maxLength: maxLength(255) }
        }
    },
    methods: {
        ...clientMethods,
        inputAddress() {
            getPostCodeByAddress(this.formClient.province + this.formClient.address_1, (dataPostcode) => {
                if (dataPostcode.status == 200) this.formClient.postal_code = dataPostcode.postcode;
            });
        },
        // Client
        inputCode() {
            this.formClient.postal_code = regexNumber(this.formClient.postal_code);
            if (!this.formClient.postal_code) {
                this.formClient.province = this.formClient.address_1 = '';
            } else {
                if (this.formClient.postal_code.length < 3) return;
                getAddressByZip(this.formClient.postal_code, (address) => {
                    if (address.status == 200) {
                        this.formClient.province = address.prefecture;
                        this.formClient.address_1 = address.city + address.area;
                    }
                });
            }
        },
        // eslint-disable-next-line no-unused-vars
        formSubmitClient(e) {
            this.submittedClient = true;
            this.$v.formClient.$touch();
            if (this.$v.formClient.$invalid) {
                return;
            } else {
                this.registerClients();
            }
        },
        registerClients() {
            if (this.formClient.name) {
                this.loading = true;
                let dataObject = Object.assign(this.formClient);
                dataObject.pic = [{ name: dataObject.namePic, email: dataObject.email, department: dataObject.department }];
                this.registerClient(dataObject).then((data) => {
                    if (data.code == 200) {
                        this.$bvToast.toast(`Success`, {
                            title: `登録されました。`,
                            variant: 'success',
                            toaster: 'b-toaster-top-center',
                            solid: true
                        });
                        // this.getListMasterClients();
                        this.formClient = JSON.parse(JSON.stringify(formClient));
                        this.$bvModal.hide('modal-client');
                        this.$emit('refresh');
                    } else {
                        if (data.message === 'name.unique') {
                            this.$bvToast.toast(`Error`, {
                                title: this.$t('client.name_unique'),
                                variant: 'danger',
                                toaster: 'b-toaster-top-center',
                                solid: true
                            });
                        } else {
                            this.$bvToast.toast(`Error`, {
                                title: this.$t(data.message),
                                variant: 'danger',
                                toaster: 'b-toaster-top-center',
                                solid: true
                            });
                        }
                    }
                    this.submittedClient = false;
                    this.loading = false;
                });
            }
        }
    },
    computed: {}
};
</script>

<style scoped lang="scss"></style>
