import i18n from '@/i18n';

function initObjectPize() {
    let object = {
        id: null,
        title: '',
        errorTitle: '',
        number_of_win_prize: 0,
        number_of_people_drawing_lots: 0,
        arrayNameAndQuantity: [
            {
                prize_name: '',
                configPrizeName: {
                    errorCustomMess: '',
                    isRequired: false,
                    isMaxLength: false,
                    error: false,
                    errorField: '賞品名'
                },
                quantity: 0,
                quantity_title: 0,
                quantity_received: 0,
                quantity_remaining: 0,
                configRemaining: {
                    isNumber: true,
                    maxlength: 10,
                    error: false,
                    errorCustomMess: ''
                }
            }
        ]
    };
    return object;
}

function handleValidateArrayNameAndQuantity(arrayObjectPizes) {
    let check = true;
    arrayObjectPizes.forEach((item) => {
        if (!item.title) {
            item.errorTitle = i18n.t('validateField.required', {
                field: '賞品タイトル'
            });
            check = false;
        } else {
            if (item.title.length > 255) {
                check = false;
            }
        }
        const prizeNameSet = new Set();
        item.arrayNameAndQuantity.forEach((itemChild) => {
            if (!itemChild.prize_name) {
                itemChild.configPrizeName.error = true;
                itemChild.configPrizeName.isRequired = true;
                itemChild.configPrizeName.isMaxLength = false;
                itemChild.configPrizeName.errorCustomMess = '';
                check = false;
            } else {
                if (itemChild.prize_name.length >= 255) {
                    itemChild.configPrizeName.error = true;
                    itemChild.configPrizeName.isMaxLength = true;
                    itemChild.configPrizeName.isRequired = false;
                    itemChild.configPrizeName.errorCustomMess = '';
                    check = false;
                } else {
                    if (prizeNameSet.has(itemChild.prize_name.trim().toString().toLowerCase())) {
                        itemChild.configPrizeName.error = true;
                        itemChild.configPrizeName.errorCustomMess = '賞品名がすでに登録されています。';
                        itemChild.configPrizeName.isRequired = false;
                        itemChild.configPrizeName.isMaxLength = false;
                        check = false;
                    } else {
                        prizeNameSet.add(itemChild.prize_name.trim().toString().toLowerCase());
                    }
                }
            }
        });
    });
    return check;
}

function handleMapDataPrizes(arrayObjectPizes) {
    let mapData = arrayObjectPizes.map((item) => {
        if (item.id) {
            return {
                id: item.id,
                title: item.title,
                number_of_win_prize: Number(item.number_of_win_prize) ?? 0,
                number_of_people_drawing_lots: Number(item.number_of_people_drawing_lots) ?? 0,
                prizes: item.arrayNameAndQuantity.map((itemChild) => {
                    return {
                        id: itemChild.id,
                        name: itemChild.prize_name,
                        quantity: Number(itemChild.quantity),
                        quantity_received: Number(itemChild.quantity_received)
                    };
                })
            };
        } else {
            return {
                title: item.title,
                number_of_win_prize: Number(item.number_of_win_prize) ?? 0,
                number_of_people_drawing_lots: Number(item.number_of_people_drawing_lots) ?? 0,
                prizes: item.arrayNameAndQuantity.map((itemChild) => {
                    return {
                        name: itemChild.prize_name,
                        quantity: Number(itemChild.quantity),
                        quantity_received: Number(itemChild.quantity_received)
                    };
                })
            };
        }
    });
    return mapData ?? [];
}

export { handleValidateArrayNameAndQuantity, handleMapDataPrizes, initObjectPize };
