<template>
    <div>
        <div class="form-group row">
            <label class="col-sm-3">GA有無（LP）</label>
            <div class="col-sm-6">
                <select name="" id="" class="form-control" v-model="form.give_away">
                    <option value="0">NO</option>
                    <option value="1">YES</option>
                </select>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-sm-3">GAからの取得情報<br />（LPからの）⇒</label>
            <div class="col-sm-9"></div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3">流入先</label>
            <div class="col-sm-4">
                <input type="text" class="form-control" v-model="form.give_away_information.inflow_destination" />
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3">流入率</label>
            <div class="col-sm-4">
                <input type="text" class="form-control" v-model="form.give_away_information.inflow_rate" />
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3">離脱率</label>
            <div class="col-sm-4">
                <input type="text" class="form-control" v-model="form.give_away_information.attrition_rate" />
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3">ATELU（SNS収集ツール）<br />レポート格納</label>
            <div class="col-sm-9">
                <div class="row mb-1" v-for="(item, k) in form.campaign_sns_collections_tools" :key="k + 'sns' + item.id">
                    <div class="col-sm-6 align-items-center">
                        <a target="_blank" :href="item.path">{{ item.origin_name }}</a>
                    </div>
                    <div class="col-sm-3">
                        <button class="btn btn-sm btn-danger" @click="deleteSNS(k)"><i class="fa fa-minus"></i></button>
                    </div>
                </div>
                <div class="row">
                    <div class="col-sm-7">
                        <UploadFile @fileInput="displayFileInfo" title="ファイルアップロード" />
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3">キャッシュバックの応募者不備件数/当選者に対しての％</label>
            <div class="col-sm-4">
                <input type="text" class="form-control" v-model="form.defects_in_cash_back_applicants_rate" />
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3">SNS当選者フォーム登録なし件数/当選者に対しての％</label>
            <div class="col-sm-4">
                <input type="text" class="form-control" v-model="form.sns_winners_without_registration_rate" />
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3">LINE友達増減数</label>
            <div class="col-sm-4">
                <input type="text" class="form-control" v-model="form.line_friends_growth" />
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3">広告媒体</label>
            <div class="col-sm-4">
                <input type="text" class="form-control" v-model="form.advertising_media" />
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3">広告費</label>
            <div class="col-sm-4">
                <input type="text" class="form-control" v-model="form.advertising_cost" />
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3">広告出稿による結果</label>
            <div class="col-sm-4">
                <input type="text" class="form-control" v-model="form.advertising_result" />
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3"
                >SNSのフォロワー増加数<br />
                <small>※SNSの時に分かれば入力</small></label
            >
            <div class="col-sm-9">
                <input type="text" v-model="form.number_of_sns_followers" class="form-control" />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-sm-3">応募数</label>
            <div class="col-sm-8">
                <div class="form-group row">
                    <label class="col-sm-3">総応募数</label>
                    <div class="col-sm-5">
                        <input type="text" disabled class="form-control" v-model="form.number_of_applications.total" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3">ハガキ・郵送</label>
                    <div class="col-sm-5">
                        <input
                            type="text"
                            class="form-control"
                            @keypress="preventInputE"
                            @input="inputNumberForm('number_of_applications', 'postcard_mail')"
                            v-model="form.number_of_applications.postcard_mail"
                            :class="{
                                'is-invalid': submitted && validate.form.number_of_applications.postcard_mail.$error
                            }"
                        />
                        <div v-if="submitted && validate.form.number_of_applications.postcard_mail.$error" class="invalid-feedback">
                            <span v-if="!validate.form.number_of_applications.postcard_mail.maxLength">{{
                                $t('validateField.max20', {
                                    field: 'ハガキ・郵送'
                                })
                            }}</span>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3">web</label>
                    <div class="col-sm-5">
                        <input
                            type="text"
                            class="form-control"
                            @keypress="preventInputE"
                            @input="inputNumberForm('number_of_applications', 'web')"
                            v-model="form.number_of_applications.web"
                            :class="{
                                'is-invalid': submitted && validate.form.number_of_applications.web.$error
                            }"
                        />
                        <div v-if="submitted && validate.form.number_of_applications.web.$error" class="invalid-feedback">
                            <span v-if="!validate.form.number_of_applications.web.maxLength">{{
                                $t('validateField.max20', {
                                    field: 'web'
                                })
                            }}</span>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3">パルなび</label>
                    <div class="col-sm-5">
                        <input
                            type="text"
                            class="form-control"
                            @keypress="preventInputE"
                            @input="inputNumberForm('number_of_applications', 'palnavi')"
                            v-model="form.number_of_applications.palnavi"
                            :class="{
                                'is-invalid': submitted && validate.form.number_of_applications.palnavi.$error
                            }"
                        />
                        <div v-if="submitted && validate.form.number_of_applications.palnavi.$error" class="invalid-feedback">
                            <span v-if="!validate.form.number_of_applications.palnavi.maxLength">{{
                                $t('validateField.max20', {
                                    field: 'パルなび'
                                })
                            }}</span>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3">SNS</label>
                    <div class="col-sm-5">
                        <input
                            type="text"
                            class="form-control"
                            @keypress="preventInputE"
                            @input="inputNumberForm('number_of_applications', 'sns')"
                            v-model="form.number_of_applications.sns"
                            :class="{
                                'is-invalid': submitted && validate.form.number_of_applications.sns.$error
                            }"
                        />
                        <div v-if="submitted && validate.form.number_of_applications.sns.$error" class="invalid-feedback">
                            <span v-if="!validate.form.number_of_applications.sns.maxLength">{{
                                $t('validateField.max20', {
                                    field: 'SNS'
                                })
                            }}</span>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3">Twitter</label>
                    <div class="col-sm-5">
                        <input
                            type="text"
                            class="form-control"
                            @keypress="preventInputE"
                            @input="inputNumberForm('number_of_applications', 'twitter')"
                            v-model="form.number_of_applications.twitter"
                            :class="{
                                'is-invalid': submitted && validate.form.number_of_applications.twitter.$error
                            }"
                        />
                        <div v-if="submitted && validate.form.number_of_applications.twitter.$error" class="invalid-feedback">
                            <span v-if="!validate.form.number_of_applications.twitter.maxLength">{{
                                $t('validateField.max20', {
                                    field: 'Twitter'
                                })
                            }}</span>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3">Instagram</label>
                    <div class="col-sm-5">
                        <input
                            type="text"
                            class="form-control"
                            @keypress="preventInputE"
                            @input="inputNumberForm('number_of_applications', 'instagram')"
                            v-model="form.number_of_applications.instagram"
                            :class="{
                                'is-invalid': submitted && validate.form.number_of_applications.instagram.$error
                            }"
                        />
                        <div v-if="submitted && validate.form.number_of_applications.instagram.$error" class="invalid-feedback">
                            <span v-if="!validate.form.number_of_applications.instagram.maxLength">{{
                                $t('validateField.max20', {
                                    field: 'Instagram'
                                })
                            }}</span>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3">LINE</label>
                    <div class="col-sm-5">
                        <input
                            type="text"
                            class="form-control"
                            @keypress="preventInputE"
                            @input="inputNumberForm('number_of_applications', 'line')"
                            v-model="form.number_of_applications.line"
                            :class="{
                                'is-invalid': submitted && validate.form.number_of_applications.line.$error
                            }"
                        />
                        <div v-if="submitted && validate.form.number_of_applications.line.$error" class="invalid-feedback">
                            <span v-if="!validate.form.number_of_applications.line.maxLength">{{
                                $t('validateField.max20', {
                                    field: 'LINE'
                                })
                            }}</span>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3">無効</label>
                    <div class="col-sm-5">
                        <input
                            type="text"
                            class="form-control"
                            @keypress="preventInputE"
                            @input="inputNumberForm('number_of_applications', 'invalid')"
                            v-model="form.number_of_applications.invalid"
                            :class="{
                                'is-invalid': submitted && validate.form.number_of_applications.invalid.$error
                            }"
                        />
                        <div v-if="submitted && validate.form.number_of_applications.invalid.$error" class="invalid-feedback">
                            <span v-if="!validate.form.number_of_applications.invalid.maxLength">{{
                                $t('validateField.max20', {
                                    field: '無効'
                                })
                            }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-sm-3">問い合わせ件数</label>
            <div class="col-sm-8">
                <div class="form-group row">
                    <label class="col-sm-3">総問い合わせ数</label>
                    <div class="col-sm-5">
                        <input type="text" class="form-control" disabled v-model="form.number_of_inquiries.total" />
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3">TEL</label>
                    <div class="col-sm-5">
                        <input
                            type="text"
                            class="form-control"
                            @keypress="preventInputE"
                            @input="inputNumberForm('number_of_inquiries', 'tel')"
                            v-model="form.number_of_inquiries.tel"
                            :class="{
                                'is-invalid': submitted && validate.form.number_of_inquiries.tel.$error
                            }"
                        />
                        <div v-if="submitted && validate.form.number_of_inquiries.tel.$error" class="invalid-feedback">
                            <span v-if="!validate.form.number_of_inquiries.tel.maxLength">{{
                                $t('validateField.max20', {
                                    field: 'TEL'
                                })
                            }}</span>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3">メール</label>
                    <div class="col-sm-5">
                        <input
                            type="text"
                            class="form-control"
                            @keypress="preventInputE"
                            @input="inputNumberForm('number_of_inquiries', 'email')"
                            v-model="form.number_of_inquiries.email"
                            :class="{
                                'is-invalid': submitted && validate.form.number_of_inquiries.email.$error
                            }"
                        />
                        <div v-if="submitted && validate.form.number_of_inquiries.email.$error" class="invalid-feedback">
                            <span v-if="!validate.form.number_of_inquiries.email.maxLength">{{
                                $t('validateField.max20', {
                                    field: 'メール'
                                })
                            }}</span>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3">それ以外</label>
                    <div class="col-sm-5">
                        <input
                            type="text"
                            class="form-control"
                            @keypress="preventInputE"
                            @input="inputNumberForm('number_of_inquiries', 'number_of_cases')"
                            v-model="form.number_of_inquiries.number_of_cases"
                            :class="{
                                'is-invalid': submitted && validate.form.number_of_inquiries.number_of_cases.$error
                            }"
                        />
                        <div v-if="submitted && validate.form.number_of_inquiries.number_of_cases.$error" class="invalid-feedback">
                            <span v-if="!validate.form.number_of_inquiries.number_of_cases.maxLength">{{
                                $t('validateField.max20', {
                                    field: 'それ以外'
                                })
                            }}</span>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3">イレギュラー</label>
                    <div class="col-sm-5">
                        <input
                            type="text"
                            class="form-control"
                            @keypress="preventInputE"
                            @input="inputNumberForm('number_of_inquiries', 'irregular')"
                            v-model="form.number_of_inquiries.irregular"
                            :class="{
                                'is-invalid': submitted && validate.form.number_of_inquiries.irregular.$error
                            }"
                        />
                        <div v-if="submitted && validate.form.number_of_inquiries.irregular.$error" class="invalid-feedback">
                            <span v-if="!validate.form.number_of_inquiries.irregular.maxLength">{{
                                $t('validateField.max20', {
                                    field: 'イレギュラー'
                                })
                            }}</span>
                        </div>
                    </div>
                </div>
                <div class="form-group row">
                    <label class="col-sm-3">イレギュラー内容</label>
                    <div class="col-sm-5">
                        <input
                            type="text"
                            class="form-control"
                            v-model="form.number_of_inquiries.irregular_content"
                            :class="{
                                'is-invalid': submitted && validate.form.number_of_inquiries.irregular_content.$error
                            }"
                        />
                        <div v-if="submitted && validate.form.number_of_inquiries.irregular_content.$error" class="invalid-feedback">
                            <span v-if="!validate.form.number_of_inquiries.irregular_content.maxLength">{{
                                $t('validateField.max20', {
                                    field: 'イレギュラー内容'
                                })
                            }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { campaignMethods, masterMethods } from '@/state/helpers';
import UploadFile from '@/components/Button/upload-file.vue';
import { regexNumber } from '@/utils/format';

export default {
    name: 'tab7',
    components: { UploadFile },
    props: {
        dataObject: {
            type: Object
        },
        submitted: {
            type: Boolean,
            required: true
        },
        validate: {
            type: Object
        }
    },
    data: function () {
        return {
            form: this.dataObject,
            fileInfo: {
                originName: '',
                extension: '',
                fileSize: 0
            }
        };
    },
    watch: {
        dataObject(newValue) {
            this.form = newValue;
        }
    },
    mounted() {},
    methods: {
        ...campaignMethods,
        ...masterMethods,
        inputNumberForm(key, key1) {
            if (key1) this.form[key][key1] = regexNumber(this.form[key][key1]);
            else this.form[key] = regexNumber(this.form[key]);
        },
        preventInputE(event) {
            // Ngăn chặn ký tự "e" từ được nhập vào ô input
            if (event.key.toLowerCase() === 'e') {
                event.preventDefault();
            }
        },
        deleteSNS(idx) {
            this.form.campaign_sns_collections_tools.splice(idx, 1);
        },
        displayFileInfo(receivedFile) {
            if (receivedFile.files.length > 0) {
                const file = receivedFile.files[0];
                this.fileInfo.originName = file.name;
                this.fileInfo.extension = file.name.split('.').pop(); // Lấy phần mở rộng của tệp
                this.fileInfo.type = file.type; // Lấy phần mở rộng của tệp
                this.fileInfo.fileSize = (file.size / 1024).toFixed(2); // Chuyển đổi size sang KB và làm tròn đến 2 chữ số thập phân
                let query = `origin_name=${this.fileInfo.originName}&extension=${this.fileInfo.extension}&file_size=${parseInt(this.fileInfo.fileSize)}&type=${this.fileInfo.type}`;
                this.uploadFile(query).then((data) => {
                    if (data.code == 200) {
                        data.data.origin_name = this.fileInfo.originName;
                        data.data.extension = this.fileInfo.extension;
                        data.data.fileSize = this.fileInfo.fileSize;
                        this.form.campaign_sns_collections_tools.push(data.data);
                        receivedFile.value = '';
                        const formData = new FormData();
                        formData.append('file', file);
                        var requestOptions = {
                            method: 'PUT',
                            body: file
                        };
                        fetch(data.data.url, requestOptions)
                            .then((response) => response.text())
                            .then((result) => console.log(result))
                            .catch((error) => console.log('error', error));
                    }
                });
            }
        }
    },
    computed: {}
};
</script>

<style scoped lang="scss"></style>
