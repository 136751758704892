<script>
import moment from 'moment';
import Layout from '@/views/layouts/main';
import PageHeader from '@/components/PageHeader/main.vue';
import appConfig from '@/app.config';
import {
    keyMaster,
    masterComputed,
    authComputed,
    masterMethods,
    faqMethods,
    partnersMethods,
    agencyMethods,
    campaignMethods,
    clientMethods,
    checkPermission,
    mapDataCampaigns,
    employeeMethods,
    prizeMethods
} from '@/state/helpers';
import { maxLength, required, url } from 'vuelidate/lib/validators';
import { campaignStatus, formCampaign, listContactInformations, numberOfApplications, numberOfInquiries } from '@/config/var-common';
import { showMessage } from '@/utils/messages';
import Multiselect from 'vue-multiselect';
import ModalClient from '@/components/Campaign/modal-client.vue';
import ModalPartner from '@/components/Campaign/modal-partner.vue';
import ModalAgency from '@/components/Campaign/modal-agency.vue';
import Tab7 from '@/components/Campaign/tab7.vue';
import Tab1 from '@/components/Campaign/tab1/main.vue';
import Tab3 from '@/components/Campaign/tab3.vue';
import TabPrizes from '@/components/Campaign/TabPrize/main.vue';
import TabTools from '@/components/Campaign/TabTool/main.vue';
import LoadingIcon from '@/components/Loading/main.vue';
import Footer from '@/components/Footer/main.vue';
import MultiSelectCommon from '@/components/Select/multi/main.vue';
import { handleValidateTab1 } from '@/components/Campaign/tab1/validate.js';
import { handleValidateArrayNameAndQuantity, handleMapDataPrizes } from '@/components/Campaign/TabPrize/index.js';
import { handleDataScopePartner } from '@/components/Campaign/TabTool/index.js';
/**
 * Advanced-form component
 */
export default {
    page: {
        title: 'キャンペーン 編集',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        LoadingIcon,
        Tab3,
        Tab1,
        Tab7,
        TabPrizes,
        TabTools,
        ModalAgency,
        ModalPartner,
        ModalClient,
        Multiselect,
        Layout,
        PageHeader,
        Footer,
        MultiSelectCommon
    },
    data() {
        return {
            hashTag: {
                arrayTag: [],
                placeholder: ''
            },
            title: 'キャンペーン 編集',
            index: 1,
            personInChargeObject: null,
            saleStaffObject: {},
            sns_application_condition_1: {},
            purchase_condition_1: {},
            target_product_lists: {},
            purchase_conditions: {},
            contact_informations: {},
            progressStatus: null,
            listItemPersons: [
                {
                    id: '',
                    name: '',
                    team: ''
                },
                {
                    id: '',
                    name: '',
                    team: ''
                }
            ],
            listProjectManagement: [],
            configProjectManagement: {
                error: false,
                errorText: '',
                isRemove: false,
                loading: false,
                trackBy: 'id',
                label: 'name'
            },
            listDataPrize: [],
            listCategories: [],
            listCategoriesSub: [],
            campaignStatus: campaignStatus,
            loadingRegister: false,
            loading: false,
            items: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: 'キャンペーン一覧',
                    href: '/campaign/list'
                },
                {
                    text: 'キャンペーン 編集',
                    active: true
                }
            ],
            id: this.$route.query.id,
            configKeyword: {
                error: false,
                arrayTag: []
            },
            form: formCampaign,
            form1: formCampaign,
            listContactInformations: listContactInformations,
            listDataMasterCampaignType: [],
            listDataMasterCampaignTypeChild: [],
            listDataMasterSaleStaf: [],
            listDataMasterSaleEmploy: [],
            listDataMasterInstructorStaf: [],
            listDataMasterTeam: [],
            listDataMasterPartners: [],
            listPartnerSupport: [],
            listDataMasterAgencies: [],
            listAgenciesSupport: [],
            listDataMasterClients: [],
            listClientsSupport: [],
            listDataMasterConditionApply: [],
            listDataMasterConditionApplySNS: [],
            listDataMasterConditionPurchase1: [],
            listDataMasterConditionPurchase2: [],
            listDataMasterCategoryProductTarget: [],
            submitted: false,
            partnersObject: {},
            partnersPicObject: {},
            partner: {
                partner_id: '',
                partner_pic_id: ''
            },
            agenciesObject: {},
            agenciesPicObject: {},
            agencies: {
                agency_id: '',
                agency_pic_id: ''
            },
            clientObject: {},
            clientPicObject: {},
            clientTypeObject: {},
            clients: {
                client_id: '',
                client_pic_id: ''
            },
            listDataMasterCampaignProject: [],
            listDataCustomerTeamPic: [],
            subDataCustomerTeamPic: [null],
            phonePic: null,
            customerTeamPic: null,
            formatDateSend: 'YYYY-MM-DD HH:mm',
            arrayObjectPizes: [],
            activeTabEdit: 0,
            listDataMasterOrderScope: [],
            tableScopeOrder: [],
            isBookmarkCampaign: false,
            hasLoadingBookmark: false,
            listIPInformationItem: [],
            objScopeOrder: null
        };
    },
    watch: {
        sourceData(newValue) {
            if (newValue) this.form.source_data.id = newValue.id;
            else this.form.source_data.id = '';
        },
        sns_application_condition_1: function (newName) {
            this.selectObject(newName, 'sns_application_condition_1', 'id');
        },
        purchase_condition_1: function (newName) {
            this.selectObject(newName, 'purchase_condition_1', 'id');
        },
        personInChargeObject: function (newName) {
            this.form.person_in_charge = newName
                ? {
                      id: newName?.id ?? null
                  }
                : null;
        },
        saleStaffObject: function (newName) {
            this.selectObject(newName, 'sale_staff', 'id');
        },
        partnersObject: function (newName) {
            this.partner.partner_id = newName.id;
        },
        partnersPicObject: function (newName) {
            this.partner.partner_pic_id = newName.id;
        },
        'form.number_of_applications.postcard_mail': function () {
            this.updateTotalNumberOfApplication();
        },
        'form.number_of_applications.web': function () {
            this.updateTotalNumberOfApplication();
        },
        'form.number_of_applications.palnavi': function () {
            this.updateTotalNumberOfApplication();
        },
        'form.number_of_applications.sns': function () {
            this.updateTotalNumberOfApplication();
        },
        'form.number_of_applications.twitter': function () {
            this.updateTotalNumberOfApplication();
        },
        'form.number_of_applications.instagram': function () {
            this.updateTotalNumberOfApplication();
        },
        'form.number_of_applications.line': function () {
            this.updateTotalNumberOfApplication();
        },
        'form.number_of_applications.others': function () {
            this.updateTotalNumberOfApplication();
        },
        'form.number_of_applications.invalid': function () {
            this.updateTotalNumberOfApplication();
        },
        'form.number_of_inquiries.tel': function () {
            this.updateTotalNumberOfInquiries();
        },
        'form.number_of_inquiries.email': function () {
            this.updateTotalNumberOfInquiries();
        },
        'form.number_of_inquiries.number_of_cases': function () {
            this.updateTotalNumberOfInquiries();
        },
        'form.number_of_inquiries.irregular': function () {
            this.updateTotalNumberOfInquiries();
        },
        'hashTag.arrayTag': function (newOld) {
            this.form.hashtag = newOld;
        },

        phonePic: function (newOld) {
            this.form.phone_pic = {
                id: newOld?.id ? newOld?.id : ''
            };
        },
        customerTeamPic: function (newOld) {
            this.form.customer_team_pic = {
                id: newOld?.id ? newOld?.id : ''
            };
        }
    },
    computed: {
        ...masterComputed,
        ...authComputed,

        filteredOptions() {
            return (index) => {
                const selectedValues = this.listItemPersons.filter((value, i) => i !== index);
                let arr = JSON.parse(JSON.stringify(this.listDataMasterInstructorStaf));
                arr.forEach((option, idx) => {
                    let i = selectedValues.findIndex((x) => x?.id === option?.id || x?.id === this.personInChargeObject?.id);
                    if (i >= 0 || option?.id === this.personInChargeObject?.id) {
                        arr[idx].$isDisabled = true;
                    } else {
                        arr[idx].$isDisabled = false;
                    }
                });
                return arr;
            };
        },
        filteredOptions1() {
            return (index) => {
                const selectedValues = this.form.part_time_employees.filter((value, i) => i !== index);
                let arr = JSON.parse(JSON.stringify(this.listDataMasterSaleStaf));
                arr.forEach((option, idx) => {
                    let i = selectedValues.findIndex((x) => x.id === option.id);
                    if (i >= 0) {
                        arr[idx].$isDisabled = true;
                    } else {
                        arr[idx].$isDisabled = false;
                    }
                });
                return arr;
            };
        },

        OPTIONCUSTOMER() {
            return (index) => {
                const selectedValues = this.subDataCustomerTeamPic.filter((value, i) => i !== index);
                let arr = JSON.parse(JSON.stringify(this.listDataCustomerTeamPic));
                arr.forEach((option, idx) => {
                    let i = selectedValues.findIndex((x) => x?.id === option?.id || x?.id === this.customerTeamPic?.id);
                    if (i >= 0 || option?.id === this.customerTeamPic?.id) {
                        arr[idx].$isDisabled = true;
                    } else {
                        arr[idx].$isDisabled = false;
                    }
                });
                return arr;
            };
        }
    },
    validations: {
        listCategoriesSub: {
            $each: {
                extension: {
                    offical_name_sns: { maxLength: maxLength(255) },
                    offical_id_sns: { maxLength: maxLength(255) },
                    number_of_circulation: { maxLength: maxLength(255) },
                    company_information: { maxLength: maxLength(255) }
                }
            }
        },
        form: {
            name: { required, maxLength: maxLength(255) },
            application_date: { maxLength: maxLength(255) },
            purchase_datetime: { maxLength: maxLength(255) },
            inform: { maxLength: maxLength(255) },
            application_form: { maxLength: maxLength(255) },
            prize_shipping_period: { maxLength: maxLength(255) },
            inquiry_office: { maxLength: maxLength(255) },
            url: { url },
            number_of_applications: {
                postcard_mail: { maxLength: maxLength(20) },
                web: { maxLength: maxLength(20) },
                palnavi: { maxLength: maxLength(20) },
                sns: { maxLength: maxLength(20) },
                twitter: { maxLength: maxLength(20) },
                instagram: { maxLength: maxLength(20) },
                line: { maxLength: maxLength(20) },
                others: { maxLength: maxLength(20) },
                invalid: { maxLength: maxLength(20) }
            },
            number_of_inquiries: {
                tel: { maxLength: maxLength(20) },
                email: { maxLength: maxLength(20) },
                number_of_cases: { maxLength: maxLength(20) },
                irregular: { maxLength: maxLength(20) },
                irregular_content: { maxLength: maxLength(20) }
            },
            chatwork_rooms: {
                $each: {
                    room_name: { maxLength: maxLength(255) }
                }
            },
            zac_id: { maxLength: maxLength(255) },
            sfid: { maxLength: maxLength(255) }
        }
    },
    beforeRouteUpdate(to, from, next) {
        // Xác định nếu có tham số id trong đường dẫn mới
        const queryId = to.query.id;
        if (!queryId) {
            this.resetForm();
            this.title = 'キャンペーン新規登録';
            this.items[1].text = 'キャンペーン新規登録';
        }
        // Gọi next để tiếp tục chuyển route
        next();
    },
    mounted() {
        this.getListMasterCategoryProductTarget();
        this.getListMasterCampaignProject();
        this.getListMasterConditionPurchase1();
        this.getListMasterConditionApply();
        this.getListMasterConditionApplySNS();
        this.getListMasterCampaignType();
        this.getListMasterSaleStaf();
        this.getListMasterPartner();
        this.getListMasterAgencies();
        this.getListMasterClients();
        this.getListMasterGroup();

        this.getListMasterSaleEmploy();
        this.getListCustomerTeamPic();
        this.handleGetMasterOrderScope();
        this.handleGetIPFromMaster();

        if (this.$route.query.id) {
            this.loading = true;
            setTimeout(() => {
                this.getDetail(this.$route.query.id);
            }, 1500);
        }
        this.userInfo = JSON.parse(localStorage.getItem('user'));
    },
    methods: {
        checkPermission,
        ...masterMethods,
        ...faqMethods,
        ...partnersMethods,
        ...agencyMethods,
        ...campaignMethods,
        ...clientMethods,
        ...employeeMethods,
        ...prizeMethods,

        async getListCustomerTeamPic() {
            let query = `page=1&limit=999&sorts[id]=desc&filters[team]=${process.env.VUE_APP_CUSTOMER_TEAM_PIC}`;
            try {
                const listUserFilterTeam = await this.listEmployee(query).catch((error) => {
                    console.log('listUserFilterTeam', error);
                });
                this.listDataCustomerTeamPic = listUserFilterTeam.data;
            } catch (error) {
                console.log(error);
            }
        },

        selectObject(data, key, key1) {
            if (data) {
                if (key1) this.form[key][key1] = data.id;
                else this.form[key] = data.id;
            } else {
                if (key1) this.form[key][key1] = '';
                else this.form[key] = '';
            }
        },

        changeData() {
            let index = this.listItemPersons.findIndex((x) => x?.id === this.personInChargeObject?.id);
            if (index >= 0) {
                this.listItemPersons[index].id = '';
                this.listItemPersons[index].name = '';
                this.listItemPersons[index].team = '';
                this.filteredOptions(index);
            }
        },
        changeDataCustomer() {
            let index = this.subDataCustomerTeamPic.findIndex((x) => x?.id === this.customerTeamPic?.id);
            if (index >= 0) {
                this.subDataCustomerTeamPic[index] = null;
                this.OPTIONCUSTOMER(index);
            }
        },

        updateTotalNumberOfApplication() {
            const postcardMail = parseInt(this.form.number_of_applications.postcard_mail) || 0;
            const web = parseInt(this.form.number_of_applications.web) || 0;
            const palnavi = parseInt(this.form.number_of_applications.palnavi) || 0;
            const sns = parseInt(this.form.number_of_applications.sns) || 0;
            const twitter = parseInt(this.form.number_of_applications.twitter) || 0;
            const instagram = parseInt(this.form.number_of_applications.instagram) || 0;
            const line = parseInt(this.form.number_of_applications.line) || 0;
            const others = parseInt(this.form.number_of_applications.others) || 0;
            const invalid = parseInt(this.form.number_of_applications.invalid) || 0;
            const total = postcardMail + web + palnavi + sns + twitter + instagram + line + others + invalid;
            this.form.number_of_applications.total = total.toString();
        },
        updateTotalNumberOfInquiries() {
            const tel = parseInt(this.form.number_of_inquiries.tel) || 0;
            const email = parseInt(this.form.number_of_inquiries.email) || 0;
            const number_of_cases = parseInt(this.form.number_of_inquiries.number_of_cases) || 0;
            const irregular = parseInt(this.form.number_of_inquiries.irregular) || 0;

            const total = tel + email + number_of_cases + irregular;
            this.form.number_of_inquiries.total = total.toString();
        },
        getDetail(id) {
            this.loading = true;
            this.detailCampaign(id).then((data) => {
                if (!data) return;

                if (!data.sns_application_condition_1) {
                    data.sns_application_condition_1 = {
                        id: ''
                    };
                } else {
                    this.sns_application_condition_1 = data.sns_application_condition_1;
                }

                if (!data.purchase_condition_1) {
                    data.purchase_condition_1 = {
                        id: ''
                    };
                } else {
                    this.purchase_condition_1 = data.purchase_condition_1;
                }
                if (!data.application_condition_1) {
                    data.application_condition_1 = {
                        id: ''
                    };
                } else {
                    this.application_condition_1 = data.application_condition_1;
                }
                if (!data.application_condition_2) {
                    data.application_condition_2 = {
                        id: ''
                    };
                } else {
                    this.application_condition_2 = data.application_condition_2;
                }

                this.isBookmarkCampaign = this.currentUser.campaign_bookmarks?.some((item) => item.id === data.id) ?? false;

                let arrayDataContactInfor = [];
                if (data.contact_informations) {
                    data.contact_informations.forEach((item) => {
                        this.listContactInformations.forEach((listI) => {
                            if (listI.child && listI.child.length > 0) {
                                let indexC = listI.child.findIndex((c) => {
                                    return c.id == item.type?.id;
                                });
                                if (indexC >= 0) {
                                    if (item.extension) {
                                        this.contact_informations[item.type.id] = item.extension ? item.extension.content : '';
                                        arrayDataContactInfor.push(item.type.id);
                                    }
                                }
                            }
                        });

                        let index = this.listContactInformations.findIndex((x) => {
                            return x.id == item?.type?.id;
                        });

                        if (index >= 0) {
                            if (item.type.value === '電話') {
                                this.contact_informations[item.type.id] =
                                    data?.phone_setting && data?.phone_setting?.disable_edit_field?.field_pre_order_phone_numbers
                                        ? data.phone_setting.pre_order_phone_numbers ?? data.phone_setting.reservation
                                        : '';
                            } else {
                                if (item.extension) {
                                    this.contact_informations[item.type.id] = item.extension ? item.extension.content : '';
                                }
                            }

                            arrayDataContactInfor.push(item.type.id);
                        }
                    });
                }

                let arrayDataTargetProduct = [];
                if (data.target_product_lists) {
                    data.target_product_lists.forEach((item) => {
                        let index = this.listDataMasterCategoryProductTarget.findIndex((x) => {
                            return x.id == item.id;
                        });
                        if (index >= 0) {
                            if (item.note) {
                                this.target_product_lists[item.id] = item.note ? item.note.detail : '';
                            }
                            arrayDataTargetProduct.push(item.id);
                        }
                    });
                }

                let arrayDataPurchase = [];
                if (data.purchase_conditions) {
                    data.purchase_conditions.forEach((item) => {
                        let index = this.listDataMasterConditionPurchase1.findIndex((x) => {
                            return x.id == item.id;
                        });
                        if (index >= 0) {
                            if (item.note) {
                                this.purchase_conditions[item.id] = item.note ? item.note.detail : '';
                            }
                            arrayDataPurchase.push(item.id);
                        }
                    });
                }

                let content_projects = [];
                if (data.content_projects) {
                    data.content_projects.forEach((item) => {
                        content_projects.push(item.id);
                    });
                }

                let platforms = [];
                if (data.platforms) {
                    data.platforms.forEach((item) => {
                        platforms.push(item.id);
                    });
                }

                let tool_useds = [];
                if (data.tool_useds) {
                    data.tool_useds.forEach((item) => {
                        tool_useds.push(item.id);
                    });
                }

                let types = [];
                if (data.types) {
                    data.types.forEach((item) => {
                        types.push(item.id);
                    });
                }

                let templates = [];
                if (data.templates) {
                    data.templates.forEach((item) => {
                        templates.push(item.id);
                    });
                }

                if (data.part_time_employees.length == 0) {
                    data.part_time_employees.push({
                        id: '',
                        name: ''
                    });
                }
                this.saleStaffObject = data.sale_staff;
                this.personInChargeObject = data.person_in_charge;

                if (data.responsible_sub_persons.length) {
                    if (data.responsible_sub_persons.length === 1) {
                        if (data.responsible_sub_persons[0].sub_person_position === 1) {
                            this.listItemPersons = [
                                ...data.responsible_sub_persons,
                                {
                                    id: '',
                                    name: '',
                                    team: ''
                                }
                            ];
                        } else {
                            this.listItemPersons = [
                                {
                                    id: '',
                                    name: '',
                                    team: ''
                                },
                                ...data.responsible_sub_persons
                            ];
                        }
                    } else if (data.responsible_sub_persons.length === 2) {
                        this.listItemPersons = data.responsible_sub_persons;
                    }
                }

                let idex = campaignStatus.findIndex((x) => x.id == data.progress_status);
                if (idex >= 0) {
                    this.progressStatus = campaignStatus[idex];
                }
                data.clients.forEach((item) => {
                    item.client_id = item.id;
                });
                data.agencies.forEach((item) => {
                    item.agency_id = item.id;
                });
                data.configCampaignStartDateTime = this.form.configCampaignStartDateTime;
                data.configCampaignEndDateTime = this.form.configCampaignEndDateTime;
                data.configSecretariatStartDateTime = this.form.configSecretariatStartDateTime;
                data.configSecretariatEndDateTime = this.form.configSecretariatEndDateTime;
                data.configUrlPublishTimeFrom = this.form.configUrlPublishTimeFrom;
                data.configUrlPublishTimeTo = this.form.configUrlPublishTimeTo;
                data.configReceiptExpirationDate = this.form.configReceiptExpirationDate;

                this.form = {
                    ...data,
                    give_away: data?.give_away ?? '0',
                    source_data: data?.source_data ?? {
                        type: '',
                        id: ''
                    },
                    number_of_applications: data?.number_of_applications ?? numberOfApplications,
                    number_of_inquiries: data?.number_of_inquiries ?? numberOfInquiries,
                    campaign_type: data?.campaign_type ? data.campaign_type.id : '',
                    sns_application_condition_1: data?.sns_application_condition_1 ?? {
                        id: ''
                    },
                    purchase_condition_1: data?.purchase_condition_1 ?? {
                        id: ''
                    },
                    application_condition_1: data?.application_condition_1 ?? {
                        id: ''
                    },
                    application_condition_2: data?.application_condition_2 ?? {
                        id: ''
                    },
                    team: data?.team ?? {
                        id: ''
                    },
                    sale_staff: data?.sale_staff ?? {
                        id: ''
                    }
                };

                this.form.chatwork_rooms =
                    data.chatwork_rooms.length > 0
                        ? data.chatwork_rooms.map((item) => {
                              return {
                                  room_id: item.room_id ?? '',
                                  room_name: item.room_name ?? ''
                              };
                          })
                        : [
                              {
                                  room_id: '',
                                  room_name: ''
                              }
                          ];
                this.form.chat_rooms = data.chat_rooms;

                this.handleMapDate(data);

                this.form.target_product_lists = arrayDataTargetProduct;
                this.form.contact_informations = arrayDataContactInfor;
                this.form.purchase_conditions = arrayDataPurchase;
                this.form.content_projects = content_projects;
                this.form.platforms = platforms;
                this.form.tool_useds = tool_useds;
                this.form.types = types;
                this.form.templates = templates;
                this.form.receipt_expiration_date = data.receipt_expiration_date ?? '';
                this.form.give_away_information = data.give_away_information
                    ? data.give_away_information
                    : {
                          inflow_destination: '',
                          inflow_rate: '',
                          attrition_rate: ''
                      };
                this.form.event_prizes = data.event_prizes;
                this.arrayObjectPizes =
                    data.event_prizes.length > 0
                        ? data.event_prizes.map((eventPrize) => {
                              return {
                                  id: eventPrize.id,
                                  title: eventPrize.title,
                                  errorTitle: '',
                                  number_of_win_prize: eventPrize.number_of_win_prize,
                                  number_of_people_drawing_lots: eventPrize.number_of_people_drawing_lots,
                                  arrayNameAndQuantity:
                                      eventPrize.prizes.length > 0
                                          ? eventPrize.prizes.map((pz) => {
                                                return {
                                                    id: pz.id,
                                                    prize_name: pz.name ?? '',
                                                    configPrizeName: {
                                                        errorCustomMess: '',
                                                        isRequired: false,
                                                        isMaxLength: false,
                                                        error: false,
                                                        errorField: '賞品名'
                                                    },
                                                    quantity: pz.quantity ?? 0,
                                                    quantity_title: pz.quantity * eventPrize.number_of_win_prize ?? 0,
                                                    quantity_received: pz.quantity_received ?? 0,
                                                    quantity_remaining: pz.quantity_received - pz.quantity * eventPrize.number_of_win_prize ?? 0,
                                                    configRemaining: {
                                                        isNumber: true,
                                                        maxlength: 10,
                                                        error: false,
                                                        errorCustomMess: ''
                                                    }
                                                };
                                            })
                                          : [
                                                {
                                                    prize_name: '',
                                                    configPrizeName: {
                                                        errorCustomMess: '',
                                                        isRequired: false,
                                                        isMaxLength: false,
                                                        error: false,
                                                        errorField: '賞品名'
                                                    },
                                                    quantity: 0,
                                                    quantity_title: 0,
                                                    quantity_received: 0,
                                                    quantity_remaining: 0,
                                                    configRemaining: {
                                                        isNumber: true,
                                                        maxlength: 10,
                                                        error: false,
                                                        errorCustomMess: ''
                                                    }
                                                }
                                            ]
                              };
                          })
                        : [];
                this.phonePic = data.phone_pic ?? '';
                this.customerTeamPic = data.customer_team_pic ?? '';
                this.configKeyword.arrayTag = data.keywords;
                this.hashTag.arrayTag = data.hashtag;
                this.getListMasterInstructorStaf();
                this.getDataCampaignTye();

                this.getListDataMasterConditionPurchase2();
                this.form.categories.forEach((item) => {
                    this.listCategories.push(item.category_id);
                    let index = this.listDataMasterCampaignTypeChild.findIndex((x) => {
                        return x.id == item.category_id;
                    });
                    let check = this.listDataMasterCampaignTypeChild.findIndex((x) => {
                        return (
                            x.id == item.category_id && (x.value == 'Twitter' || x.value == 'Instagram' || x.value == 'LINE' || x.value == 'Tiktok')
                        );
                    });
                    if (index >= 0) {
                        let obj = {
                            category_id: item.category_id,
                            application_condition_1: item.application_condition_1,
                            application_condition_2_id: item.application_condition_2_id,
                            listCondition1: this.listDataMasterCampaignTypeChild[index].data,
                            listCondition2: [],
                            hashtag: item.hashtag ?? [],
                            configHashTag: {
                                arrayTag: item.hashtag ?? []
                            },
                            extension: item.extension
                                ? item.extension
                                : {
                                      offical_name_sns: '',
                                      offical_id_sns: '',
                                      number_of_circulation: '',
                                      company_information: '',
                                      other_type_data: '',
                                      application_method: ''
                                  },
                            checkSNS: check
                        };

                        let i = obj.listCondition1.findIndex((x) => {
                            return obj.application_condition_1 && x.id == obj.application_condition_1.id;
                        });
                        if (i >= 0) {
                            obj.listCondition2 = obj.listCondition1[i].data;
                        }
                        this.listCategoriesSub.push(obj);
                        this.listCategoriesSub = this.listCategoriesSub.sort((a, b) => a.category_id - b.category_id);
                    }
                });
                this.subDataCustomerTeamPic = data.customer_sub_person.length > 0 ? data.customer_sub_person : [null];
                this.form.information_ips =
                    data.information_ips?.map((item) => {
                        return {
                            master_id: item.id,
                            value: item.value,
                            note: {
                                detail: item?.note?.detail ?? ''
                            }
                        };
                    }) ?? [];
                this.loading = false;
            });
        },

        getListMasterCampaignProject() {
            this.listMaster(keyMaster['campaign.project_details']).then((data) => {
                this.listDataMasterCampaignProject = data;
            });
        },
        getListMasterCategoryProductTarget() {
            this.listMaster(keyMaster['campaign.product_target']).then((data) => {
                this.listDataMasterCategoryProductTarget = data;
            });
        },
        getListMasterConditionPurchase1() {
            this.listMaster(keyMaster['campaign.purchase_conditions_1']).then((data) => {
                this.listDataMasterConditionPurchase1 = data;
            });
        },
        getListDataMasterConditionPurchase2() {
            this.listDataMasterConditionPurchase2 = [];
            let index = this.listDataMasterConditionPurchase1.findIndex((x) => {
                return x.id == this.purchase_condition_1.id;
            });
            if (index >= 0) {
                this.listDataMasterConditionPurchase2 = this.listDataMasterConditionPurchase1[index].data;
            }
        },
        getListMasterConditionApply() {
            this.listMaster(keyMaster['campaign.conditions_applying_admission']).then((data) => {
                this.listDataMasterConditionApply = data;
            });
        },
        getListMasterConditionApplySNS() {
            this.listMaster(keyMaster['campaign.conditions_applying_sns']).then((data) => {
                this.listDataMasterConditionApplySNS = data;
            });
        },
        getListMasterCampaignType() {
            this.listMaster(keyMaster['campaign.type']).then((data) => {
                this.listDataMasterCampaignType = data;
            });
        },

        getListMasterSaleStaf() {
            this.listMaster('users?filters[role]=4' + '&limit=999').then((data) => {
                this.listDataMasterSaleStaf = data;
            });
        },
        getListMasterSaleEmploy() {
            this.listMaster('users?filters[role]=2' + '&limit=999').then((data) => {
                this.listDataMasterSaleEmploy = data;
            });
        },
        async getListMasterInstructorStaf() {
            let query = `page=1&limit=999&sorts[id]=desc&filters[team]=${process.env.VUE_APP_CUSTOMER_TEAM_PIC}`;
            let arrTwoInstructorStaf = [];
            try {
                const listUserFilterRole = await this.listMaster('users?filters[role]=3,1&limit=999').catch((error) => {
                    console.log('listUserFilterRole', error);
                });
                const listUserFilterTeam = await this.listEmployee(query).catch((error) => {
                    console.log('listUserFilterTeam', error);
                });
                arrTwoInstructorStaf = [...listUserFilterRole, ...listUserFilterTeam.data];
                this.listProjectManagement = listUserFilterRole;
                this.listDataMasterInstructorStaf = arrTwoInstructorStaf.reduce((acc, current) => {
                    const x = acc.find((item) => item.id === current.id);
                    if (!x) {
                        acc.push(current);
                    }
                    return acc;
                }, []);
            } catch (error) {
                console.log(error);
            }
        },
        getListMasterGroup() {
            this.listMaster('employee.department').then((data) => {
                let index = data.findIndex((x) => x.id == process.env.VUE_APP_INSTRUCTOR);
                if (index >= 0) this.listDataMasterTeam = data[index].data;
            });
        },
        getListMasterPartner() {
            this.listMaster('all/partners').then((data) => {
                this.listDataMasterPartners = data;
            });
        },
        getListMasterAgencies() {
            this.listMaster('all/agencies').then((data) => {
                this.listDataMasterAgencies = data;
            });
        },
        getListMasterClients() {
            this.listMaster('all/clients').then((data) => {
                this.listDataMasterClients = data;
            });
        },
        getListMasterPartnerSupport() {
            if (this.partnersObject.id) {
                this.listPartnersSupport(this.partnersObject.id).then((data) => {
                    data.data.forEach((item, k) => {
                        let index = this.form.partners.findIndex((x) => x.partner_pic_id == item.id);
                        if (index >= 0) {
                            data.data[k].$isDisabled = true;
                        }
                    });
                    this.listPartnerSupport = data.data;
                });
            }
        },

        // eslint-disable-next-line no-unused-vars
        formSubmit(e) {
            this.submitted = true;
            this.$v.$touch();
            this.form = Object.assign({}, this.form);
            let check = handleValidateTab1(
                this.form,
                this.form.configCampaignStartDateTime,
                this.form.configCampaignEndDateTime,
                this.form.configSecretariatStartDateTime,
                this.form.configSecretariatEndDateTime,
                this.form.configUrlPublishTimeFrom,
                this.form.configUrlPublishTimeTo,
                this.form.configReceiptExpirationDate,
                this.contact_informations
            );

            let errorTabPrize = handleValidateArrayNameAndQuantity(this.arrayObjectPizes);

            if (this.arrayObjectPizes.length > 0) {
                let selectedTabPrize = 4;
                if (!errorTabPrize && this.activeTabEdit !== selectedTabPrize) {
                    showMessage(8080, this.$bvToast, '賞品情報のタブを編集してください。');
                }
            }

            if (this.$v.form.$invalid || this.$v.listCategoriesSub.$each.$invalid || this.configKeyword.error || !check || !errorTabPrize) {
                return;
            } else {
                this.register();
            }
        },

        register() {
            if (this.loadingRegister) return;
            this.loadingRegister = true;
            this.loading = true;
            if (this.form.name) {
                this.form.chatwork_rooms = this.form.chatwork_rooms.filter((item) => {
                    return item.room_id && item.room_name;
                });

                // campaign_start_date
                this.form.campaign_start_date = this.form.campaign_start_time
                    ? moment(this.form.campaign_start_date + ' ' + this.form.campaign_start_time, this.formatDateSend).format(this.formatDateSend)
                    : this.form.campaign_start_date ?? '';

                // campaign_end_date
                this.form.campaign_end_date = this.form.campaign_end_time
                    ? moment(this.form.campaign_end_date + ' ' + this.form.campaign_end_time, this.formatDateSend).format(this.formatDateSend)
                    : this.form.campaign_end_date ?? '';

                // secretariat_start_date
                this.form.secretariat_start_date = this.form.secretariat_start_time
                    ? moment(this.form.secretariat_start_date + ' ' + this.form.secretariat_start_time, this.formatDateSend).format(
                          this.formatDateSend
                      )
                    : this.form.secretariat_start_date ?? '';

                // secretariat_end_date
                this.form.secretariat_end_date = this.form.secretariat_end_time
                    ? moment(this.form.secretariat_end_date + ' ' + this.form.secretariat_end_time, this.formatDateSend).format(this.formatDateSend)
                    : this.form.secretariat_end_date ?? '';

                // url_publish_time_from
                this.form.url_publish_time_from = this.form.url_publish_time_from_time
                    ? moment(this.form.url_publish_time_from + ' ' + this.form.url_publish_time_from_time, this.formatDateSend).format(
                          this.formatDateSend
                      )
                    : this.form.url_publish_time_from ?? '';

                // url_publish_time_to
                this.form.url_publish_time_to = this.form.url_publish_time_to_time
                    ? moment(this.form.url_publish_time_to + ' ' + this.form.url_publish_time_to_time, this.formatDateSend).format(
                          this.formatDateSend
                      )
                    : this.form.url_publish_time_to ?? '';

                this.form.event_prizes = handleMapDataPrizes(this.arrayObjectPizes);

                this.form.campaign_master_order_scopes = this.handleMapDataSever(this.tableScopeOrder, this.form.campaign_master_order_scopes);

                this.form.campaign_master_order_scope_partner = handleDataScopePartner(this.tableScopeOrder, 'register');

                // Tạo một bản sao của đối tượng formCampaign
                const formCampaignCopy = this.handleClearDateBeforeSend(this.form);
                let objectData = mapDataCampaigns(
                    formCampaignCopy,
                    this.configKeyword,
                    this.listItemPersons,
                    this.listDataPrize,
                    this.target_product_lists,
                    this.purchase_conditions,
                    this.contact_informations,
                    this.listCategoriesSub,
                    this.subDataCustomerTeamPic
                );

                this.updateCampaign(objectData).then((data) => {
                    if (data.code == 200) {
                        showMessage(data.code, this.$bvToast, 'キャンペーンが更新されました。');
                        this.getDetail(this.$route.query.id);
                    } else {
                        showMessage(data.code, this.$bvToast, this.$t(data.message));
                        this.handleMapDate(this.form);
                        this.loading = false;
                    }

                    this.loadingRegister = false;
                });
            }
        },

        handleMapDate(argData) {
            this.form.campaign_start_time = argData.campaign_start_date ? moment(argData.campaign_start_date).format('HH:mm') : '';
            this.form.campaign_end_time = argData.campaign_end_date ? moment(argData.campaign_end_date).format('HH:mm') : '';
            this.form.secretariat_start_time = argData.secretariat_start_date ? moment(argData.secretariat_start_date).format('HH:mm') : '';
            this.form.secretariat_end_time = argData.secretariat_end_date ? moment(argData.secretariat_end_date).format('HH:mm') : '';
            this.form.url_publish_time_from_time = argData.url_publish_time_from ? moment(argData.url_publish_time_from).format('HH:mm') : '';
            this.form.url_publish_time_to_time = argData.url_publish_time_to ? moment(argData.url_publish_time_to).format('HH:mm') : '';

            this.form.campaign_start_date = argData.campaign_start_date ? moment(argData.campaign_start_date).format('YYYY-MM-DD') : '';
            this.form.campaign_end_date = argData.campaign_end_date ? moment(argData.campaign_end_date).format('YYYY-MM-DD') : '';
            this.form.secretariat_start_date = argData.secretariat_start_date ? moment(argData.secretariat_start_date).format('YYYY-MM-DD') : '';
            this.form.secretariat_end_date = argData.secretariat_end_date ? moment(argData.secretariat_end_date).format('YYYY-MM-DD') : '';
            this.form.url_publish_time_from = argData.url_publish_time_from ? moment(argData.url_publish_time_from).format('YYYY-MM-DD') : '';
            this.form.url_publish_time_to = argData.url_publish_time_to ? moment(argData.url_publish_time_to).format('YYYY-MM-DD') : '';
        },

        handleClearDateBeforeSend(dataOrigin) {
            const formCampaignCopy = { ...dataOrigin };
            if (!formCampaignCopy.isShowPublicTime) {
                formCampaignCopy.url_publish_time_from = '';
                formCampaignCopy.url_publish_time_to = '';
            }

            if (!formCampaignCopy.isShowReceiptExpirationDate) {
                delete formCampaignCopy.receipt_expiration_date;
            }
            // Xóa các thuộc tính không cần thiết
            delete formCampaignCopy.campaign_start_time;
            delete formCampaignCopy.campaign_end_time;
            delete formCampaignCopy.secretariat_start_time;
            delete formCampaignCopy.secretariat_end_time;
            delete formCampaignCopy.url_publish_time_from_time;
            delete formCampaignCopy.url_publish_time_to_time;

            delete formCampaignCopy.configCampaignStartDateTime;
            delete formCampaignCopy.configCampaignEndDateTime;
            delete formCampaignCopy.configSecretariatStartDateTime;
            delete formCampaignCopy.configSecretariatEndDateTime;
            delete formCampaignCopy.configUrlPublishTimeFrom;
            delete formCampaignCopy.configUrlPublishTimeTo;
            delete formCampaignCopy.configReceiptExpirationDate;
            delete formCampaignCopy.isShowPublicTime;
            delete formCampaignCopy.isShowReceiptExpirationDate;
            delete formCampaignCopy.receipt_expiration_date_d;
            delete formCampaignCopy.receipt_expiration_date_m;
            delete formCampaignCopy.receipt_expiration_date_y;

            return formCampaignCopy;
        },

        addEmploy() {
            this.form.part_time_employees.push({
                id: ''
            });
        },
        removeEmploy(k) {
            this.form.part_time_employees.splice(k, 1);
        },

        getDataCampaignTye() {
            this.listCategoriesSub = [];
            this.listCategories = [];
            let index = this.listDataMasterCampaignType.findIndex((x) => {
                return x.id == this.form.campaign_type;
            });
            if (index >= 0) {
                this.listDataMasterCampaignTypeChild = this.listDataMasterCampaignType[index].data;
            }
        },

        handleMapDataSever(tableScopeOrder, campaign_master_order_scopes) {
            let dataAfterMap = [];
            tableScopeOrder.forEach((itemTableScope, indexTableScope) => {
                if (campaign_master_order_scopes.length <= 0) {
                    dataAfterMap.push({
                        large_item: itemTableScope.id,
                        medium_items: itemTableScope.data.map((itemLv2) => {
                            return {
                                medium_item: itemLv2.id,
                                small_items: itemLv2.data.map((itemLv3) => {
                                    return {
                                        small_item: itemLv3.id,
                                        detail_items: itemLv3.data?.map((itemLv4) => itemLv4.id) ?? []
                                    };
                                })
                            };
                        })
                    });
                } else {
                    if (campaign_master_order_scopes[indexTableScope]?.id) {
                        if (itemTableScope.id === campaign_master_order_scopes[indexTableScope].large_item.id) {
                            dataAfterMap.push({
                                ...(campaign_master_order_scopes[indexTableScope]?.id !== null && {
                                    id: campaign_master_order_scopes[indexTableScope].id
                                }),
                                large_item: itemTableScope.id,
                                medium_items: itemTableScope.data.map((itemLv2, idxLv2) => {
                                    const mediumItemId = campaign_master_order_scopes[indexTableScope].medium_items[idxLv2]?.id ?? null;
                                    return {
                                        ...(mediumItemId !== null && { id: mediumItemId }),
                                        medium_item: itemLv2.id,
                                        small_items: itemLv2.data.map((itemLv3, idxLv3) => {
                                            const smallItemId =
                                                campaign_master_order_scopes[indexTableScope].medium_items[idxLv2]?.small_items[idxLv3]?.id ?? null;
                                            return {
                                                ...(smallItemId !== null && { id: smallItemId }),
                                                small_item: itemLv3.id,
                                                detail_items: itemLv3.data?.map((itemLv4) => itemLv4.id) ?? []
                                            };
                                        })
                                    };
                                })
                            });
                        } else {
                            dataAfterMap.push({
                                large_item: itemTableScope.id,
                                medium_items: itemTableScope.data.map((itemLv2) => {
                                    return {
                                        medium_item: itemLv2.id,
                                        small_items: itemLv2.data.map((itemLv3) => {
                                            return {
                                                small_item: itemLv3.id,
                                                detail_items: itemLv3.data?.map((itemLv4) => itemLv4.id) ?? []
                                            };
                                        })
                                    };
                                })
                            });
                        }
                    } else {
                        dataAfterMap.push({
                            large_item: itemTableScope.id,
                            medium_items: itemTableScope.data.map((itemLv2) => {
                                return {
                                    medium_item: itemLv2.id,
                                    small_items: itemLv2.data.map((itemLv3) => {
                                        return {
                                            small_item: itemLv3.id,
                                            detail_items: itemLv3.data?.map((itemLv4) => itemLv4.id) ?? []
                                        };
                                    })
                                };
                            })
                        });
                    }
                }
            });
            this.handleCheckConditionTel(dataAfterMap);
            return dataAfterMap;
        },

        handleCheckConditionTel(campaign_master_order_scopes) {
            this.form.has_tel_phone_setting = false;
            if (campaign_master_order_scopes && campaign_master_order_scopes.length > 0) {
                let getDetails = [];
                campaign_master_order_scopes.forEach((largeItem) => {
                    largeItem.medium_items.forEach((mediumItem) => {
                        mediumItem.small_items.forEach((smallItem) => {
                            getDetails.push(`${smallItem.small_item}`);
                        });
                    });
                });
                // 2 là id của điều kiện "電話" (TEL)
                this.form.has_tel_phone_setting = getDetails.includes('2');
            }
        },

        async handleGetMasterOrderScope() {
            try {
                const resultMasterOrder = await this.getMasterOrderScope();
                if (resultMasterOrder.code !== 200) return;
                this.listDataMasterOrderScope = resultMasterOrder.data;
            } catch (error) {
                console.log(error);
            }
        },

        handleEmitSaveScopeOrder(newScopeOrder) {
            this.objScopeOrder = newScopeOrder;
        },

        async handleGetIPFromMaster() {
            try {
                const dataIPRespone = await this.listMaster(keyMaster['campaign.information_ip']);
                this.listIPInformationItem = dataIPRespone ?? [];
            } catch (error) {
                console.log(error);
            }
        },

        handleGetDetail() {
            this.$router.push({
                path: '/campaign/form/view',
                query: {
                    id: this.$route.query.id
                }
            });
        },

        async handleSetBookmarkCampaign() {
            if (this.hasLoadingBookmark) return;
            this.hasLoadingBookmark = true;
            this.isBookmarkCampaign = true;
            try {
                this.userInfo.campaign_bookmarks.push({ id: this.form.id, name: this.form.name });
                localStorage.setItem('user', JSON.stringify(this.userInfo));
                await this.setBookmarkCampaign(this.form.id);
            } catch (error) {
                console.log(error);
            } finally {
                this.hasLoadingBookmark = false;
            }
        },
        async handleUnsetBookmarkCampaign() {
            if (this.hasLoadingBookmark) return;
            this.hasLoadingBookmark = true;
            this.isBookmarkCampaign = false;
            try {
                this.userInfo.campaign_bookmarks = this.userInfo.campaign_bookmarks.filter((item) => item.id !== this.form.id);
                localStorage.setItem('user', JSON.stringify(this.userInfo));
                await this.unsetBookmarkCampaign(this.form.id);
            } catch (error) {
                console.log(error);
            } finally {
                this.hasLoadingBookmark = false;
            }
        }
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div v-if="!loading">
            <div class="col-lg-12">
                <div class="card mb-0">
                    <div class="card-header">
                        <h5>{{ form.name }}</h5>
                    </div>
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-12 custom--tabss">
                                <form class="needs-validation" @submit.prevent="formSubmit" autocomplete="off">
                                    <b-tabs justified nav-class="nav-tabs-custom" content-class="p-3 text-muted" v-model="activeTabEdit">
                                        <b-tab active class="border-0">
                                            <template v-slot:title>
                                                <span class="d-inline-block d-sm-none">
                                                    <i class="fas fa-home"></i>
                                                </span>
                                                <span class="">基本情報</span>
                                            </template>
                                            <tab1
                                                :listDataMasterCampaignType="listDataMasterCampaignType"
                                                :listDataMasterCampaignProject="listDataMasterCampaignProject"
                                                :listCategoriesS.sync="listCategories"
                                                :listDataMasterCampaignTypeChilds.sync="listDataMasterCampaignTypeChild"
                                                :listDataMasterSaleStaf.sync="listDataMasterSaleStaf"
                                                :listItemPersons.sync="listItemPersons"
                                                :contact_informations.sync="contact_informations"
                                                :listContactInformations="listContactInformations"
                                                :hashTag.sync="hashTag"
                                                :configKeyword.sync="configKeyword"
                                                :listCategoriesSubs.sync="listCategoriesSub"
                                                :progressStatusS.sync="progressStatus"
                                                :campaignStatus="campaignStatus"
                                                :submitted.sync="submitted"
                                                :validate.sync="$v"
                                                :data-object.sync="form"
                                                :purchase_conditions.sync="purchase_conditions"
                                                :listDataMasterConditionPurchase1="listDataMasterConditionPurchase1"
                                                :target_product_lists.sync="target_product_lists"
                                                :listDataMasterCategoryProductTarget="listDataMasterCategoryProductTarget"
                                                :listDataMasterConditionApplySNS="listDataMasterConditionApplySNS"
                                                :snsApplicationCondition1.sync="sns_application_condition_1"
                                                :listDataMasterOrderScope="listDataMasterOrderScope"
                                                :listIPInformationItem="listIPInformationItem"
                                                @saveScopeOrder="handleEmitSaveScopeOrder"
                                            ></tab1>
                                        </b-tab>

                                        <b-tab>
                                            <template v-slot:title>
                                                <span class="d-inline-block d-sm-none">
                                                    <i class="far fa-envelope"></i>
                                                </span>
                                                <span class="d-none d-sm-inline-block">受注範囲＆パートナー＆ツール</span>
                                            </template>
                                            <div>
                                                <TabTools
                                                    :dataForm="form"
                                                    :dataTabTools.sync="tableScopeOrder"
                                                    :objScopeOrder="objScopeOrder"
                                                    :dataMasterOrderScope="listDataMasterOrderScope"
                                                />
                                            </div>
                                        </b-tab>

                                        <b-tab>
                                            <template v-slot:title>
                                                <span class="d-inline-block d-sm-none">
                                                    <i class="far fa-user"></i>
                                                </span>
                                                <span class="d-none d-sm-inline-block">お客様情報</span>
                                            </template>
                                            <tab3
                                                :clientTypeObjects.sync="clientTypeObject"
                                                :clientsS="clients"
                                                :clientObjects.sync="clientObject"
                                                :clientPicObjects.sync="clientPicObject"
                                                :agenciesS.sync="agencies"
                                                :agenciesObjects.sync="agenciesObject"
                                                :agenciesPicObjects.sync="agenciesPicObject"
                                                :listClientsSupportsS.sync="listClientsSupport"
                                                :listDataMasterAgencies.sync="listDataMasterAgencies"
                                                :listAgenciesSupportsS.sync="listAgenciesSupport"
                                                :listDataMasterClientsS.sync="listDataMasterClients"
                                                :submitted.sync="submitted"
                                                :validate.sync="$v"
                                                :data-object.sync="form"
                                            ></tab3>
                                        </b-tab>

                                        <b-tab>
                                            <template v-slot:title>
                                                <span class="d-inline-block d-sm-none">
                                                    <i class="far fa-envelope"></i>
                                                </span>
                                                <span class="d-none d-sm-inline-block">事務局担当情報</span>
                                            </template>

                                            <div class="form-group row">
                                                <label class="col-sm-3">営業担当</label>
                                                <div class="col-sm-8">
                                                    <MultiSelectCommon
                                                        :id="`select-sale-staff-object`"
                                                        :value.sync="saleStaffObject"
                                                        :options="listDataMasterSaleEmploy"
                                                        :config="{
                                                            error: false,
                                                            isRemove: false,
                                                            trackBy: 'id',
                                                            label: 'name'
                                                        }"
                                                    />
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3">ディレクション担当</label>
                                                <div class="col-sm-8">
                                                    <div class="row">
                                                        <div class="col-sm-8">
                                                            <div class="d-flex align-items-center">
                                                                <span style="width: 60px; min-width: fit-content">メイン</span>
                                                                <div class="col-sm-12">
                                                                    <MultiSelectCommon
                                                                        :id="`select-person-in-charge-object`"
                                                                        :value.sync="personInChargeObject"
                                                                        :options="listDataMasterInstructorStaf"
                                                                        :config="{
                                                                            error: false,
                                                                            isRemove: false,
                                                                            trackBy: 'id',
                                                                            label: 'name'
                                                                        }"
                                                                        @select="changeData()"
                                                                    />
                                                                </div>

                                                                <span style="min-width: fit-content">{{
                                                                    personInChargeObject?.role?.id == 1
                                                                        ? $t('administrator')
                                                                        : personInChargeObject?.team?.value ?? ''
                                                                }}</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-4"></div>
                                                    </div>
                                                    <div class="row mt-2" v-for="(pTem, k) in listItemPersons" :key="'p' + k">
                                                        <div class="col-sm-8">
                                                            <div class="d-flex align-items-center" style="gap: 5px">
                                                                <span style="width: 60px; min-width: fit-content">サブ{{ k + 1 }}</span>
                                                                <div class="position-relative col-sm-12">
                                                                    <multiselect
                                                                        :showPointer="false"
                                                                        :showNoResults="false"
                                                                        :allow-empty="false"
                                                                        selectedLabel=""
                                                                        deselectLabel=""
                                                                        placeholder=""
                                                                        selectLabel=""
                                                                        :showNoOptions="false"
                                                                        track-by="id"
                                                                        label="name"
                                                                        v-model="listItemPersons[k]"
                                                                        :options="filteredOptions(k)"
                                                                    ></multiselect>
                                                                    <i
                                                                        v-if="listItemPersons[k].id"
                                                                        @click="
                                                                            $event.stopPropagation();
                                                                            listItemPersons[k].id = '';
                                                                            listItemPersons[k].name = '';
                                                                            listItemPersons[k].team = '';
                                                                            filteredOptions(k);
                                                                        "
                                                                        style="
                                                                            cursor: pointer;
                                                                            position: absolute;
                                                                            right: 25px;
                                                                            top: 11px;
                                                                            background: #fff;
                                                                            z-index: 9;
                                                                        "
                                                                        class="fa fa-window-close"
                                                                    ></i>
                                                                </div>

                                                                <span style="min-width: fit-content">{{
                                                                    listItemPersons[k]?.role?.id == 1
                                                                        ? $t('administrator')
                                                                        : listItemPersons[k]?.team?.value ?? ''
                                                                }}</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-4"></div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3">案件統括</label>
                                                <div class="col-sm-8">
                                                    <div class="d-flex align-items-center">
                                                        <div class="flex-fill">
                                                            <MultiSelectCommon
                                                                :id="`select-project-management-object`"
                                                                :value.sync="form.project_management"
                                                                :options="listProjectManagement"
                                                                :config="configProjectManagement"
                                                            />
                                                        </div>

                                                        <span class="ml-3" style="min-width: fit-content">{{
                                                            form.project_management?.role?.id == 1
                                                                ? $t('administrator')
                                                                : form.project_management?.team?.value ?? ''
                                                        }}</span>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3">エスカレー担当</label>
                                                <div class="col-sm-8">
                                                    <div class="row">
                                                        <div class="col-sm-8">
                                                            <div class="d-flex align-items-center">
                                                                <span style="width: 60px; min-width: fit-content">メイン</span>
                                                                <div class="col-sm-12">
                                                                    <MultiSelectCommon
                                                                        :id="`select-customer-team-pic`"
                                                                        :value.sync="customerTeamPic"
                                                                        :options="listDataCustomerTeamPic"
                                                                        :config="{
                                                                            error: false,
                                                                            isRemove: false,
                                                                            trackBy: 'id',
                                                                            label: 'name'
                                                                        }"
                                                                        @select="changeDataCustomer()"
                                                                    />
                                                                </div>

                                                                <span class="ml-3" style="min-width: fit-content">{{
                                                                    customerTeamPic?.team?.value ?? ''
                                                                }}</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-4"></div>
                                                    </div>
                                                    <div class="row mt-2" v-for="(spm, k) in subDataCustomerTeamPic" :key="'p' + k">
                                                        <div class="col-sm-8">
                                                            <div class="d-flex align-items-center" style="gap: 12px">
                                                                <span style="width: 60px; min-width: fit-content">サブ</span>
                                                                <div class="col-sm-12">
                                                                    <MultiSelectCommon
                                                                        :id="`select-sub-customer-team-pic${k}`"
                                                                        :value.sync="subDataCustomerTeamPic[k]"
                                                                        :options="OPTIONCUSTOMER(k)"
                                                                        :config="{
                                                                            error: false,
                                                                            isRemove: false,
                                                                            trackBy: 'id',
                                                                            label: 'name'
                                                                        }"
                                                                        @remove="OPTIONCUSTOMER(k)"
                                                                    />
                                                                </div>

                                                                <span style="min-width: fit-content">{{
                                                                    subDataCustomerTeamPic[k]?.team?.value ?? ''
                                                                }}</span>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-4"></div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="form-group row">
                                                <label class="col-sm-3">アルバイト</label>
                                                <div class="col-sm-9">
                                                    <div class="row mt-2" v-for="(eTem, k) in form.part_time_employees" :key="'e' + k">
                                                        <div class="col-sm-10">
                                                            <div class="d-flex w-100">
                                                                <div class="w-100" style="position: relative">
                                                                    <multiselect
                                                                        :showPointer="false"
                                                                        :showNoResults="false"
                                                                        :allow-empty="false"
                                                                        selectedLabel=""
                                                                        deselectLabel=""
                                                                        placeholder=""
                                                                        selectLabel=""
                                                                        :showNoOptions="false"
                                                                        @close="getListMasterPartnerSupport"
                                                                        track-by="id"
                                                                        label="name"
                                                                        v-model="form.part_time_employees[k]"
                                                                        :options="filteredOptions1(k)"
                                                                    ></multiselect>

                                                                    <i
                                                                        v-if="form.part_time_employees[k].id"
                                                                        @click="
                                                                            $event.stopPropagation();
                                                                            filteredOptions1(k);
                                                                            form.part_time_employees[k].id = '';
                                                                            form.part_time_employees[k].name = '';
                                                                        "
                                                                        style="
                                                                            cursor: pointer;
                                                                            position: absolute;
                                                                            right: 15px;
                                                                            z-index: 9;
                                                                            top: 11px;
                                                                            background: #fff;
                                                                        "
                                                                        class="fa fa-window-close"
                                                                    ></i>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="col-sm-2">
                                                            <button
                                                                type="button"
                                                                class="btn btn-outline-info btn-sm"
                                                                style="border-radius: 50%"
                                                                v-if="k <= 0"
                                                                @click="addEmploy()"
                                                            >
                                                                <i class="fa fa-plus"></i>
                                                            </button>
                                                            <button
                                                                type="button"
                                                                class="btn btn-sm btn-outline-danger"
                                                                style="border-radius: 50%"
                                                                v-if="k > 0"
                                                                @click="removeEmploy(k)"
                                                            >
                                                                <i class="fa fa-minus"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </b-tab>

                                        <b-tab>
                                            <template v-slot:title>
                                                <span class="d-inline-block d-sm-none">
                                                    <i class="far fa-envelope"></i>
                                                </span>
                                                <span class="d-none d-sm-inline-block">賞品情報</span>
                                            </template>
                                            <div>
                                                <TabPrizes :dataForm="form" :dataPrizes.sync="arrayObjectPizes" />
                                            </div>
                                        </b-tab>

                                        <b-tab>
                                            <template v-slot:title>
                                                <span class="d-inline-block d-sm-none">
                                                    <i class="far fa-envelope"></i>
                                                </span>
                                                <span class="d-none d-sm-inline-block">実施結果登録</span>
                                            </template>
                                            <tab7 :submitted.sync="submitted" :validate.sync="$v" :data-object.sync="form"></tab7>
                                        </b-tab>
                                    </b-tabs>
                                </form>
                                <ModalClient @refresh="getListMasterClients()"></ModalClient>
                                <ModalPartner @refresh="getListMasterPartner()"></ModalPartner>
                                <ModalAgency @refresh="getListMasterAgencies()"></ModalAgency>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer>
                <div class="d-flex justify-content-center align-items-center">
                    <div style="font-size: 20px; cursor: pointer">
                        <i v-if="isBookmarkCampaign" class="ri-bookmark-fill" style="color: #157efb" @click="handleUnsetBookmarkCampaign"></i>
                        <i v-else class="ri-bookmark-line" @click="handleSetBookmarkCampaign"></i>
                    </div>

                    <div class="text-center flex-fill box__button--custom">
                        <button
                            type="button"
                            class="btn btn-light"
                            @click="
                                $router.push({
                                    path: '/campaign/list'
                                })
                            "
                        >
                            {{ $t('btn.cancel') }}
                        </button>
                        <button
                            v-if="form.is_approved == 1 && checkPermission('campaign.edit')"
                            :disabled="form.is_locked == 1 || loadingRegister"
                            type="submit"
                            @click="formSubmit"
                            class="btn btn-primary mx-3"
                        >
                            {{ $t('btn.save') }}
                        </button>
                        <button
                            v-if="form.is_approved == 1 && checkPermission('campaign.edit')"
                            :disabled="form.is_locked == 1 || loadingRegister"
                            type="submit"
                            @click="handleGetDetail"
                            class="btn btn-info"
                        >
                            {{ $t('btn.detail') }}
                        </button>
                    </div>
                    <div>最終編集者：{{ form.updated_by ? form.updated_by.name : '' }}</div>
                </div>
            </Footer>
        </div>
        <div class="d-flex justify-content-center" v-else>
            <LoadingIcon />
        </div>
    </Layout>
</template>
<style scoped>
.nav-tabs-custom .nav-item {
    white-space: nowrap;
}

.page-content.mystyle .form__body--card {
    max-width: 100%;
    margin-left: 0;
}

.custom__table--sticky td:last-child {
    position: relative;
    inset: 0;
}
</style>
