<template>
    <b-modal no-close-on-backdrop id="modal-agency" title="新規登録" title-class="font-18" scrollable hide-footer>
        <form class="needs-validation" @submit.prevent="formSubmitAgency" autocomplete="off">
            <div class="form-group row align-items-center">
                <label class="col-sm-3 mw--form"
                    >{{ $t('agency.name') }}
                    <span class="text-danger">*</span>
                </label>
                <div class="col-sm-9">
                    <input
                        id="validationCustom05"
                        v-model="formAgency.name"
                        type="text"
                        class="form-control"
                        :class="{
                            'is-invalid': submittedAgency && $v.formAgency.name.$error
                        }"
                    />
                    <div v-if="submittedAgency && $v.formAgency.name.$error" class="invalid-feedback">
                        <span v-if="!$v.formAgency.name.required">{{
                            $t('validateField.required', {
                                field: $t('agency.name')
                            })
                        }}</span>
                        <span v-if="!$v.formAgency.name.maxLength">
                            {{
                                $t('validateField.max255', {
                                    field: $t('agency.name')
                                })
                            }}
                        </span>
                    </div>
                </div>
            </div>

            <div class="form-group row align-items-center">
                <label class="col-sm-3 mw--form"
                    >{{ $t('agency.kana_nameCompany') }}
                    <span class="text-danger">*</span>
                </label>
                <div class="col-sm-9">
                    <input
                        id="validationCustom05"
                        v-model="formAgency.kana_name"
                        type="text"
                        class="form-control"
                        :class="{
                            'is-invalid': submittedAgency && $v.formAgency.kana_name.$error
                        }"
                    />
                    <div
                        v-if="submittedAgency && $v.formAgency.kana_name.$error"
                        class="invalid-feedback"
                        :class="{
                            'is-invalid': submittedAgency && $v.formAgency.kana_name.$error
                        }"
                    >
                        <span v-if="!$v.formAgency.kana_name.required">{{
                            $t('validateField.required', {
                                field: $t('agency.kana_nameCompany')
                            })
                        }}</span>
                        <span v-if="!$v.formAgency.kana_name.katakana">{{
                            $t('validateField.incorrectFormat', {
                                field: $t('agency.kana_nameCompany')
                            })
                        }}</span>
                        <span v-if="!$v.formAgency.kana_name.maxLength">
                            {{
                                $t('validateField.max255', {
                                    field: $t('agency.kana_nameCompany')
                                })
                            }}
                        </span>
                    </div>
                </div>
            </div>

            <div class="form-group row align-items-center">
                <label class="col-sm-3 mw--form"
                    >{{ $t('agency.types') }}
                    <span class="text-danger">*</span>
                </label>
                <div class="col-sm-9">
                    <div class="d-flex flex-wrap">
                        <div class="custom-control custom-checkbox mr-4 py-1" style="min-width: 100px" v-for="item in listTypeAgency" :key="item.id">
                            <input type="checkbox" v-model="formAgency.types" :id="`check${item.id}`" :value="item.id" class="custom-control-input" />
                            <label :for="`check${item.id}`" class="custom-control-label">{{ item.value }}</label>
                        </div>
                    </div>
                    <span
                        :class="{
                            'is-invalid': submittedAgency && $v.formAgency.types.$error
                        }"
                    ></span>
                    <div v-if="submittedAgency && $v.formAgency.types.$error" class="invalid-feedback">
                        <span v-if="!$v.formAgency.types.required">{{
                            $t('validateField.required', {
                                field: $t('agency.types')
                            })
                        }}</span>
                    </div>
                </div>
            </div>

            <div class="form-group row align-items-center">
                <label class="col-sm-3">担当者<span class="text-danger">*</span></label>
                <div class="col-sm-9">
                    <input
                        id="validationCustom05"
                        v-model="formAgency.namePic"
                        type="text"
                        class="form-control"
                        :class="{
                            'is-invalid': submittedAgency && $v.formAgency.namePic.$error
                        }"
                    />
                    <div v-if="submittedAgency && $v.formAgency.namePic.$error" class="invalid-feedback">
                        <span v-if="!$v.formAgency.namePic.required">{{
                            $t('validateField.required', {
                                field: $t('agency.namePic')
                            })
                        }}</span>
                        <span v-if="!$v.formAgency.namePic.maxLength">{{
                            $t('validateField.max255', {
                                field: $t('agency.namePic')
                            })
                        }}</span>
                    </div>
                </div>
            </div>

            <div>
                <div class="d-flex justify-content-center">
                    <div class="text-center flex-fill">
                        <button type="submit" class="btn btn-primary ml-3">
                            {{ $t('btn.register') }}
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </b-modal>
</template>
<script>
import { formAgency } from '@/config/var-common';
import { maxLength, required } from 'vuelidate/lib/validators';
import { agencyMethods, keyMaster, masterMethods } from '@/state/helpers';

export default {
    name: 'modalAgency',
    props: {},
    data: function () {
        return {
            submittedAgency: false,
            formAgency: JSON.parse(JSON.stringify(formAgency)),
            listTypeAgency: []
        };
    },
    watch: {},
    validations: {
        formAgency: {
            name: { required, maxLength: maxLength(255) },
            namePic: { required, maxLength: maxLength(255) },
            kana_name: {
                required,
                maxLength: maxLength(255),
                katakana() {
                    if (this.formAgency.kana_name && this.formAgency.kana_name.length < 255) {
                        const katakanaRegex = /^[\u30A0-\u30FF\s]+$/u;

                        if (katakanaRegex.test(this.formAgency.kana_name)) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                    return true;
                }
            },
            postal_code: {
                maxLength: maxLength(20)
            },
            province: { maxLength: maxLength(255) },
            address_1: { maxLength: maxLength(255) },
            address_2: { maxLength: maxLength(255) },
            url: { maxLength: maxLength(255) },
            phone_number: { maxLength: maxLength(20) },
            types: { required }
        }
    },
    mounted() {
        this.getListTypeAgency();
    },
    methods: {
        ...agencyMethods,
        ...masterMethods,
        getListTypeAgency() {
            this.listMaster(keyMaster['agency.type']).then((data) => {
                this.listTypeAgency = data;
            });
        },
        formSubmitAgency() {
            this.submittedAgency = true;
            this.$v.formAgency.$touch();
            if (this.$v.formAgency.$invalid) {
                return;
            } else {
                this.registerAgencys();
            }
        },

        async registerAgencys() {
            if (this.formAgency.name && !this.loading) {
                this.loading = true;
                let dataObject = Object.assign(this.formAgency);
                dataObject.person_in_charges = [{ name: dataObject.namePic }];
                this.registerAgency(dataObject).then((data) => {
                    if (data.code == 200) {
                        this.$bvToast.toast(`Success`, {
                            title: `登録されました。`,
                            variant: 'success',
                            toaster: 'b-toaster-top-center',
                            solid: true
                        });
                        this.formAgency = JSON.parse(JSON.stringify(formAgency));
                        this.$bvModal.hide('modal-agency');
                        this.$emit('refresh');
                    } else {
                        if (data.message === 'name.unique') {
                            this.$bvToast.toast(`Error`, {
                                title: this.$t('agency.name_unique'),
                                variant: 'danger',
                                toaster: 'b-toaster-top-center',
                                solid: true
                            });
                        } else {
                            this.$bvToast.toast(`Error`, {
                                title: this.$t(data.message),
                                variant: 'danger',
                                toaster: 'b-toaster-top-center',
                                solid: true
                            });
                        }
                    }
                    this.submittedAgency = false;
                    this.loading = false;
                });
            }
        }
    },
    computed: {}
};
</script>

<style scoped lang="scss"></style>
