<template>
    <div>
        <ModalCommon :id="idInfo" :config="configModalCommonInfo" @save="handleSaveInfomation()" @close="handleCloseInfomation()">
            <form class="needs-validation" autocomplete="off">
                <div class="form-group row">
                    <label class="col-sm-3 mw--form" for="shippingCompany">{{ this.$t('pageCampaign.tabPrize.shippingCompany') }}</label>
                    <div class="col-sm-9">
                        <InputText :id="`shippingCompany`" :model.sync="formInfo.delivery_company" :config.sync="formInfo.configDeliveryCompany" />
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-3 mw--form" for="trackingNumber">{{ this.$t('pageCampaign.tabPrize.trackingNumber') }}</label>
                    <div class="col-sm-9">
                        <InputText :id="`trackingNumber`" :model.sync="formInfo.tracking_number" :config.sync="formInfo.configTrakingNumber" />
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-3 mw--form" for="expectedNumberOfBoxesInStock">{{
                        this.$t('pageCampaign.tabPrize.expectedNumberOfBoxesInStock')
                    }}</label>
                    <div class="col-sm-9">
                        <InputText
                            :id="`expectedNumberOfBoxesInStock`"
                            :model.sync="formInfo.number_of_boxes_to_be_stocked"
                            :config.sync="formInfo.configNumberOfBoxesToBeStocked"
                        />
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-3 mw--form" for="expirationDate">{{ this.$t('pageCampaign.tabPrize.expirationDate') }}</label>
                    <div class="col-sm-9">
                        <InputText :id="`expirationDate`" :model.sync="formInfo.expiration_date" :config.sync="formInfo.configExpirationDate" />
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-3 mw--form" for="storageWarehouse">{{ this.$t('pageCampaign.tabPrize.storageWarehouse') }}</label>
                    <div class="col-sm-9">
                        <MultiSelectCommon
                            :id="`select-storage-warehouse`"
                            :value.sync="formInfo.storage_warehouse"
                            :options="listStorageWarehouse"
                            :config="formInfo.configStorageWarehouse"
                            @open="getListMasterStorageWarehouse(formInfo.configStorageWarehouse)"
                        ></MultiSelectCommon>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-3 mw--form" for="locatorNumber">{{ this.$t('pageCampaign.tabPrize.locatorNumber') }}</label>
                    <div class="col-sm-9">
                        <InputText :id="`locatorNumber`" :model.sync="formInfo.locator_number" :config.sync="formInfo.configLocatorNumber" />
                    </div>
                </div>
            </form>
        </ModalCommon>
    </div>
</template>

<script>
import { CONFIG_MODALCOMMONINFO } from '../../constans';
import { InputText } from '@/components/Input';
import { handleValidateInfo } from './validate';
import { keyMaster, masterMethods } from '@/state/helpers';
import ModalCommon from '@/components/Modal/main.vue';
import MultiSelectCommon from '@/components/Select/multi/main.vue';

export default {
    name: 'PopUpInfomation',
    components: {
        ModalCommon,
        InputText,
        MultiSelectCommon
    },
    props: {
        id: {
            type: String,
            default: ''
        },
        title: {
            type: String,
            default: ''
        },
        isShow: {
            type: Boolean,
            default: false
        },
        dataInfo: {
            type: Object,
            default: () => {
                return {};
            }
        }
    },
    data() {
        return {
            idInfo: this.id,
            formInfo: this.dataInfo,
            formInfoCopy: JSON.parse(JSON.stringify(this.dataInfo)),
            configModalCommonInfo: CONFIG_MODALCOMMONINFO,
            listStorageWarehouse: []
        };
    },
    methods: {
        ...masterMethods,

        handleSaveInfomation() {
            let checkValidate = handleValidateInfo(this.formInfo);
            if (!checkValidate) {
                this.configModalCommonInfo.model = true;
                return;
            }
            this.$emit('update:dataInfo', this.formInfo);
            this.$emit('update:isShow', false);
        },
        handleCloseInfomation() {
            let checkValidate = handleValidateInfo(this.formInfo);
            if (!checkValidate) {
                this.configModalCommonInfo.model = true;
                return;
            }
            this.formInfo = this.formInfoCopy;
            this.$emit('update:dataInfo', this.formInfo);
            this.$emit('update:isShow', false);
        },

        async getListMasterStorageWarehouse(configStorageWarehouse) {
            if (configStorageWarehouse.loading) return;
            configStorageWarehouse.loading = true;
            this.listStorageWarehouse = [];
            try {
                let data = await this.listMaster(keyMaster['prize.storage_warehouse']);
                this.listStorageWarehouse = data;
            } catch (error) {
                console.log(error);
            } finally {
                configStorageWarehouse.loading = false;
            }
        }
    },
    watch: {
        id: {
            handler: function (valId) {
                this.idInfo = valId;
            },
            immediate: true
        },
        title: {
            handler: function (valTitle) {
                this.configModalCommonInfo.title = valTitle;
            },
            immediate: true
        },
        isShow: {
            handler: function (valShow) {
                this.configModalCommonInfo.model = valShow;
                if (valShow) {
                    this.formInfoCopy = JSON.parse(JSON.stringify(this.formInfo));
                }
            },
            immediate: true
        }
    }
};
</script>

<style lang="scss" scoped></style>
