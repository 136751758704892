<template>
    <div>
        <div class="form-group row">
            <label class="col-sm-3">エンドクライアント</label>
            <div class="col-sm-8">
                <div class="d-flex">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>エンドクライアント名</th>
                                <th>担当</th>
                                <th>部署</th>
                                <th>メールアドレス</th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, k) in form.clients" :key="k + 'fc' + item.client_id">
                                <td>{{ item.name }}</td>
                                <td>{{ item.client_pic_name }}</td>
                                <td>{{ item.client_pic_department }}</td>
                                <td>{{ item.client_pic_email }}</td>
                                <td>{{ item.nameType ? item.nameType : renderType(item.type) }}</td>
                                <td>
                                    <a href="javascript:void(0)" @click="deleteClient(item)" class="text-danger">削除</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button type="button" class="btn btn-sm btn-success ml-5" style="white-space: nowrap; opacity: 0">新規登録</button>
                </div>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-sm-3">エンドクライアント名</label>
            <div class="col-sm-8">
                <div class="d-flex align-items-start">
                    <div class="position-relative w-100">
                        <multiselect
                            :showPointer="false"
                            :showNoResults="false"
                            selectedLabel=""
                            deselectLabel=""
                            placeholder=""
                            selectLabel=""
                            :showNoOptions="false"
                            @close="closeClient()"
                            track-by="id"
                            label="name"
                            v-model="clientObject"
                            :options="listDataMasterClients"
                        ></multiselect>
                        <i
                            v-if="clientObject.id"
                            @click="
                                $event.stopPropagation();
                                clientObject = {};
                                clientPicObject = {};
                            "
                            style="cursor: pointer; position: absolute; right: 15px; top: 11px; background: #fff; z-index: 9"
                            class="fa fa-window-close"
                        ></i>
                    </div>
                    <button
                        v-if="checkPermission('client.register')"
                        v-b-modal.modal-client
                        type="button"
                        class="btn btn-sm btn-primary ml-5"
                        style="white-space: nowrap"
                    >
                        新規登録
                    </button>
                </div>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-sm-3">エンドクライアント担当</label>
            <div class="col-sm-8">
                <div class="d-flex">
                    <div class="w-100">
                        <div class="w-100 position-relative">
                            <multiselect
                                :showPointer="false"
                                :showNoResults="false"
                                selectedLabel=""
                                deselectLabel=""
                                placeholder=""
                                selectLabel=""
                                :showNoOptions="false"
                                track-by="id"
                                label="name"
                                @close="$emit('update:clientPicObjects', clientPicObject)"
                                v-model="clientPicObject"
                                :options="listClientsSupport"
                            ></multiselect>
                            <i
                                v-if="clientPicObject.id"
                                @click="
                                    $event.stopPropagation();
                                    clientPicObject = {};
                                "
                                style="cursor: pointer; position: absolute; right: 15px; top: 11px; background: #fff; z-index: 9"
                                class="fa fa-window-close"
                            ></i>
                        </div>
                    </div>

                    <button type="button" class="btn btn-sm btn-success ml-5" style="white-space: nowrap; opacity: 0">新規登録</button>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3">メーカー・流通★ </label>
            <div class="col-sm-8">
                <div class="d-flex">
                    <div class="w-100">
                        <div class="w-100 position-relative">
                            <multiselect
                                :showPointer="false"
                                :showNoResults="false"
                                selectedLabel=""
                                deselectLabel=""
                                placeholder=""
                                selectLabel=""
                                :showNoOptions="false"
                                track-by="id"
                                label="name"
                                v-model="clientTypeObject"
                                :options="listClientsType"
                            ></multiselect>
                            <i
                                v-if="clientTypeObject.id"
                                @click="
                                    $event.stopPropagation();
                                    clientTypeObject = {};
                                "
                                style="cursor: pointer; position: absolute; right: 15px; top: 11px; background: #fff; z-index: 9"
                                class="fa fa-window-close"
                            ></i>
                        </div>

                        <div class="text-center mt-2">
                            <button type="button" class="btn btn-sm btn-primary" @click="addClients">登録</button>
                        </div>
                    </div>

                    <button type="button" class="btn btn-sm btn-success ml-5" style="white-space: nowrap; opacity: 0">新規登録</button>
                </div>
            </div>
        </div>
        <!--              Agency-->

        <div class="form-group row">
            <label class="col-sm-3">代理店</label>
            <div class="col-sm-8">
                <div class="d-flex">
                    <table class="table table-bordered">
                        <thead>
                            <tr>
                                <th>代理店名</th>
                                <th>担当</th>
                                <th>部署</th>
                                <th>メールアドレス</th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(item, k) in form.agencies" :key="k + 'fa' + item.agency_id">
                                <td>{{ item.name }}</td>
                                <td>{{ item.agency_pic_name }}</td>
                                <td>{{ item.agency_pic_department }}</td>
                                <td>{{ item.agency_pic_email }}</td>
                                <td>
                                    <a href="javascript:void(0)" @click="deleteAgency(item)" class="text-danger">削除</a>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                    <button type="button" class="btn btn-sm btn-success ml-5" style="white-space: nowrap; opacity: 0">新規登録</button>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3">代理店名</label>
            <div class="col-sm-8">
                <div class="d-flex align-items-start">
                    <div class="w-100 position-relative">
                        <multiselect
                            :showPointer="false"
                            :showNoResults="false"
                            selectedLabel=""
                            deselectLabel=""
                            placeholder=""
                            selectLabel=""
                            :showNoOptions="false"
                            @close="closeAgency"
                            track-by="id"
                            label="name"
                            v-model="agenciesObject"
                            :options="listDataMasterAgencies"
                        ></multiselect>
                        <i
                            v-if="agenciesObject.id"
                            @click="
                                $event.stopPropagation();
                                agenciesObject = {};
                                agenciesPicObject = {};
                            "
                            style="cursor: pointer; position: absolute; right: 15px; top: 11px; background: #fff; z-index: 9"
                            class="fa fa-window-close"
                        ></i>
                    </div>
                    <button
                        v-if="checkPermission('agency.register')"
                        v-b-modal.modal-agency
                        type="button"
                        class="btn btn-sm btn-primary ml-5"
                        style="white-space: nowrap"
                    >
                        新規登録
                    </button>
                </div>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-sm-3">代理店担当</label>
            <div class="col-sm-8">
                <div class="d-flex align-items-start">
                    <div class="w-100">
                        <div class="w-100 position-relative">
                            <multiselect
                                :showPointer="false"
                                :showNoResults="false"
                                selectedLabel=""
                                deselectLabel=""
                                placeholder=""
                                selectLabel=""
                                :showNoOptions="false"
                                track-by="id"
                                label="name"
                                @close="$emit('update:agenciesPicObjects', agenciesPicObject)"
                                v-model="agenciesPicObject"
                                :options="listAgenciesSupport"
                            ></multiselect>
                            <i
                                v-if="agenciesPicObject.id"
                                @click="
                                    $event.stopPropagation();
                                    agenciesPicObject = {};
                                "
                                style="cursor: pointer; position: absolute; right: 15px; top: 11px; background: #fff; z-index: 9"
                                class="fa fa-window-close"
                            ></i>
                        </div>
                        <div class="text-center mt-2">
                            <button type="button" class="btn btn-sm btn-primary" @click="addAgencies">登録</button>
                        </div>
                    </div>

                    <button type="button" class="btn btn-sm btn-primary ml-5" style="white-space: nowrap; opacity: 0">新規登録</button>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3">発注元</label>
            <div class="col-sm-4">
                <div class="d-flex align-items-center">
                    <div class="w-100 position-relative">
                        <multiselect
                            :showPointer="false"
                            :showNoResults="false"
                            selectedLabel=""
                            deselectLabel=""
                            placeholder=""
                            selectLabel=""
                            :showNoOptions="false"
                            track-by="id"
                            label="name"
                            v-model="sourceDataType"
                            @select="sourceData = { id: '', name: '' }"
                            :options="dataSource"
                        ></multiselect>
                        <i
                            v-if="sourceDataType && sourceDataType.id"
                            @click="
                                $event.stopPropagation();
                                sourceDataType = {};
                            "
                            style="cursor: pointer; position: absolute; right: 15px; top: 11px; background: #fff; z-index: 9"
                            class="fa fa-window-close"
                        ></i>
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3"></label>
            <div class="col-sm-4">
                <div class="d-flex align-items-center" v-if="form.source_data.type == 1">
                    <div class="w-100 position-relative">
                        <multiselect
                            :showPointer="false"
                            :showNoResults="false"
                            selectedLabel=""
                            deselectLabel=""
                            placeholder=""
                            selectLabel=""
                            :showNoOptions="false"
                            track-by="id"
                            label="name"
                            v-model="sourceData"
                            :options="removeDuplicates(form.clients, 'client_id')"
                        ></multiselect>
                        <i
                            v-if="sourceData && sourceData.id"
                            @click="
                                $event.stopPropagation();
                                sourceData = {};
                            "
                            style="cursor: pointer; position: absolute; right: 15px; top: 11px; background: #fff; z-index: 9"
                            class="fa fa-window-close"
                        ></i>
                    </div>
                </div>
                <div class="d-flex align-items-center" v-if="form.source_data.type == 2">
                    <div class="w-100 position-relative">
                        <multiselect
                            :showPointer="false"
                            :showNoResults="false"
                            selectedLabel=""
                            deselectLabel=""
                            placeholder=""
                            selectLabel=""
                            :showNoOptions="false"
                            track-by="id"
                            label="name"
                            v-model="sourceData"
                            :options="removeDuplicates(form.agencies, 'agency_id')"
                        ></multiselect>
                        <i
                            v-if="sourceData && sourceData.id"
                            @click="
                                $event.stopPropagation();
                                sourceData = {};
                            "
                            style="cursor: pointer; position: absolute; right: 15px; top: 11px; background: #fff; z-index: 9"
                            class="fa fa-window-close"
                        ></i>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { agencyMethods, campaignMethods, checkPermission, clientMethods, masterMethods } from '@/state/helpers';
import Multiselect from 'vue-multiselect';

export default {
    name: 'tab3',
    components: { Multiselect },
    props: {
        dataObject: {
            type: Object
        },
        submitted: {
            type: Boolean,
            required: true
        },
        validate: {
            type: Object
        },
        listDataMasterAgencies: {
            type: Array
        },
        listAgenciesSupports: {
            type: Array
        },
        listDataMasterClientsS: {
            type: Array
        },
        listClientsSupportsS: {
            type: Array
        },
        clientsS: {
            type: Object
        },
        agenciesS: {
            type: Object
        },
        agenciesObjects: {
            type: Object
        },
        agenciesPicObjects: {
            type: Object
        },
        clientObjects: {
            type: Object
        },
        clientPicObjects: {
            type: Object
        },
        clientTypeObjects: {
            type: Object
        }
    },
    watch: {
        sourceData(newValue) {
            if (newValue) this.form.source_data.id = newValue.id;
            else this.form.source_data.id = '';
        },
        sourceDataType(newValue) {
            if (newValue) this.form.source_data.type = newValue.id;
            else this.form.source_data.type = '';
        },
        dataObject(newValue) {
            this.form = newValue;
            // let index = this.dataSource.findIndex(x=> x.id == this.form.source_data.type)
            // if(index >=0){
            //     this.sourceDataType = this.dataSource[index]
            // }
            // this.sourceData = {
            //     id: this.form.source_data.id,
            //     name: this.form.source_data.name
            // }
        },
        clientObjects(newValue) {
            this.clientObject = newValue;
        },
        listDataMasterClientsS(newValue) {
            this.listDataMasterClients = newValue;
        },
        listClientsSupportsS(newValue) {
            this.listClientsSupports = newValue;
        },
        agenciesObjects(newValue) {
            this.agenciesObject = newValue;
        },
        clientsS(newValue) {
            this.clients = newValue;
        },
        agenciesS(newValue) {
            this.agencies = newValue;
        },
        listClientsSupports(newValue) {
            this.listClientsSupport = newValue;
        },
        listAgenciesSupports(newValue) {
            this.listAgenciesSupport = newValue;
        },
        clientObject: function (newName) {
            this.clients.client_id = newName.id;
        },
        clientPicObject: function (newName) {
            this.clients.client_pic_id = newName.id;
        },
        agenciesObject: function (newName) {
            this.agencies.agency_id = newName.id;
        },
        agenciesPicObject: function (newName) {
            this.agencies.agency_pic_id = newName.id;
        }
    },
    data: function () {
        return {
            listClientsSupports: this.listClientsSupportsS,
            listDataMasterClients: this.listDataMasterClientsS,
            sourceData: {},
            sourceDataType: {},
            dataSource: [
                {
                    id: 1,
                    name: 'エンドクライアント'
                },
                {
                    id: 2,
                    name: '代理店'
                }
            ],
            form: this.dataObject,
            sns_application_condition_1: {},
            clientObject: {},
            clients: this.clientsS,
            clientPicObject: {},
            listClientsType: [
                {
                    id: 1,
                    name: 'メーカー'
                },
                { id: 2, name: '流通' }
            ],
            listClientsSupport: [],
            agenciesObject: {},
            agencies: this.agenciesS,
            agenciesPicObject: {},
            clientTypeObject: {},

            listAgenciesSupport: []
        };
    },
    mounted() {
        let index = this.dataSource.findIndex((x) => x.id == this.form.source_data.type);
        if (index >= 0) {
            this.sourceDataType = this.dataSource[index];
        }
        if (this.dataSource[index]) {
            if (this.dataSource[index].id === 1) {
                this.sourceData = {
                    id: this.form.source_data.id,
                    name: this.form.clients.find((client) => client.id === this.form.source_data.id)?.name ?? ''
                };
            } else {
                this.sourceData = {
                    id: this.form.source_data.id,
                    name: this.form.agencies.find((agency) => agency.id === this.form.source_data.id)?.name ?? ''
                };
            }
        }
    },
    methods: {
        checkPermission,
        ...campaignMethods,
        ...masterMethods,
        ...clientMethods,
        ...agencyMethods,
        removeDuplicates(objectsS, key) {
            let objects = JSON.parse(JSON.stringify(objectsS));
            const map = {}; // Sử dụng một đối tượng bản đồ để theo dõi các ID đã xuất hiện
            const result = [];
            // Lặp qua mảng đối tượng
            for (const obj of objects) {
                // Nếu ID không tồn tại trong bản đồ, thêm đối tượng vào mảng kết quả và đánh dấu ID đã xuất hiện
                if (!map[obj[key]]) {
                    result.push(obj);
                    map[obj[key]] = true;
                }
            }

            return result;
        },
        closeClient() {
            this.$emit('update:clientObjects', this.clientObject);
            this.getListMasterClientsSupport();
        },
        closeAgency() {
            this.$emit('update:agenciesObjects', this.agenciesObject);
            this.getListMasterAgenciesSupport();
        },
        renderType(type) {
            let index = this.listClientsType.findIndex((x) => x.id == type);
            if (index >= 0) return this.listClientsType[index].name;
        },
        addAgencies() {
            if (this.agencies.agency_id || this.agencies.agency_pic_id) {
                let iA = this.listDataMasterAgencies.findIndex((x) => x.id == this.agencies.agency_id);
                let iAS = this.listAgenciesSupport.findIndex((x) => x.id == this.agencies.agency_pic_id);
                let obj = Object.assign({}, this.agencies);
                obj.name = this.listDataMasterAgencies[iA].name;
                obj.id = this.listDataMasterAgencies[iA].id;
                if (iAS >= 0) {
                    obj.agency_pic_name = this.listAgenciesSupport[iAS].name;
                    obj.agency_pic_department = this.listAgenciesSupport[iAS].department;
                    obj.agency_pic_email = this.listAgenciesSupport[iAS].email;
                } else obj.agency_pic_id = '';
                let checkAgency = this.form.agencies.some(function (item) {
                    return item.agency_id === obj.agency_id && item.agency_pic_id === obj.agency_pic_id;
                });
                if (!checkAgency) {
                    this.form.agencies.push(obj);
                    this.agenciesObject = {};
                    this.agenciesPicObject = {};
                    this.agencies = {
                        agency_id: '',
                        agency_pic_id: ''
                    };
                    this.listAgenciesSupport = [];
                } else {
                    this.$bvToast.toast(``, {
                        title: `データはすでに存在します`,
                        variant: 'danger',
                        toaster: 'b-toaster-top-center',
                        solid: true
                    });
                }
            }
        },
        getListMasterAgenciesSupport() {
            if (this.agenciesObject.id) {
                this.listAgencySupport(this.agenciesObject.id).then((data) => {
                    data.data.forEach((item, k) => {
                        let index = this.form.agencies.findIndex((x) => x.agency_pic_id == item.id);
                        if (index >= 0) {
                            data.data[k].$isDisabled = true;
                        }
                    });
                    this.listAgenciesSupport = data.data;
                    this.loading = false;
                });
            }
        },
        getListMasterClientsSupport() {
            if (this.clientObject.id) {
                this.listClientSupport(this.clientObject.id).then((data) => {
                    data.data.forEach((item, k) => {
                        let index = this.form.clients.findIndex((x) => x.client_pic_id == item.id);
                        if (index >= 0) {
                            data.data[k].$isDisabled = true;
                        } else {
                            data.data[k].$isDisabled = false;
                        }
                    });
                    this.listClientsSupport = data.data;
                    this.$emit('update:listClientsSupportsS', this.listClientsSupport);
                    this.loading = false;
                });
            }
        },
        deleteAgency(item) {
            let i = this.form.agencies.findIndex((x) => x.agency_id == item.agency_id && x.agency_pic_id == item.agency_pic_id);
            if (i >= 0) {
                this.form.agencies.splice(i, 1);
            }
        },
        addClients() {
            if (this.clients.client_id) {
                let iC = this.listDataMasterClients.findIndex((x) => x.id == this.clients.client_id);
                let iCS = this.listClientsSupport.findIndex((x) => x.id == this.clients.client_pic_id);
                let obj = Object.assign({}, this.clients);
                obj.id = this.listDataMasterClients[iC].id;
                obj.name = this.listDataMasterClients[iC].name;
                obj.type = this.clientTypeObject.id;
                obj.nameType = this.clientTypeObject.name;
                if (iCS >= 0) {
                    obj.client_pic_name = this.listClientsSupport[iCS].name;
                    obj.client_pic_department = this.listClientsSupport[iCS].department;
                    obj.client_pic_email = this.listClientsSupport[iCS].email;
                } else obj.client_pic_id = '';
                let checkClient = this.form.clients.some(function (item) {
                    return item.client_id === obj.client_id && item.client_pic_id === obj.client_pic_id;
                });
                if (!checkClient) {
                    this.form.clients.push(obj);
                    this.clientObject = {};
                    this.clientPicObject = {};
                    this.clientTypeObject = {};
                    this.clients = {
                        client_id: '',
                        client_pic_id: ''
                    };
                    this.listClientsSupport = [];
                } else {
                    this.$bvToast.toast(`error`, {
                        title: `データはすでに存在します`,
                        variant: 'danger',
                        toaster: 'b-toaster-top-center',
                        solid: true
                    });
                }
            }
        },
        deleteClient(item) {
            let i = this.form.clients.findIndex((x) => x.client_id == item.client_id && x.client_pic_id == item.client_pic_id);
            if (i >= 0) {
                this.form.clients.splice(i, 1);
            }
        }
    },
    computed: {}
};
</script>

<style scoped lang="scss"></style>
