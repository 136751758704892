<template>
    <div>
        <div class="d-flex justify-content-end mb-3" style="gap: 12px">
            <button
                v-if="checkPermission('prize.register')"
                type="button"
                class="btn btn-sm btn-primary"
                style="white-space: nowrap"
                @click="handleAddTitle()"
            >
                賞品タイトルを追加
            </button>
        </div>

        <div class="table-responsive" style="height: 450px; overflow-y: auto; outline: none">
            <table class="table table-bordered custom__table--sticky">
                <thead>
                    <tr>
                        <th>賞品タイトル <span class="text-danger">*</span></th>
                        <th>当選人数</th>
                        <th>抽選人数</th>
                        <th>賞品名 <span class="text-danger">*</span></th>
                        <th>数量</th>
                        <th>タイトルに対して数量</th>
                        <th>入庫数（顧客から）</th>
                        <th>残数</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    <template v-for="(item, k) in arrayObjectPizes">
                        <tr v-for="(itemPN, idx) in item.arrayNameAndQuantity" :key="'arrayObjectPizes' + k + idx">
                            <td v-if="idx == 0" :rowspan="item.arrayNameAndQuantity.length" style="vertical-align: middle">
                                <div style="min-width: 250px">
                                    <InputText
                                        :id="`winners${k}`"
                                        :model.sync="item.title"
                                        :config="{
                                            error: !!item.errorTitle,
                                            errorCustomMess: item.errorTitle
                                        }"
                                        @input="handleInputTitle(item)"
                                    />
                                </div>
                            </td>

                            <td v-if="idx == 0" :rowspan="item.arrayNameAndQuantity.length" style="vertical-align: middle">
                                <div style="min-width: 100px">
                                    <InputText
                                        :id="`winners${k}`"
                                        :model.sync="item.number_of_win_prize"
                                        :config="{
                                            isNumber: true,
                                            maxlength: 10,
                                            error: false
                                        }"
                                        @input="handleExcuteMath(item)"
                                    />
                                </div>
                            </td>

                            <td v-if="idx == 0" :rowspan="item.arrayNameAndQuantity.length" style="vertical-align: middle">
                                <div style="min-width: 100px">
                                    <InputText
                                        :id="`drawn${k}`"
                                        :model.sync="item.number_of_people_drawing_lots"
                                        :config="{
                                            isNumber: true,
                                            maxlength: 10,
                                            error: false
                                        }"
                                        disabled
                                    />
                                </div>
                            </td>

                            <td>
                                <div
                                    class="d-flex justify-content-between"
                                    style="gap: 10px"
                                    :key="idx"
                                    :class="{
                                        'mb-3': item.arrayNameAndQuantity.length !== 1
                                    }"
                                >
                                    <div style="min-width: 250px; flex: 1">
                                        <InputText :id="`prize-name${k}-${idx}`" :model.sync="itemPN.prize_name" :config="itemPN.configPrizeName" />
                                    </div>
                                    <div class="d-flex align-items-center" style="gap: 12px">
                                        <button
                                            type="button"
                                            class="btn btn-sm btn-info"
                                            style="height: 30px; min-width: fit-content; display: flex; justify-content: center; align-items: center"
                                            @click.prevent="addNameAndQuantity(idx, k)"
                                        >
                                            <i class="fa fa-plus"></i>
                                        </button>
                                        <button
                                            type="button"
                                            class="btn btn-sm btn-danger"
                                            style="height: 30px; min-width: fit-content; display: flex; justify-content: center; align-items: center"
                                            :disabled="item.arrayNameAndQuantity.length <= 1"
                                            @click.prevent="removeNameAndQuantity(idx, k)"
                                        >
                                            <i class="fa fa-minus"></i>
                                        </button>
                                    </div>
                                </div>
                            </td>

                            <td>
                                <div
                                    :class="{
                                        'mb-3': item.arrayNameAndQuantity.length !== 1
                                    }"
                                    style="min-width: 100px"
                                >
                                    <InputText
                                        :id="`quantity${k}-${idx}`"
                                        :model.sync="itemPN.quantity"
                                        :config="{
                                            isNumber: true,
                                            maxlength: 10,
                                            error: false
                                        }"
                                        @input="handleExcuteMath(item)"
                                    />
                                </div>
                            </td>

                            <td>
                                <div style="min-width: 150px">
                                    <InputText
                                        :id="`quantity_title${k}-${idx}`"
                                        :model.sync="itemPN.quantity_title"
                                        :config="{
                                            isNumber: true,
                                            maxlength: 10,
                                            error: false
                                        }"
                                        disabled
                                    />
                                </div>
                            </td>

                            <td>
                                <div style="min-width: 150px">
                                    <InputText
                                        :id="`quantity_received${k}-${idx}`"
                                        :model.sync="itemPN.quantity_received"
                                        :config="{
                                            isNumber: true,
                                            maxlength: 10,
                                            error: false
                                        }"
                                        @input="handleExcuteMath(item)"
                                    />
                                </div>
                            </td>

                            <td>
                                <div style="min-width: 150px">
                                    <InputText
                                        :id="`quantity_remaining${k}-${idx}`"
                                        :model.sync="itemPN.quantity_remaining"
                                        :config.sync="itemPN.configRemaining"
                                        disabled
                                    />
                                </div>
                            </td>

                            <td>
                                <div class="mt-1">
                                    <button
                                        type="button"
                                        class="btn btn-sm btn-warning"
                                        style="
                                            height: 30px;
                                            min-width: fit-content;
                                            display: flex;
                                            justify-content: center;
                                            align-items: center;
                                            padding-inline: 12px;
                                        "
                                        :disabled="!itemPN.id"
                                        @click="
                                            () => {
                                                $router.push({
                                                    path: '/prize/form/view',
                                                    query: {
                                                        id: itemPN.id
                                                    }
                                                });
                                            }
                                        "
                                    >
                                        <i class="fa fa-info"></i>
                                    </button>
                                </div>
                            </td>

                            <td v-if="idx == 0" :rowspan="item.arrayNameAndQuantity.length" style="vertical-align: middle">
                                <div>
                                    <div class="d-flex justify-content-center" style="gap: 12px">
                                        <button type="button" class="btn btn-sm btn-danger" style="white-space: nowrap" @click="handleRemoveTitle(k)">
                                            {{ $t('btn.delete') }}
                                        </button>
                                    </div>
                                </div>
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
        </div>
    </div>
</template>

<script>
import { checkPermission } from '@/state/helpers';
import { InputText } from '@/components/Input';
import { initObjectPize } from './index';
export default {
    props: {
        dataForm: {
            type: Object,
            default: () => {}
        },
        dataPrizes: {
            type: Array,
            default: () => []
        }
    },
    components: {
        InputText
    },
    data() {
        return {
            arrayObjectPizes: [],
            form: this.dataForm
        };
    },
    methods: {
        checkPermission,

        handleAddTitle() {
            this.arrayObjectPizes.push(initObjectPize());
        },

        handleRemoveTitle(index) {
            this.arrayObjectPizes.splice(index, 1);
        },

        addNameAndQuantity(idChild, idParent) {
            this.arrayObjectPizes[idParent].arrayNameAndQuantity.splice(idChild + 1, 0, {
                prize_name: '',
                configPrizeName: {
                    errorCustomMess: '',
                    isRequired: false,
                    isMaxLength: false,
                    error: false,
                    errorField: '賞品名'
                },
                quantity: 0,
                quantity_title: 0,
                quantity_received: 0,
                quantity_remaining: 0,
                configRemaining: {
                    isNumber: true,
                    maxlength: 10,
                    error: false,
                    errorCustomMess: ''
                }
            });
        },

        removeNameAndQuantity(idChild, idParent) {
            this.arrayObjectPizes[idParent].arrayNameAndQuantity.splice(idChild, 1);
        },

        handleInputTitle(obj) {
            if (!obj.title) {
                obj.errorTitle = this.$t('validateField.required', {
                    field: '賞品タイトル'
                });
            } else {
                if (obj.title.length > 255) {
                    obj.errorTitle = this.$t('validateField.max255', {
                        field: '賞品タイトル'
                    });
                } else {
                    obj.errorTitle = '';
                }
            }
        },

        handleExcuteMath(obj) {
            if (!obj.number_of_win_prize) {
                obj.number_of_people_drawing_lots = 0;
            } else {
                obj.number_of_people_drawing_lots = Math.round(obj.number_of_win_prize * 1.2);
            }

            obj.arrayNameAndQuantity.forEach((item) => {
                if (item.quantity.toString() && obj.number_of_win_prize.toString()) {
                    item.quantity_title = Number(item.quantity) * Number(obj.number_of_win_prize);
                } else {
                    item.quantity_title = 0;
                }

                if (item.quantity_title.toString() && item.quantity_received.toString()) {
                    item.quantity_remaining = Number(item.quantity_received) - Number(item.quantity_title);
                } else {
                    item.quantity_remaining = 0;
                }
            });
        }
    },
    watch: {
        dataPrizes: {
            handler(val) {
                this.arrayObjectPizes = val;
            },
            immediate: true
        },
        arrayObjectPizes: {
            handler(val) {
                this.$emit('update:dataPrizes', val);
            },
            deep: true
        }
    }
};
</script>

<style lang="scss" scoped></style>
