var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"d-flex justify-content-end mb-3",staticStyle:{"gap":"12px"}},[(_vm.checkPermission('prize.register'))?_c('button',{staticClass:"btn btn-sm btn-primary",staticStyle:{"white-space":"nowrap"},attrs:{"type":"button"},on:{"click":function($event){return _vm.handleAddTitle()}}},[_vm._v(" 賞品タイトルを追加 ")]):_vm._e()]),_c('div',{staticClass:"table-responsive",staticStyle:{"height":"450px","overflow-y":"auto","outline":"none"}},[_c('table',{staticClass:"table table-bordered custom__table--sticky"},[_vm._m(0),_c('tbody',[_vm._l((_vm.arrayObjectPizes),function(item,k){return _vm._l((item.arrayNameAndQuantity),function(itemPN,idx){return _c('tr',{key:'arrayObjectPizes' + k + idx},[(idx == 0)?_c('td',{staticStyle:{"vertical-align":"middle"},attrs:{"rowspan":item.arrayNameAndQuantity.length}},[_c('div',{staticStyle:{"min-width":"250px"}},[_c('InputText',{attrs:{"id":`winners${k}`,"model":item.title,"config":{
                                        error: !!item.errorTitle,
                                        errorCustomMess: item.errorTitle
                                    }},on:{"update:model":function($event){return _vm.$set(item, "title", $event)},"input":function($event){return _vm.handleInputTitle(item)}}})],1)]):_vm._e(),(idx == 0)?_c('td',{staticStyle:{"vertical-align":"middle"},attrs:{"rowspan":item.arrayNameAndQuantity.length}},[_c('div',{staticStyle:{"min-width":"100px"}},[_c('InputText',{attrs:{"id":`winners${k}`,"model":item.number_of_win_prize,"config":{
                                        isNumber: true,
                                        maxlength: 10,
                                        error: false
                                    }},on:{"update:model":function($event){return _vm.$set(item, "number_of_win_prize", $event)},"input":function($event){return _vm.handleExcuteMath(item)}}})],1)]):_vm._e(),(idx == 0)?_c('td',{staticStyle:{"vertical-align":"middle"},attrs:{"rowspan":item.arrayNameAndQuantity.length}},[_c('div',{staticStyle:{"min-width":"100px"}},[_c('InputText',{attrs:{"id":`drawn${k}`,"model":item.number_of_people_drawing_lots,"config":{
                                        isNumber: true,
                                        maxlength: 10,
                                        error: false
                                    },"disabled":""},on:{"update:model":function($event){return _vm.$set(item, "number_of_people_drawing_lots", $event)}}})],1)]):_vm._e(),_c('td',[_c('div',{key:idx,staticClass:"d-flex justify-content-between",class:{
                                    'mb-3': item.arrayNameAndQuantity.length !== 1
                                },staticStyle:{"gap":"10px"}},[_c('div',{staticStyle:{"min-width":"250px","flex":"1"}},[_c('InputText',{attrs:{"id":`prize-name${k}-${idx}`,"model":itemPN.prize_name,"config":itemPN.configPrizeName},on:{"update:model":function($event){return _vm.$set(itemPN, "prize_name", $event)}}})],1),_c('div',{staticClass:"d-flex align-items-center",staticStyle:{"gap":"12px"}},[_c('button',{staticClass:"btn btn-sm btn-info",staticStyle:{"height":"30px","min-width":"fit-content","display":"flex","justify-content":"center","align-items":"center"},attrs:{"type":"button"},on:{"click":function($event){$event.preventDefault();return _vm.addNameAndQuantity(idx, k)}}},[_c('i',{staticClass:"fa fa-plus"})]),_c('button',{staticClass:"btn btn-sm btn-danger",staticStyle:{"height":"30px","min-width":"fit-content","display":"flex","justify-content":"center","align-items":"center"},attrs:{"type":"button","disabled":item.arrayNameAndQuantity.length <= 1},on:{"click":function($event){$event.preventDefault();return _vm.removeNameAndQuantity(idx, k)}}},[_c('i',{staticClass:"fa fa-minus"})])])])]),_c('td',[_c('div',{class:{
                                    'mb-3': item.arrayNameAndQuantity.length !== 1
                                },staticStyle:{"min-width":"100px"}},[_c('InputText',{attrs:{"id":`quantity${k}-${idx}`,"model":itemPN.quantity,"config":{
                                        isNumber: true,
                                        maxlength: 10,
                                        error: false
                                    }},on:{"update:model":function($event){return _vm.$set(itemPN, "quantity", $event)},"input":function($event){return _vm.handleExcuteMath(item)}}})],1)]),_c('td',[_c('div',{staticStyle:{"min-width":"150px"}},[_c('InputText',{attrs:{"id":`quantity_title${k}-${idx}`,"model":itemPN.quantity_title,"config":{
                                        isNumber: true,
                                        maxlength: 10,
                                        error: false
                                    },"disabled":""},on:{"update:model":function($event){return _vm.$set(itemPN, "quantity_title", $event)}}})],1)]),_c('td',[_c('div',{staticStyle:{"min-width":"150px"}},[_c('InputText',{attrs:{"id":`quantity_received${k}-${idx}`,"model":itemPN.quantity_received,"config":{
                                        isNumber: true,
                                        maxlength: 10,
                                        error: false
                                    }},on:{"update:model":function($event){return _vm.$set(itemPN, "quantity_received", $event)},"input":function($event){return _vm.handleExcuteMath(item)}}})],1)]),_c('td',[_c('div',{staticStyle:{"min-width":"150px"}},[_c('InputText',{attrs:{"id":`quantity_remaining${k}-${idx}`,"model":itemPN.quantity_remaining,"config":itemPN.configRemaining,"disabled":""},on:{"update:model":function($event){return _vm.$set(itemPN, "quantity_remaining", $event)},"update:config":function($event){return _vm.$set(itemPN, "configRemaining", $event)}}})],1)]),_c('td',[_c('div',{staticClass:"mt-1"},[_c('button',{staticClass:"btn btn-sm btn-warning",staticStyle:{"height":"30px","min-width":"fit-content","display":"flex","justify-content":"center","align-items":"center","padding-inline":"12px"},attrs:{"type":"button","disabled":!itemPN.id},on:{"click":() => {
                                            _vm.$router.push({
                                                path: '/prize/form/view',
                                                query: {
                                                    id: itemPN.id
                                                }
                                            });
                                        }}},[_c('i',{staticClass:"fa fa-info"})])])]),(idx == 0)?_c('td',{staticStyle:{"vertical-align":"middle"},attrs:{"rowspan":item.arrayNameAndQuantity.length}},[_c('div',[_c('div',{staticClass:"d-flex justify-content-center",staticStyle:{"gap":"12px"}},[_c('button',{staticClass:"btn btn-sm btn-danger",staticStyle:{"white-space":"nowrap"},attrs:{"type":"button"},on:{"click":function($event){return _vm.handleRemoveTitle(k)}}},[_vm._v(" "+_vm._s(_vm.$t('btn.delete'))+" ")])])])]):_vm._e()])})})],2)])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('thead',[_c('tr',[_c('th',[_vm._v("賞品タイトル "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('th',[_vm._v("当選人数")]),_c('th',[_vm._v("抽選人数")]),_c('th',[_vm._v("賞品名 "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('th',[_vm._v("数量")]),_c('th',[_vm._v("タイトルに対して数量")]),_c('th',[_vm._v("入庫数（顧客から）")]),_c('th',[_vm._v("残数")]),_c('th'),_c('th')])])
}]
render._withStripped = true
export { render, staticRenderFns }