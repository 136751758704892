<template>
    <b-modal no-close-on-backdrop id="modal-partner" title="新規登録" title-class="font-18" scrollable hide-footer>
        <form class="needs-validation" @submit.prevent="formSubmitPartner" autocomplete="off">
            <div class="form-group row align-items-center">
                <label class="col-sm-3 mb-0">{{ $t('pagePartner.name') }}<span class="text-danger">*</span></label>
                <div class="col-sm-9">
                    <input
                        id="validationCustom052"
                        v-model="formPartner.name"
                        type="text"
                        class="form-control"
                        :class="{
                            'is-invalid': submittedPartner && $v.formPartner.name.$error
                        }"
                    />
                    <div v-if="submittedPartner && $v.formPartner.name.$error" class="invalid-feedback">
                        <span v-if="!$v.formPartner.name.required">{{
                            $t('validateField.required', {
                                field: $t('pagePartner.name')
                            })
                        }}</span>
                        <span v-if="!$v.formPartner.name.maxLength">{{
                            $t('validateField.max255', {
                                field: $t('client.name')
                            })
                        }}</span>
                    </div>
                </div>
            </div>
            <div class="form-group row align-items-center">
                <label class="col-sm-3">{{ $t('client.kana_name') }}<span class="text-danger">*</span></label>
                <div class="col-sm-9">
                    <input
                        id="validationCustom0533"
                        v-model="formPartner.kana_name"
                        type="text"
                        class="form-control"
                        :class="{
                            'is-invalid': submittedPartner && $v.formPartner.kana_name.$error
                        }"
                    />
                    <div v-if="submittedPartner && $v.formPartner.kana_name.$error" class="invalid-feedback">
                        <span v-if="!$v.formPartner.kana_name.required">{{
                            $t('validateField.required', {
                                field: $t('client.kana_name')
                            })
                        }}</span>
                        <span v-if="!$v.formPartner.kana_name.katakana && $v.formPartner.kana_name.maxLength">
                            {{
                                $t('validateField.incorrectFormat', {
                                    field: $t('client.kana_name')
                                })
                            }}</span
                        >
                        <span v-if="!$v.formPartner.kana_name.maxLength">{{
                            $t('validateField.max255', {
                                field: $t('client.kana_name')
                            })
                        }}</span>
                    </div>
                </div>
            </div>

            <div class="form-group row align-items-center">
                <label class="col-sm-3">{{ $t('client.postal_code') }}<span class="text-danger">*</span></label>
                <div class="col-sm-9">
                    <input
                        id="validationCustom054"
                        v-model="formPartner.postal_code"
                        autocomplete="off"
                        type="text"
                        class="form-control"
                        :class="{
                            'is-invalid': submittedPartner && $v.formPartner.postal_code.$error
                        }"
                        @input="inputCodePartner"
                    />
                    <div v-if="submittedPartner && $v.formPartner.postal_code.$error" class="invalid-feedback">
                        <span v-if="!$v.formPartner.postal_code.required">{{
                            $t('validateField.required', {
                                field: $t('client.postal_code')
                            })
                        }}</span>
                        <span v-if="!$v.formPartner.postal_code.maxLength">{{
                            $t('validateField.max20', {
                                field: $t('client.postal_code')
                            })
                        }}</span>
                    </div>
                </div>
            </div>

            <div class="form-group row align-items-center">
                <label class="col-sm-3">{{ $t('client.province') }}<span class="text-danger">*</span></label>
                <div class="col-sm-9">
                    <input
                        id="validationCustom055"
                        v-model="formPartner.province"
                        autocomplete="off"
                        type="text"
                        class="form-control"
                        :class="{
                            'is-invalid': submittedPartner && $v.formPartner.province.$error
                        }"
                        @input="inputAddressPartner"
                    />
                    <div v-if="submittedPartner && $v.formPartner.province.$error" class="invalid-feedback">
                        <span v-if="!$v.formPartner.province.required">{{
                            $t('validateField.required', {
                                field: $t('client.province')
                            })
                        }}</span>
                        <span v-if="!$v.formPartner.province.maxLength">{{
                            $t('validateField.max255', {
                                field: $t('client.province')
                            })
                        }}</span>
                    </div>
                </div>
            </div>

            <div class="form-group row align-items-center">
                <label class="col-sm-3">{{ $t('client.address_1') }}<span class="text-danger">*</span></label>
                <div class="col-sm-9">
                    <input
                        id="validationCustom305"
                        v-model="formPartner.address_1"
                        type="text"
                        class="form-control"
                        :class="{
                            'is-invalid': submittedPartner && $v.formPartner.address_1.$error
                        }"
                        placeholder="市区町村"
                        @input="inputAddressPartner"
                    />
                    <div v-if="submittedPartner && $v.formPartner.address_1.$error" class="invalid-feedback">
                        <span v-if="!$v.formPartner.address_1.required">{{
                            $t('validateField.required', {
                                field: $t('client.address_1')
                            })
                        }}</span>
                        <span v-if="!$v.formPartner.address_1.maxLength">{{
                            $t('validateField.max255', {
                                field: $t('client.address_1')
                            })
                        }}</span>
                    </div>
                </div>
            </div>

            <div class="form-group row align-items-center">
                <label class="col-sm-3 mw--form" for="validationCustom10">{{ $t('pagePartner.email') }} <span class="text-danger">*</span></label>
                <div class="col-sm-9">
                    <input
                        id="validationCustom10"
                        v-model="formPartner.email"
                        autocomplete="off"
                        type="text"
                        class="form-control"
                        :class="{
                            'is-invalid': submittedPartner && $v.formPartner.email.$error
                        }"
                    />
                    <div v-if="submittedPartner && $v.formPartner.email.$error" class="invalid-feedback">
                        <span v-if="!$v.formPartner.email.required">{{
                            $t('validateField.required', {
                                field: $t('pagePartner.email')
                            })
                        }}</span>
                        <div v-if="!$v.formPartner.email.maxLength">
                            <span>{{
                                $t('validateField.max255', {
                                    field: $t('pagePartner.email')
                                })
                            }}</span>
                        </div>
                        <div v-else>
                            <span v-if="!$v.formPartner.email.email">{{
                                $t('validateField.incorrectFormat', {
                                    field: $t('pagePartner.email')
                                })
                            }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group row align-items-center">
                <label class="col-sm-3 mw--form" for="validationCustom11"
                    >{{ $t('pagePartner.type') }}
                    <span class="text-danger">*</span>
                </label>
                <div class="col-sm-9">
                    <div class="d-flex flex-wrap">
                        <div
                            class="custom-control custom-checkbox mr-4 py-1"
                            style="min-width: 100px"
                            v-for="item in listDataDepartment"
                            :key="item.id"
                        >
                            <input
                                type="checkbox"
                                v-model="formPartner.types"
                                :id="`checktype${item.id}`"
                                :value="item.id"
                                class="custom-control-input"
                            />
                            <label :for="`checktype${item.id}`" class="custom-control-label">{{ item.value }}</label>
                        </div>
                    </div>
                    <span
                        :class="{
                            'is-invalid': submittedPartner && $v.formPartner.types.$error
                        }"
                    ></span>
                    <div v-if="submittedPartner && $v.formPartner.types.$error" class="invalid-feedback">
                        <span v-if="!$v.formPartner.types.required">{{
                            $t('validateField.requiredSelect', {
                                field: $t('pagePartner.type')
                            })
                        }}</span>
                    </div>
                </div>
            </div>

            <div class="form-group row align-items-center">
                <label class="col-sm-3">担当者<span class="text-danger">*</span></label>
                <div class="col-sm-9">
                    <input
                        id="validationCustom05"
                        v-model="formPartner.namePic"
                        type="text"
                        class="form-control"
                        :class="{
                            'is-invalid': submittedPartner && $v.formPartner.namePic.$error
                        }"
                    />
                    <div v-if="submittedPartner && $v.formPartner.namePic.$error" class="invalid-feedback">
                        <span v-if="!$v.formPartner.namePic.required">{{
                            $t('validateField.required', {
                                field: $t('client.namePic')
                            })
                        }}</span>
                        <span v-if="!$v.formPartner.namePic.maxLength">{{
                            $t('validateField.max255', {
                                field: $t('client.namePic')
                            })
                        }}</span>
                    </div>
                </div>
            </div>

            <div class="form-group row align-items-center">
                <label class="col-sm-3 mw--form" for="validationCustom027"
                    >{{ $t('pagePartner.emailPartner') }} <span class="text-danger">*</span></label
                >
                <div class="col-sm-9">
                    <input
                        id="validationCustom027"
                        v-model="formPartner.emailPic"
                        autocomplete="off"
                        type="text"
                        class="form-control"
                        :class="{
                            'is-invalid': submittedPartner && $v.formPartner.emailPic.$error
                        }"
                    />
                    <div v-if="submittedPartner && $v.formPartner.emailPic.$error" class="invalid-feedback">
                        <span v-if="!$v.formPartner.emailPic.required">{{
                            $t('validateField.required', {
                                field: $t('pagePartner.email')
                            })
                        }}</span>
                        <div v-if="!$v.formPartner.emailPic.maxLength">
                            <span>{{
                                $t('validateField.max255', {
                                    field: $t('pagePartner.email')
                                })
                            }}</span>
                        </div>
                        <div v-else>
                            <span v-if="!$v.formPartner.emailPic.email">{{
                                $t('validateField.incorrectFormat', {
                                    field: $t('pagePartner.email')
                                })
                            }}</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group row align-items-center">
                <label class="col-sm-3 mw--form" for="validationCustom08">{{ $t('pagePartner.password') }} <span class="text-danger">*</span></label>
                <div class="col-sm-9">
                    <input
                        id="validationCustom08"
                        v-model="formPartner.passwordPic"
                        type="password"
                        name="password"
                        autocomplete="new-password"
                        class="form-control"
                        :class="{
                            'is-invalid': submittedPartner && $v.formPartner.passwordPic.$error
                        }"
                    />
                    <span>
                        {{ $t('notificationCommon.notePass') }}
                    </span>
                    <div v-if="submittedPartner && $v.formPartner.passwordPic.$error" class="invalid-feedback">
                        <span v-if="!$v.formPartner.passwordPic.required">{{
                            $t('validateField.required', {
                                field: $t('pagePartner.password')
                            })
                        }}</span>
                    </div>
                </div>
            </div>

            <div class="form-group row align-items-center">
                <label class="col-sm-3 mw--form" for="validationCustom07">部署</label>
                <div class="col-sm-9">
                    <input
                        id="validationCustom07"
                        v-model="formPartner.department"
                        autocomplete="off"
                        type="text"
                        class="form-control"
                        :class="{
                            'is-invalid': submittedPartner && $v.formPartner.department.$error
                        }"
                    />
                    <div v-if="submittedPartner && $v.formPartner.department.$error" class="invalid-feedback">
                        <span v-if="!$v.formPartner.department.required">{{
                            $t('validateField.max255', {
                                field: '部署'
                            })
                        }}</span>
                    </div>
                </div>
            </div>

            <div>
                <div class="d-flex justify-content-center">
                    <div class="text-center flex-fill">
                        <button type="submit" class="btn btn-primary">
                            {{ $t('btn.register') }}
                        </button>
                    </div>
                </div>
            </div>
        </form>
    </b-modal>
</template>
<script>
import { arrayTypeFixed, formPartner } from '@/config/var-common';
import { email, maxLength, required, url } from 'vuelidate/lib/validators';
import { partnersMethods } from '@/state/helpers';
import { regexNumber } from '@/utils/format';
import { getAddressByZip, getPostCodeByAddress } from 'postcode-address-get';

export default {
    name: 'modalPartner',
    props: {},
    data: function () {
        return {
            submittedPartner: false,
            formPartner: JSON.parse(JSON.stringify(formPartner)),
            listDataDepartment: arrayTypeFixed
        };
    },
    watch: {},
    validations: {
        formPartner: {
            name: { required, maxLength: maxLength(255) },
            department: { maxLength: maxLength(255) },
            kana_name: {
                required,
                maxLength: maxLength(255),
                katakana() {
                    if (this.formPartner.kana_name && this.formPartner.kana_name.length < 255) {
                        const katakanaRegex = /^[\u30A0-\u30FF\s]+$/u;

                        if (katakanaRegex.test(this.formPartner.kana_name)) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                    return true;
                }
            },
            postal_code: { required, maxLength: maxLength(20) },
            province: { required, maxLength: maxLength(255) },
            address_1: { required, maxLength: maxLength(255) },
            address_2: { maxLength: maxLength(255) },
            url: { url },
            keyword: { maxLength: maxLength(255) },
            email: { required, email, maxLength: maxLength(255) },
            phone_number: { maxLength: maxLength(20) },
            types: { required },
            namePic: { required, maxLength: maxLength(255) },
            emailPic: { required, email, maxLength: maxLength(255) },
            passwordPic: {
                required,
                validate() {
                    if (this.formPartner.passwordPic && this.formPartner.passwordPic.length < 255) {
                        const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,32}$/;
                        if (passwordRegex.test(this.formPartner.passwordPic)) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                    return true;
                }
            }
        }
    },
    methods: {
        ...partnersMethods,
        inputCodePartner() {
            this.formPartner.postal_code = regexNumber(this.formPartner.postal_code);
            if (!this.formPartner.postal_code) {
                this.formPartner.province = this.formPartner.address_1 = '';
            } else {
                if (this.formPartner.postal_code.length < 3) return;
                getAddressByZip(this.formPartner.postal_code, (address) => {
                    if (address.status == 200) {
                        this.formPartner.province = address.prefecture;
                        this.formPartner.address_1 = address.city + address.area;
                    }
                });
            }
        },
        inputAddressPartner() {
            getPostCodeByAddress(this.formPartner.province + this.formPartner.address_1, (dataPostcode) => {
                if (dataPostcode.status == 200) this.formPartner.postal_code = dataPostcode.postcode;
            });
        },
        formSubmitPartner() {
            this.submittedPartner = true;
            this.$v.formPartner.$touch();
            if (this.$v.formPartner.$invalid) {
                return;
            } else {
                this.registerPartner();
            }
        },
        registerPartner() {
            if (this.formPartner.name && !this.loading) {
                this.loading = true;
                let dataObject = Object.assign(this.formPartner);
                dataObject.pic = [
                    {
                        name: dataObject.namePic,
                        email: dataObject.emailPic,
                        password: dataObject.passwordPic,
                        department: dataObject.department
                    }
                ];
                this.registerPartners(dataObject).then((data) => {
                    if (data.code == 200) {
                        this.$bvToast.toast(`Success`, {
                            title: `登録されました。`,
                            variant: 'success',
                            toaster: 'b-toaster-top-center',
                            solid: true
                        });
                        this.formPartner = JSON.parse(JSON.stringify(formPartner));
                        this.$bvModal.hide('modal-partner');
                        this.$emit('refresh');
                    } else {
                        if (data.message === 'name.unique') {
                            this.$bvToast.toast(`Error`, {
                                title: this.$t('pagePartner.name_unique'),
                                variant: 'danger',
                                toaster: 'b-toaster-top-center',
                                solid: true
                            });
                        } else {
                            this.$bvToast.toast(`Error`, {
                                title: this.$t(data.message),
                                variant: 'danger',
                                toaster: 'b-toaster-top-center',
                                solid: true
                            });
                        }
                    }
                    this.loading = false;
                    this.submittedPartner = false;
                });
            }
        }
    },
    computed: {}
};
</script>

<style scoped lang="scss"></style>
