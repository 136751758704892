<template>
    <div>
        <ModalCommon :id="idTable" :config="configModalDetailsReturns" @save="handleSaveDetailReturn()" @close="handleCloseDetailReturn()">
            <div class="d-flex justify-content-end mb-3" style="gap: 12px">
                <button type="button" class="btn btn-sm btn-primary" style="white-space: nowrap" @click="handleReturnPrize()">
                    {{ this.$t('pageCampaign.tabPrize.addReturnPrize') }}
                </button>
            </div>
            <div class="table-responsive" style="height: 620px; overflow-y: auto; outline: none">
                <table class="table table-bordered custom__table--sticky">
                    <thead>
                        <tr>
                            <th v-for="(item, k) in configTableDetailReturn.headers" :key="k">
                                <div class="d-flex align-items-center" style="min-width: max-content; gap: 5px">
                                    {{ item.label }}
                                    <span v-if="item.required" class="text-danger">*</span>
                                </div>
                            </th>
                        </tr>
                    </thead>

                    <tbody>
                        <tr v-for="(item, idx) in listDetailReturns" :key="idx">
                            <td>
                                <div style="min-width: 150px">
                                    <DateFormat :id="`returnDate${idx}`" :model.sync="item.return_date" :config.sync="item.configReturnDate" />
                                </div>
                            </td>

                            <td>
                                <div style="min-width: 150px">
                                    <InputText
                                        :id="`shippingCompanyReturn${idx}`"
                                        :model.sync="item.shipping_company"
                                        :config.sync="item.configShippingCompany"
                                    />
                                </div>
                            </td>

                            <td>
                                <div style="min-width: 150px">
                                    <InputText
                                        :id="`tracking_number${idx}`"
                                        :model.sync="item.tracking_number"
                                        :config.sync="item.configTrakingNumber"
                                    />
                                </div>
                            </td>

                            <td>
                                <div style="min-width: 200px">
                                    <textarea
                                        rows="3"
                                        v-model="item.reason"
                                        :class="`form-control ${item.configReason.isRequired ? 'is-invalid' : ''}`"
                                        @input="item.configReason.isRequired = false"
                                    ></textarea>
                                    <div class="invalid-feedback d-block">
                                        <span v-if="item.configReason.isRequired">{{
                                            $t('validateField.required', {
                                                field: item.configReason.errorField
                                            })
                                        }}</span>
                                    </div>
                                </div>
                            </td>

                            <td>
                                <div style="min-width: 150px">
                                    <InputText :id="`prefectures${idx}`" :model.sync="item.province" :config.sync="item.confingProvince" />
                                </div>
                            </td>

                            <td>
                                <div style="min-width: 150px">
                                    <InputText
                                        :id="`returnShippingFee${idx}`"
                                        :model.sync="item.shipping_return_fee"
                                        :config.sync="item.configShippingReturnFee"
                                    />
                                </div>
                            </td>

                            <td>
                                <div style="min-width: 200px">
                                    <MultiSelectCommon
                                        :id="`select-returnStatus${idx}`"
                                        :value.sync="item.return_status"
                                        :options="listReturnStatus"
                                        :config="item.configReturnStatus"
                                        @open="getListReturnStatus(item.configReturnStatus)"
                                        @select="handleSelectReturnStatus(item)"
                                        @remove="handleRemoveReturnStatus(item)"
                                    ></MultiSelectCommon>
                                </div>
                            </td>

                            <td>
                                <div style="min-width: 150px">
                                    <DateFormat
                                        :id="`reconfirmationDate${idx}`"
                                        :model.sync="item.reconfirmation_schedule"
                                        :disabled="hasReturnStatus(item)"
                                    />
                                </div>
                            </td>

                            <td>
                                <div style="min-width: 150px">
                                    <InputText
                                        :id="`instructionsForReturningPrizes${idx}`"
                                        :model.sync="item.instruction"
                                        :config.sync="item.configInstruction"
                                    />
                                </div>
                            </td>

                            <td>
                                <div style="min-width: 150px">
                                    <InputText :id="`locatorNumber${idx}`" :model.sync="item.storage_code" :config.sync="item.configStorageCode" />
                                </div>
                            </td>

                            <td>
                                <div style="min-width: 200px">
                                    <textarea rows="3" v-model="item.note" class="form-control"></textarea>
                                </div>
                            </td>

                            <td>
                                <div class="d-flex" style="gap: 10px">
                                    <button class="btn btn-info btn-sm" style="min-width: fit-content" @click="handleAddDetail(idx)">
                                        <i class="fa fa-plus"></i>
                                    </button>
                                    <button class="btn btn-danger btn-sm" style="min-width: fit-content" @click="handleRemoveDetail(idx)">
                                        <i class="fa fa-minus"></i>
                                    </button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </ModalCommon>
    </div>
</template>

<script>
import _ from 'lodash';
import { InputText } from '@/components/Input';
import { CONFIG_TABLE_DETAIL_RETURN, CONFIG_MODAL_DETAIL_RETURN } from '../../constans';
import { initObjectReturnPrizeItem } from '../../index';
import { handleValidate } from './validate';
import { keyMaster, masterMethods } from '@/state/helpers';
import ModalCommon from '@/components/Modal/main.vue';
import MultiSelectCommon from '@/components/Select/multi/main.vue';
import DateFormat from '@/components/Select/DateFormat/main.vue';

export default {
    name: 'ModalDetailsReturns',
    components: {
        ModalCommon,
        InputText,
        MultiSelectCommon,
        DateFormat
    },
    props: {
        id: {
            type: String,
            default: ''
        },
        isShow: {
            type: Boolean,
            default: false
        },
        dataDetailReturns: {
            type: Array,
            default: () => []
        },
        returnPrizeItemsCount: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            idTable: this.id,
            listDetailReturns: this.dataDetailReturns,
            listDetailReturnsCopy: [],
            configTableDetailReturn: CONFIG_TABLE_DETAIL_RETURN,
            configModalDetailsReturns: CONFIG_MODAL_DETAIL_RETURN,
            countPrizeItemsReturn: this.returnPrizeItemsCount,
            listReturnStatus: []
        };
    },
    methods: {
        ...masterMethods,

        handleReturnPrize() {
            this.listDetailReturns.push(initObjectReturnPrizeItem());
        },

        handleAddDetail(idx) {
            this.listDetailReturns.splice(idx + 1, 0, initObjectReturnPrizeItem());
        },
        handleRemoveDetail(idx) {
            this.listDetailReturns.splice(idx, 1);
        },
        handleCloseDetailReturn() {
            this.listDetailReturns = this.listDetailReturnsCopy;
            this.countPrizeItemsReturn = this.listDetailReturns.length;
            this.$emit('update:returnPrizeItemsCount', this.countPrizeItemsReturn);
            this.$emit('update:dataDetailReturns', this.listDetailReturns);
            this.$emit('update:isShow', false);
        },
        handleSaveDetailReturn() {
            let checkValidate = handleValidate(this.listDetailReturns);
            if (!checkValidate) {
                this.configModalDetailsReturns.model = true;
                return;
            }
            this.listDetailReturnsCopy = _.cloneDeep(this.listDetailReturns);
            this.countPrizeItemsReturn = this.listDetailReturns.length;
            this.$emit('update:returnPrizeItemsCount', this.countPrizeItemsReturn);
            this.$emit('update:dataDetailReturns', this.listDetailReturns);
            this.$emit('update:isShow', false);
        },

        async getListReturnStatus(configReturnStatus) {
            if (configReturnStatus.loading) return;
            configReturnStatus.loading = true;
            this.listReturnStatus = [];
            try {
                let data = await this.listMaster(keyMaster['prize.corresponding_status']);
                this.listReturnStatus = data;
            } catch (error) {
                console.log(error);
            } finally {
                configReturnStatus.loading = false;
            }
        },
        handleSelectReturnStatus(param) {
            if (param.return_status?.value !== '確認中' && param.return_status?.value !== '保管') {
                param.reconfirmation_schedule = null;
            }
        },
        handleRemoveReturnStatus(param) {
            param.reconfirmation_schedule = null;
        },

        hasReturnStatus(param) {
            return param.return_status?.value === '確認中' || param.return_status?.value === '保管' ? false : true;
        }
    },
    watch: {
        isShow: {
            handler: function (valShow) {
                this.configModalDetailsReturns.model = valShow;
                if (valShow) {
                    this.listDetailReturnsCopy = _.cloneDeep(this.listDetailReturns);
                }
            },
            immediate: true
        }
    }
};
</script>

<style lang="scss" scoped>
td,
th {
    vertical-align: middle;
    white-space: nowrap;
}
th,
td {
    white-space: nowrap;
}
th:last-child,
td:last-child {
    position: sticky;
    right: 0;
    background: #fff;
}
th:last-child {
    z-index: 100;
}
td:last-child {
    z-index: 99;
}
</style>
