<template>
    <div>
        <div class="form-group row">
            <label class="col-sm-3">進捗ステータス</label>
            <div class="col-sm-8">
                <div style="position: relative">
                    <MultiSelectCommon
                        :id="`select-progres-status`"
                        :value.sync="progressStatus"
                        :options="campaignStatus"
                        :config="{ trackBy: 'id', label: 'text', error: false, isRemove: false }"
                    />
                </div>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3">キャンペーン名称<span class="text-danger">*</span></label>
            <div class="col-sm-8">
                <InputText
                    :model.sync="form.name"
                    :config="{
                        isRequired: !validate.form.name.required,
                        isMaxLength: !validate.form.name.maxLength,
                        error: submitted && validate.form.name.$error,
                        errorField: $t('campaign.name')
                    }"
                />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-sm-3">ZACID</label>
            <div class="col-sm-8">
                <InputText
                    :model.sync="form.zac_id"
                    :config="{
                        isMaxLength: !validate.form.zac_id.maxLength,
                        error: submitted && validate.form.zac_id.$error,
                        errorField: 'ZACID'
                    }"
                />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-sm-3">{{ $t('pageCampaign.sfid') }}</label>
            <div class="col-sm-8">
                <InputText
                    :model.sync="form.sfid"
                    :config="{
                        isMaxLength: !validate.form.sfid.maxLength,
                        error: submitted && validate.form.sfid.$error,
                        errorField: $t('pageCampaign.sfid')
                    }"
                />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-sm-3">キャンペーン種別</label>
            <div class="col-sm-8">
                <div class="d-flex">
                    <div class="custom-control custom-radio mr-4" v-for="item in listDataMasterCampaignType" :key="item.id + 's'">
                        <input
                            type="radio"
                            v-model="form.campaign_type"
                            :id="`check${item.id}`"
                            :value="item.id"
                            class="custom-control-input"
                            @change="getDataCampaignTye()"
                        />
                        <label :for="`check${item.id}`" class="custom-control-label">{{ item.value }}</label>
                    </div>
                </div>
            </div>
        </div>

        <div class="form-group row" v-if="form.campaign_type">
            <label class="col-sm-3">応募種別</label>
            <div class="col-sm-8">
                <div class="d-flex flex-wrap">
                    <div class="custom-control custom-checkbox mr-4" v-for="item in listDataMasterCampaignTypeChild" :key="item.id + 'sss'">
                        <input
                            @change="setCondition1()"
                            type="checkbox"
                            v-model="listCategories"
                            :id="`checks${item.id}`"
                            :value="item.id"
                            class="custom-control-input"
                        />
                        <label :for="`checks${item.id}`" class="custom-control-label">{{ item.value }}</label>
                    </div>
                </div>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-sm-3">受注範囲</label>
            <div class="col-sm-8">
                <button type="button" class="btn btn-sm btn-info" @click="handleOpenScopeOrder()">詳細</button>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-sm-3 pr-0">キャンペーン開始年月日</label>
            <div class="col-sm-8">
                <DateTimeCommon
                    id="select-campaign-start-date-time"
                    :full_date.sync="form.campaign_start_date"
                    :full_time.sync="form.campaign_start_time"
                    :config.sync="form.configCampaignStartDateTime"
                >
                    <span v-if="form.configCampaignStartDateTime.error" class="invalid-feedback d-block">{{
                        form.configCampaignStartDateTime.errorDateTimeStartCampaign
                    }}</span>
                </DateTimeCommon>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-sm-3" for="validationCustom05">キャンペーン終了年月日</label>
            <div class="col-sm-8">
                <DateTimeCommon
                    id="select-campaign-end-date-time"
                    :full_date.sync="form.campaign_end_date"
                    :full_time.sync="form.campaign_end_time"
                    :config.sync="form.configCampaignEndDateTime"
                >
                    <span v-if="form.configCampaignEndDateTime.error" class="invalid-feedback d-block">{{
                        form.configCampaignEndDateTime.errorDateTimeEndCampaign
                    }}</span>
                </DateTimeCommon>
            </div>
        </div>
        <div class="form-group row">
            <label class="col-sm-3" for="validationCustom05">事務局開始年月日</label>
            <div class="col-sm-8">
                <DateTimeCommon
                    id="select-secretariat-start-date-time"
                    :full_date.sync="form.secretariat_start_date"
                    :full_time.sync="form.secretariat_start_time"
                    :config.sync="form.configSecretariatStartDateTime"
                >
                    <span v-if="form.configSecretariatStartDateTime.error" class="invalid-feedback d-block">{{
                        form.configSecretariatStartDateTime.errorDateTimeStartSecretariat
                    }}</span>
                </DateTimeCommon>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-sm-3" for="validationCustom05">事務局終了年月日</label>
            <div class="col-sm-8">
                <DateTimeCommon
                    id="select-secretariat-end-date-time"
                    :full_date.sync="form.secretariat_end_date"
                    :full_time.sync="form.secretariat_end_time"
                    :config.sync="form.configSecretariatEndDateTime"
                >
                    <span v-if="form.configSecretariatEndDateTime.error" class="invalid-feedback d-block">{{
                        form.configSecretariatEndDateTime.errorDateTimeEndSecretariat
                    }}</span>
                </DateTimeCommon>
            </div>
        </div>

        <div v-if="form.isShowReceiptExpirationDate" class="form-group row">
            <label class="col-sm-3 pr-0">レシート有効期限 </label>
            <div class="col-sm-8">
                <DatePickerCommon
                    :year.sync="form.receipt_expiration_date_y"
                    :month.sync="form.receipt_expiration_date_m"
                    :day.sync="form.receipt_expiration_date_d"
                    :full_date.sync="form.receipt_expiration_date"
                    :config.sync="form.configReceiptExpirationDate"
                >
                    <div v-if="form.configReceiptExpirationDate.error" class="invalid-feedback d-block">
                        <span>{{ form.configReceiptExpirationDate.errorText }}</span>
                    </div>
                </DatePickerCommon>
            </div>
        </div>

        <template v-for="(item, k) in listCategoriesSub">
            <div
                :key="k + item.category_id + 's'"
                class="mb-1"
                style="position: relative; padding: 10px; border: 1px solid #efefef; border-radius: 10px"
            >
                <div class="row">
                    <label class="col-sm-3">{{ renderNameCategory(item) }}</label>
                </div>

                <div v-if="renderNameCategory(item) == 'WEB'" class="form-group row">
                    <label class="col-sm-3">応募フォーム</label>
                    <div class="col-sm-6">
                        <InputText
                            :model.sync="form.application_form"
                            :config="{
                                maxlength: 255,
                                error: false,
                                errorField: '応募フォーム'
                            }"
                        />
                    </div>
                </div>

                <div v-if="renderNameCategory(item) == '流通' && form.campaign_type == 81" class="form-group row">
                    <label class="col-sm-3">流通数</label>
                    <div class="col-sm-6">
                        <InputText
                            :model.sync="item.extension.number_of_circulation"
                            :config="{
                                isMaxLength: !validate.listCategoriesSub.$each[k].extension.number_of_circulation.maxLength,
                                error: submitted && validate.listCategoriesSub.$each[k].extension.number_of_circulation.$error,
                                errorField: '流通数'
                            }"
                        />
                    </div>
                </div>

                <div v-if="renderNameCategory(item) == 'タイアップ' && form.campaign_type == 81" class="form-group row">
                    <label class="col-sm-3">企業情報</label>
                    <div class="col-sm-6">
                        <textarea rows="3" v-model="item.extension.company_information" class="form-control"></textarea>
                    </div>
                </div>

                <div v-if="renderNameCategory(item) == 'その他'" class="form-group row">
                    <label class="col-sm-3"></label>
                    <div class="col-sm-6">
                        <textarea rows="3" v-model="item.extension.other_type_data" class="form-control"></textarea>
                    </div>
                </div>

                <div
                    v-if="
                        renderNameCategory(item) == 'WEB' ||
                        renderNameCategory(item) == 'ハガキ' ||
                        renderNameCategory(item) == 'Twitter' ||
                        renderNameCategory(item) == 'Instagram' ||
                        renderNameCategory(item) == 'LINE' ||
                        renderNameCategory(item) == 'Tiktok' ||
                        renderNameCategory(item) == '店頭' ||
                        renderNameCategory(item) == 'アプリ'
                    "
                    class="form-group row"
                >
                    <label class="col-sm-3">応募条件①</label>
                    <div class="col-sm-6">
                        <div style="position: relative">
                            <MultiSelectCommon
                                :id="`select-application-condition-1${k}`"
                                :value.sync="item.application_condition_1"
                                :options="item.listCondition1"
                                :config="{ trackBy: 'id', label: 'value', error: false, isRemove: false }"
                                @close="setCondition2(item)"
                                @remove="handleRemove(item)"
                            />
                        </div>
                    </div>
                </div>

                <div class="row" v-if="item.application_condition_1 && item.application_condition_1.id">
                    <label class="col-sm-3">応募条件②</label>
                    <div class="col-sm-8">
                        <div class="d-flex flex-wrap">
                            <div class="custom-control custom-checkbox mr-4" v-for="jtem in item.listCondition2" :key="jtem.id + 'sss'">
                                <input
                                    type="checkbox"
                                    v-model="item.application_condition_2_id"
                                    :id="`checksh${jtem.id}`"
                                    :value="jtem.id"
                                    class="custom-control-input"
                                    @change="handleShowConditionBuyAndSell('change')"
                                />
                                <label :for="`checksh${jtem.id}`" class="custom-control-label">{{ jtem.value }}</label>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="form-group row">
                    <label class="col-sm-3">応募方法</label>
                    <div class="col-sm-6">
                        <textarea rows="5" class="form-control" v-model="item.extension.application_method"></textarea>
                    </div>
                </div>

                <div class="form-group row mt-2" v-if="item.checkSNS >= 0">
                    <label class="col-sm-3" for="validationCustom05">公式アカウント名</label>
                    <div class="col-sm-8">
                        <input
                            type="text"
                            class="form-control"
                            v-model="item.extension.offical_name_sns"
                            :class="{
                                'is-invalid': submitted && validate.listCategoriesSub.$each[k].extension.offical_name_sns.$error
                            }"
                        />
                        <div v-if="submitted && validate.listCategoriesSub.$each[k].extension.offical_name_sns.$error" class="invalid-feedback">
                            <span v-if="!validate.listCategoriesSub.$each[k].extension.offical_name_sns.maxLength">{{
                                $t('validateField.max255', {
                                    field: '公式アカウント名'
                                })
                            }}</span>
                        </div>
                    </div>
                </div>

                <div class="form-group row mt-2" v-if="item.checkSNS >= 0">
                    <label class="col-sm-3" for="validationCustom05">公式ID</label>
                    <div class="col-sm-8">
                        <input
                            type="text"
                            class="form-control"
                            v-model="item.extension.offical_id_sns"
                            :class="{
                                'is-invalid': submitted && validate.listCategoriesSub.$each[k].extension.offical_id_sns.$error
                            }"
                        />
                        <div v-if="submitted && validate.listCategoriesSub.$each[k].extension.offical_id_sns.$error" class="invalid-feedback">
                            <span v-if="!validate.listCategoriesSub.$each[k].extension.offical_id_sns.maxLength">{{
                                $t('validateField.max255', {
                                    field: '公式ID'
                                })
                            }}</span>
                        </div>
                    </div>
                </div>

                <div
                    v-if="renderNameCategory(item) == 'Twitter' || renderNameCategory(item) == 'Tiktok' || renderNameCategory(item) == 'Instagram'"
                    class="form-group row"
                >
                    <label class="col-sm-3">ハッシュタグ</label>
                    <div class="col-sm-6">
                        <KeyWordTag checkPre="hash" :config.sync="item.configHashTag" />
                    </div>
                </div>

                <a href="javascript:void(0)" style="position: absolute; right: 10px; top: 10px" @click="deleteCategory(item)" class="text-danger"
                    >削除</a
                >
            </div>
        </template>

        <!-- <div class="form-group row mt-3" v-if="form.campaign_type && checkShow(form.campaign_type)">
            <label class="col-sm-3">購入日時 </label>
            <div class="col-sm-8">
                <input
                    type="text"
                    class="form-control"
                    v-model="form.purchase_datetime"
                    :class="{
                        'is-invalid': submitted && validate.form.purchase_datetime.$error
                    }"
                />
                <div v-if="submitted && validate.form.purchase_datetime.$error" class="invalid-feedback">
                    <span v-if="!validate.form.purchase_datetime.maxLength">
                        {{
                            $t('validateField.max255', {
                                field: '購入日時'
                            })
                        }}
                    </span>
                </div>
            </div>
        </div> -->

        <div v-if="form.isShowConditionBuyAndSell">
            <div class="form-group row">
                <label class="col-sm-3">購買条件</label>
                <div class="col-sm-6">
                    <div class="d-flex flex-wrap">
                        <div class="custom-control custom-checkbox mr-4 mb-2" v-for="item in listDataMasterConditionPurchase1" :key="item.id + 'pt'">
                            <input
                                type="checkbox"
                                v-model="form.purchase_conditions"
                                :id="`checkpst${item.id}`"
                                :value="item.id"
                                class="custom-control-input"
                                @change="handleClearDataPurchaseConditions(item)"
                            />
                            <label :for="`checkpst${item.id}`" class="custom-control-label">{{ item.value }}</label>
                        </div>
                    </div>
                </div>
            </div>

            <template v-for="item in form.purchase_conditions">
                <div class="form-group row" :key="'target' + item">
                    <label class="col-sm-3">{{ renderNamePurchase(item) }} 入力 </label>
                    <div class="col-sm-6">
                        <div class="d-flex align-items-center">
                            <input
                                v-model="purchaseConditions[item]"
                                class="form-control mr-1"
                                @input="
                                    () => {
                                        $emit('update:purchase_conditions', purchaseConditions);
                                    }
                                "
                            />
                        </div>
                    </div>
                </div>
            </template>
        </div>

        <div v-if="form.isShowReceiptExpirationDate">
            <div class="form-group row">
                <label class="col-sm-3">対象商品カテゴリ</label>
                <div class="col-sm-9">
                    <div class="d-flex flex-wrap">
                        <div
                            class="custom-control custom-checkbox mr-4 mb-2"
                            v-for="item in listDataMasterCategoryProductTarget"
                            :key="item.id + 'pt'"
                        >
                            <input
                                type="checkbox"
                                v-model="form.target_product_lists"
                                :id="`checkpt${item.id}`"
                                :value="item.id"
                                class="custom-control-input"
                                @change="handleClearDataTargetProduct(item)"
                            />
                            <label :for="`checkpt${item.id}`" class="custom-control-label">{{ item.value }}</label>
                        </div>
                    </div>
                </div>
            </div>

            <template v-for="item in form.target_product_lists">
                <div class="form-group row" :key="'target' + item">
                    <label class="col-sm-3">詳細 {{ renderNameTargetProduct(item) }}</label>
                    <div class="col-sm-6">
                        <textarea
                            rows="3"
                            v-model="targetProductLists[item]"
                            class="form-control"
                            @input="
                                () => {
                                    $emit('update:target_product_lists', targetProductLists);
                                }
                            "
                        ></textarea>
                    </div>
                </div>
            </template>
        </div>

        <div class="form-group row mt-3">
            <label class="col-sm-3">告知</label>
            <div class="col-sm-9">
                <input
                    type="text"
                    class="form-control"
                    placeholder="公式サイト／POP／公式SNS／デジタル・ウェブ広告"
                    v-model="form.inform"
                    :class="{
                        'is-invalid': submitted && validate.form.inform.$error
                    }"
                />
                <div v-if="submitted && validate.form.inform.$error" class="invalid-feedback">
                    <span v-if="!validate.form.inform.maxLength">
                        {{
                            $t('validateField.max255', {
                                field: '告知'
                            })
                        }}
                    </span>
                </div>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-sm-3">LP サイト</label>
            <div class="col-sm-9">
                <input
                    type="text"
                    class="form-control"
                    v-model="form.url"
                    :class="{
                        'is-invalid': submitted && validate.form.url.$error
                    }"
                />
                <div v-if="submitted && validate.form.url.$error" class="invalid-feedback">
                    <span v-if="!validate.form.url.maxLength">
                        {{
                            $t('validateField.incorrectURL', {
                                field: 'LP サイト'
                            })
                        }}
                    </span>
                </div>
            </div>
        </div>

        <div v-if="form.isShowPublicTime" class="form-group row">
            <label class="col-sm-3">公開期間 </label>
            <div class="col-sm-9">
                <div class="d-flex mb-3" style="gap: 10px">
                    <DateTimeCommon
                        id="select-url-publish-time-from"
                        :full_date.sync="form.url_publish_time_from"
                        :full_time.sync="form.url_publish_time_from_time"
                        :config.sync="form.configUrlPublishTimeFrom"
                    >
                        <span v-if="form.configUrlPublishTimeFrom.error" class="invalid-feedback d-block">{{
                            form.configUrlPublishTimeFrom.errorText
                        }}</span>
                    </DateTimeCommon>
                    <div>
                        <span>から</span>
                    </div>
                </div>
                <div class="d-flex" style="gap: 10px">
                    <DateTimeCommon
                        id="select-url-time-to"
                        :full_date.sync="form.url_publish_time_to"
                        :full_time.sync="form.url_publish_time_to_time"
                        :config.sync="form.configUrlPublishTimeTo"
                    >
                        <span v-if="form.configUrlPublishTimeTo.error" class="invalid-feedback d-block">{{
                            form.configUrlPublishTimeTo.errorText
                        }}</span>
                    </DateTimeCommon>
                    <div>
                        <span>まで</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-sm-3">当選者数</label>
            <div class="col-sm-9">
                <input class="form-control" type="text" :disabled="true" :value="form.total_number_of_win_prize" />
            </div>
        </div>

        <div class="form-group row">
            <label class="col-sm-3">賞品進呈時期(発送時期・送付時期)</label>
            <div class="col-sm-9">
                <input
                    class="form-control"
                    type="text"
                    v-model="form.prize_shipping_period"
                    :class="{
                        'is-invalid': submitted && validate.form.prize_shipping_period.$error
                    }"
                />
                <div v-if="submitted && validate.form.prize_shipping_period.$error" class="invalid-feedback">
                    <span v-if="!validate.form.prize_shipping_period.maxLength">
                        {{
                            $t('validateField.max255', {
                                field: '賞品進呈時期(発送時期・送付時期)'
                            })
                        }}
                    </span>
                </div>
            </div>
        </div>

        <div class="form-group row">
            <label class="col-sm-3">お問い合わせ事務局名 </label>
            <div class="col-sm-9">
                <textarea
                    class="form-control"
                    v-model="form.inquiry_office"
                    :class="{
                        'is-invalid': submitted && validate.form.inquiry_office.$error
                    }"
                ></textarea>
                <div v-if="submitted && validate.form.inquiry_office.$error" class="invalid-feedback">
                    <span v-if="!validate.form.inquiry_office.maxLength">
                        {{
                            $t('validateField.max255', {
                                field: 'お問い合わせ事務局名'
                            })
                        }}
                    </span>
                </div>
            </div>
        </div>

        <div v-for="item in listContactInformations" :key="item.id + 's'">
            <div v-if="form.contact_informations.includes(item.id)" class="form-group row">
                <label class="col-sm-3">{{ item.subText }}</label>
                <div class="col-sm-9">
                    <div v-if="item.child && item.child.length > 0">
                        <div v-for="(oChild, idoChild) in item.child" :key="idoChild" class="col-sm-9">
                            <div v-if="form.contact_informations.includes(oChild.id)">
                                <div class="form-group row">
                                    <label class="col-sm-3">{{ oChild.text }}</label>
                                    <div class="col-sm-9">
                                        <InputText :model.sync="contact_informations[oChild.id]" :config.sync="oChild.configInput" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div>
                            <InputText
                                :model.sync="contact_informations[item.id]"
                                :config.sync="item.configInput"
                                :disabled="item.subText == 'TEL入力'"
                            />
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div>
            <div class="form-group row">
                <label class="col-sm-3">検索キーワード</label>
                <div class="col-sm-9">
                    <KeyWordTag :config="configKeyword" />
                    <span v-if="configKeyword.error" class="text-danger">
                        {{
                            $t('validateField.max255', {
                                field: $t('client.keyword')
                            })
                        }}
                    </span>
                </div>
            </div>
            <div class="form-group row">
                <label class="col-sm-3">メモ欄</label>
                <div class="col-sm-9">
                    <textarea class="form-control" v-model="form.memo"></textarea>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-sm-3" for="ChatworkRoom">Chatwork Room </label>
                <div class="col-sm-8">
                    <div v-for="(itemChat, idx) in form.chatwork_rooms" :key="idx" class="d-flex align-items-center mb-3">
                        <div class="col-sm-12 px-0">
                            <input
                                id="ChatworkRoom"
                                v-model.number="itemChat.room_id"
                                type="number"
                                class="form-control mb-3"
                                placeholder="チャットルームID"
                                @keypress="handleNumber($event)"
                            />
                            <input
                                id="ChatworkRoom"
                                v-model="itemChat.room_name"
                                type="text"
                                class="form-control"
                                :class="{
                                    'is-invalid': submitted && validate.form.chatwork_rooms.$each[idx].room_name.$error
                                }"
                                placeholder="チャット名"
                            />
                            <div v-if="submitted && validate.form.chatwork_rooms.$error" class="invalid-feedback">
                                <span v-if="!validate.form.chatwork_rooms.$each[idx].room_name.maxLength">
                                    {{
                                        $t('validateField.max255', {
                                            field: ''
                                        })
                                    }}
                                </span>
                            </div>
                        </div>

                        <div class="col-sm-1">
                            <button
                                type="button"
                                class="btn btn-outline-info btn-sm"
                                style="border-radius: 50%"
                                v-if="idx <= 0"
                                @click="addChatRoom()"
                            >
                                <i class="fa fa-plus"></i>
                            </button>
                            <button
                                type="button"
                                class="btn btn-sm btn-outline-danger"
                                style="border-radius: 50%"
                                v-if="idx > 0"
                                @click="removeChatRoom(idx)"
                            >
                                <i class="fa fa-minus"></i>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <div class="form-group row">
                <label class="col-sm-3">{{ this.$t('pageCampaign.associated_campaign_ID') }}</label>
                <div class="col-sm-9">
                    <div class="d-flex align-items-center">
                        <MultiSelectCommon
                            :id="`select-associated-campaign-id`"
                            :value.sync="form.associated_campaign"
                            :options="listAssociatedCampaign"
                            :config="configAssociatedCampaign"
                            @open="handleOpenAssociatedCampaign()"
                        />

                        <div v-if="form.associated_campaign" class="ml-3">
                            <a target="_blank" :href="`/campaign/form/view?id=${form.associated_campaign.id}`" style="font-size: 20px">
                                <i class="ri-links-line"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <div class="form-group row">
                    <label class="col-sm-3">{{ $t('pageCampaign.ipInformationItem') }}</label>
                    <div class="col-sm-9 d-flex flex-wrap" style="gap: 12px">
                        <div class="custom-control custom-checkbox pl-0" v-for="itemIP in listIPInformationItem" :key="itemIP.id">
                            <InputCheckBox
                                :model.sync="listCheckedIPInfo"
                                :config="{
                                    id: `check_IP--info${itemIP.id}`,
                                    value: itemIP.id,
                                    label: itemIP.value
                                }"
                                @change="handleChangeIPInfo(itemIP)"
                            />
                        </div>
                    </div>
                </div>
                <template>
                    <div v-for="(ipsItem, idx) in form.information_ips" :key="`ips_${idx}`" class="form-group row">
                        <label class="col-sm-3">{{ ipsItem.value }}</label>
                        <div class="col-sm-9">
                            <textarea rows="3" v-model="ipsItem.note.detail" class="form-control"></textarea>
                        </div>
                    </div>
                </template>
            </div>
        </div>

        <ModalCommon id="ModalOrder" :config="configModalCommon" @save="handleSaveScopeOrder()" @close="handleCloseScopeOrder()">
            <form class="needs-validation" autocomplete="off">
                <div class="form-group row">
                    <label class="col-sm-2 mw--form" for="validationCustom05">受注範囲</label>
                    <div class="col-sm-9">
                        <div class="d-flex flex-wrap">
                            <div
                                class="custom-control custom-checkbox mr-4 py-1"
                                style="min-width: 100px"
                                v-for="orderLv1 in listMasterOrderScope"
                                :key="orderLv1.id"
                            >
                                <InputCheckBox
                                    :model.sync="listChecked.msOrderLv1"
                                    :config="{
                                        id: `check_app${orderLv1.id}`,
                                        value: orderLv1.id,
                                        label: orderLv1.value
                                    }"
                                    @change="handleChangeOrderLv1(orderLv1)"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="listChecked.msOrderLv1.length">
                    <div
                        v-for="itemFilterLv1 in listOrderFilterLv1"
                        :key="`${itemFilterLv1.id}-filter1`"
                        class="py-3 mt-3"
                        style="box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px; border-radius: 10px"
                    >
                        <div>
                            <div class="ml-1 my-4">
                                <label class="custom-control px-3">{{ itemFilterLv1.value }}</label>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-1"></div>
                                <div class="col-sm-11 row">
                                    <div v-for="(dataFilterLv1, idxF1) in itemFilterLv1.data" :key="`${idxF1}-medium_item_filter1`">
                                        <InputCheckBox
                                            class="mr-4 py-1"
                                            style="min-width: 100px"
                                            :model.sync="listChecked.msOrderLv2"
                                            :config="{
                                                id: `check_app${dataFilterLv1.id}`,
                                                value: dataFilterLv1.id,
                                                label: dataFilterLv1.value
                                            }"
                                            @change="handleChangeOrderLv2(dataFilterLv1)"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div v-for="itemFilterLv2 in listOrderFilterLv2" :key="`${itemFilterLv2.id}-filter2`">
                            <div v-if="handleVisibleLv2(itemFilterLv1, itemFilterLv2)">
                                <div v-if="itemFilterLv2.data.length > 0" style="border-top: 1px solid rgb(181 180 180 / 50%)">
                                    <div class="ml-4 my-4">
                                        <label class="custom-control px-2"
                                            >{{ itemFilterLv1.value }} <span class="font-weight-bold px-1">/</span> {{ itemFilterLv2.value }}</label
                                        >
                                    </div>

                                    <div class="form-group row">
                                        <div class="col-sm-1"></div>
                                        <div class="col-sm-11 row">
                                            <div v-for="(dataFilterLv2, idxF2) in itemFilterLv2.data" :key="`${idxF2}-medium_item_filter2`">
                                                <div v-if="handleShowOptionOneFilterLv2(itemFilterLv2)">
                                                    <InputRadio
                                                        class="mr-4"
                                                        :model.sync="listRadio.msOrderLv2[itemFilterLv2.id]"
                                                        :config="{
                                                            id: `radio-${dataFilterLv2.id}`,
                                                            value: dataFilterLv2.id,
                                                            label: dataFilterLv2.value,
                                                            name: `msOrderLv2-${itemFilterLv2.id}`
                                                        }"
                                                    />
                                                </div>
                                                <div v-else>
                                                    <InputCheckBox
                                                        class="mr-4 py-1"
                                                        style="min-width: 100px"
                                                        :model.sync="listChecked.msOrderLv3"
                                                        :config="{
                                                            id: `check_app${dataFilterLv2.id}`,
                                                            value: dataFilterLv2.id,
                                                            label: dataFilterLv2.value
                                                        }"
                                                        @change="handleChangeOrderLv3(dataFilterLv2)"
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div v-for="itemFilterLv3 in listOrderFilterLv3" :key="`${itemFilterLv3.id}-filter3`">
                                <div v-if="handleVisibleLv3(itemFilterLv1, itemFilterLv2, itemFilterLv3)">
                                    <div v-if="itemFilterLv3.data.length > 0" style="border-top: 1px solid rgb(181 180 180 / 50%)">
                                        <div class="ml-4 my-4">
                                            <label class="custom-control"
                                                >{{ itemFilterLv1.value }} <span class="font-weight-bold px-1">/</span> {{ itemFilterLv2.value }}
                                                <span class="font-weight-bold px-1">/</span> {{ itemFilterLv3.value }}</label
                                            >
                                        </div>

                                        <div class="form-group row">
                                            <div class="col-sm-1"></div>
                                            <div class="col-sm-11 row">
                                                <div v-for="(dataFilterLv3, idxF3) in itemFilterLv3.data" :key="`${idxF3}-medium_item_filter3`">
                                                    <div v-if="handleShowOptionOneFilterLv3(itemFilterLv3)">
                                                        <InputRadio
                                                            class="mr-4"
                                                            :model.sync="listRadio.msOrderLv4[itemFilterLv3.id]"
                                                            :config="{
                                                                id: `radio-filter-${dataFilterLv3.id}`,
                                                                value: dataFilterLv3.id,
                                                                label: dataFilterLv3.value,
                                                                name: `radio-filter-lv4-${itemFilterLv3.id}`
                                                            }"
                                                        />
                                                    </div>
                                                    <div v-else>
                                                        <InputCheckBox
                                                            class="mr-4 py-1"
                                                            style="min-width: 100px"
                                                            :model.sync="listChecked.msOrderLv4"
                                                            :config="{
                                                                id: `check_app${dataFilterLv3.id}`,
                                                                value: dataFilterLv3.id,
                                                                label: dataFilterLv3.value
                                                            }"
                                                            @change="handleChangeOrderLv4(dataFilterLv3)"
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </ModalCommon>
    </div>
</template>
<script>
import KeyWordTag from '@/components/KeywordTag/main.vue';
import DateTimeCommon from '@/components/Select/date-time/main.vue';
import DatePickerCommon from '@/components/Select/date/main.vue';
import MultiSelectCommon from '@/components/Select/multi/main.vue';
import ModalCommon from '@/components/Modal/main.vue';
import { InputCheckBox, InputRadio, InputText } from '@/components/Input';

import { campaignMethods, masterMethods } from '@/state/helpers';
import { isNumber } from '@/utils/format';
import { listContactInformations } from '@/config/var-common';
import {
    funcSetCondition1,
    funcOrderLv1,
    funcOrderLv2,
    funcOrderLv3,
    funcOrderLv4,
    funcOptionOneFilterLv2,
    funcOptionOneFilterLv3,
    funcMapDataRegisted
} from './index';

export default {
    name: 'tab1',
    components: {
        KeyWordTag,
        DateTimeCommon,
        DatePickerCommon,
        MultiSelectCommon,
        ModalCommon,
        InputCheckBox,
        InputRadio,
        InputText
    },
    props: {
        campaignStatus: {
            type: Array
        },
        progressStatusS: {
            type: Object
        },
        listDataMasterCampaignProject: {
            type: Array
        },
        listDataMasterCampaignType: {
            type: Array
        },
        listDataMasterCampaignTypeChilds: {
            type: Array
        },
        listDataMasterSaleStaf: {
            type: Array
        },
        listItemPersons: {
            type: Array
        },
        listCategoriesSubs: {
            type: Array
        },
        configKeyword: {
            type: Object
        },
        listCategoriesS: {
            type: Array
        },
        contact_informations: {
            type: Object
        },
        hashTag: {
            type: Object
        },
        dataObject: {
            type: Object
        },
        purchase_conditions: {
            type: Object
        },
        target_product_lists: {
            type: Object
        },
        snsApplicationCondition1: {
            type: Object
        },
        submitted: {
            type: Boolean,
            required: true
        },
        validate: {
            type: Object
        },
        listDataMasterConditionApplySNS: {
            type: Array
        },
        listDataMasterConditionPurchase1: {
            type: Array
        },
        listDataMasterCategoryProductTarget: {
            type: Array
        },
        listDataMasterOrderScope: {
            type: Array
        },
        listIPInformationItem: {
            type: Array
        }
    },
    data: function () {
        return {
            form: this.dataObject,
            listCategoriesSub: this.listCategoriesSubs,
            progressStatus: this.progressStatusS,
            listDataMasterCampaignTypeChild: this.listDataMasterCampaignTypeChilds,
            listCategories: this.listCategoriesS,
            sns_application_condition_1: {},
            listContactInformations: listContactInformations,
            purchaseConditions: this.purchase_conditions,
            targetProductLists: this.target_product_lists,
            listMasterOrderScope: this.listDataMasterOrderScope,
            listOrderFilterLv1: [],
            listOrderFilterLv2: [],
            listOrderFilterLv3: [],
            listOrderFilterLv4: [],
            listChecked: {
                msOrderLv1: [],
                msOrderLv2: [],
                msOrderLv3: [],
                msOrderLv4: []
            },
            listRadio: {
                msOrderLv2: {},
                msOrderLv4: {}
            },
            configModalCommon: {
                title: '受注範囲',
                model: false,
                scrollable: true,
                hideFooter: false,
                class: 'modal-custom-tab1-cp'
            },
            listAssociatedCampaign: [],
            configAssociatedCampaign: {
                trackBy: 'id',
                label: 'name',
                error: false,
                isRemove: false,
                loading: false,
                openDirection: 'top'
            },
            listCheckedIPInfo: []
        };
    },
    watch: {
        progressStatus: function (newName) {
            this.selectObject(newName, 'progress_status');
        },
        listCategoriesSubs(newValue) {
            this.listCategoriesSub = newValue;
        },
        listDataMasterCampaignTypeChilds(newValue) {
            this.listDataMasterCampaignTypeChild = newValue;
        },
        listCategoriesS: {
            handler: function (newValue) {
                this.listCategories = newValue;
            },
            immediate: true,
            deep: true
        },
        progressStatusS(newValue) {
            this.progressStatus = newValue;
        },
        dataObject(newValue) {
            this.form = newValue;
        },
        snsApplicationCondition1(newValue) {
            this.sns_application_condition_1 = newValue;
        },
        'dataObject.campaign_type': {
            handler: function () {
                // 81 close , 513 open
                if (this.form.campaign_type === 81) {
                    this.form.isShowReceiptExpirationDate = true;
                    if (this.listCategories.length > 0) {
                        const findObjCondition1 = this.listCategoriesSub.find((item) => item.application_condition_1);
                        if (findObjCondition1) {
                            if (findObjCondition1.listCondition2.length > 0 && findObjCondition1.application_condition_2_id.length > 0) {
                                this.handleShowConditionBuyAndSell('watch');
                            }
                        }
                    }
                } else {
                    this.form.isShowReceiptExpirationDate = false;
                }
            },
            immediate: true
        },
        'dataObject.campaign_master_order_scopes': {
            handler: function () {
                this.handleMapDataRegisted();
            },
            immediate: true,
            deep: true
        },
        'dataObject.information_ips': {
            handler: function () {
                this.listCheckedIPInfo = this.dataObject.information_ips?.map((item) => item.master_id) ?? [];
            },
            immediate: true,
            deep: true
        }
    },
    mounted() {},
    methods: {
        ...campaignMethods,
        ...masterMethods,
        selectObject(data, key, key1) {
            if (data) {
                if (key1) this.form[key][key1] = data.id;
                else this.form[key] = data.id;
            } else {
                if (key1) this.form[key][key1] = '';
                else this.form[key] = '';
            }
        },

        deleteCategory(item) {
            let i = this.listCategories.findIndex((x) => {
                return x == item.category_id;
            });
            if (i >= 0) {
                this.listCategories.splice(i, 1);

                let index = this.listCategoriesSub.findIndex((x) => {
                    return x.category_id == item.category_id;
                });
                if (index >= 0) {
                    this.listCategoriesSub.splice(index, 1);
                }
            }
        },

        setCondition1() {
            const resultSet1 = funcSetCondition1(this.listCategoriesSub, this.listCategories, this.listDataMasterCampaignTypeChild, this.form);
            this.listCategoriesSub = resultSet1.listCategoriesSub;
            this.listCategories = resultSet1.listCategories;
            this.listDataMasterCampaignTypeChild = resultSet1.listDataMasterCampaignTypeChild;
            this.form = resultSet1.form;
            this.$emit('update:listCategoriesSubs', this.listCategoriesSub);
            this.handleShowConditionBuyAndSell('change');
        },

        setCondition2(item) {
            if (!item.application_condition_1) {
                return;
            }
            item.application_condition_2_id = [];
            let index = item.listCondition1.findIndex((x) => {
                return x.id == item.application_condition_1.id;
            });
            if (index >= 0) {
                item.listCondition2 = item.listCondition1[index].data;
            }
        },

        handleRemove(item) {
            item.application_condition_2_id = [];
            this.handleShowConditionBuyAndSell('change');
        },

        getDataCampaignTye() {
            this.listCategoriesSub = [];
            this.listCategories = [];
            this.form.isShowConditionBuyAndSell = false;
            this.form.purchase_conditions = [];
            let index = this.listDataMasterCampaignType.findIndex((x) => {
                return x.id == this.form.campaign_type;
            });
            if (index >= 0) {
                this.listDataMasterCampaignTypeChild = this.listDataMasterCampaignType[index].data;
            }
            // 81 close , 513 open
            this.form.isShowReceiptExpirationDate = this.form.campaign_type === 81;
            this.form.receipt_expiration_date = '';
            this.form.target_product_lists = [];
            this.targetProductLists = {};
        },

        renderNameCategory(item) {
            let index = this.listDataMasterCampaignTypeChild.findIndex((x) => {
                return x.id == item.category_id;
            });
            if (index >= 0) {
                return this.listDataMasterCampaignTypeChild[index].value;
            }
        },

        checkShow(type) {
            let i = this.listDataMasterCampaignType.findIndex((x) => {
                return x.id == type;
            });
            if (i >= 0 && this.listDataMasterCampaignType[i].value != 'オープン') {
                return true;
            }
            return false;
        },

        handleNumber(e) {
            isNumber(e);
        },

        addChatRoom() {
            this.form.chatwork_rooms.push({
                room_id: '',
                room_name: ''
            });
        },

        removeChatRoom(k) {
            this.form.chatwork_rooms.splice(k, 1);
        },

        renderNamePurchase(i) {
            let index = this.listDataMasterConditionPurchase1.findIndex((x) => {
                return x.id == i;
            });
            if (index >= 0) {
                return this.listDataMasterConditionPurchase1[index].value;
            }
            return '';
        },

        renderNameTargetProduct(i) {
            let index = this.listDataMasterCategoryProductTarget.findIndex((x) => {
                return x.id == i;
            });
            if (index >= 0) {
                return this.listDataMasterCategoryProductTarget[index].value;
            }
            return '';
        },

        handleShowPublicTime() {
            // 95 LPサイト
            if (this.listChecked.msOrderLv3.includes(95)) {
                this.form.isShowPublicTime = true;
            } else {
                this.form.isShowPublicTime = false;
                this.form.url_publish_time_from = '';
                this.form.url_publish_time_to = '';
            }
        },

        handleShowConditionBuyAndSell(typeHandle = null) {
            if (this.form.campaign_type !== 81) return;
            if (typeHandle === 'change') {
                if (!this.form.isShowConditionBuyAndSell) {
                    this.form.purchase_conditions = [];
                    this.purchaseConditions = {};
                }
            }

            this.dataApplycation2Type1 = this.listCategoriesSub.map((item) => item.listCondition2).flat();
            this.listIDSelectApplycation2Type1 = this.listCategoriesSub
                .map((item) => item.application_condition_2_id)
                .flat()
                .map(Number);

            const listSelected = this.dataApplycation2Type1.filter((itemAPT2) => this.listIDSelectApplycation2Type1.includes(itemAPT2.id));
            if (!listSelected.length) {
                this.form.isShowConditionBuyAndSell = false;
            } else {
                const listValue = listSelected.map((itemSelected) => itemSelected.value);
                this.form.isShowConditionBuyAndSell = listValue.includes('WEB明細') || listValue.includes('レシート（購買証明）');
            }
        },

        handleClearDataTargetProduct(item) {
            if (!this.form.target_product_lists.includes(item.id)) {
                this.targetProductLists[item.id] = '';
            }
        },

        handleClearDataPurchaseConditions(item) {
            if (!this.form.purchase_conditions.includes(item.id)) {
                this.purchaseConditions[item.id] = '';
            }
        },

        handleChangeOrderLv1(itemLv1) {
            const resultLv1 = funcOrderLv1(
                itemLv1,
                this.listChecked,
                this.listOrderFilterLv1,
                this.listOrderFilterLv2,
                this.listOrderFilterLv3,
                this.listOrderFilterLv4,
                this.listRadio,
                this.contact_informations
            );
            this.listChecked = resultLv1.listChecked;
            this.listOrderFilterLv1 = resultLv1.listOrderFilterLv1;
            this.listOrderFilterLv2 = resultLv1.listOrderFilterLv2;
            this.listOrderFilterLv3 = resultLv1.listOrderFilterLv3;
            this.listOrderFilterLv4 = resultLv1.listOrderFilterLv4;
            this.listRadio = resultLv1.listRadio;
            this.contact_informations = resultLv1.contact_informations;
            this.handleShowPublicTime();
        },

        handleChangeOrderLv2(itemLv2) {
            const resultLv2 = funcOrderLv2(
                itemLv2,
                this.listChecked,
                this.listOrderFilterLv2,
                this.listOrderFilterLv3,
                this.listOrderFilterLv4,
                this.listRadio,
                this.contact_informations
            );
            this.listChecked = resultLv2.listChecked;
            this.listOrderFilterLv2 = resultLv2.listOrderFilterLv2;
            this.listOrderFilterLv3 = resultLv2.listOrderFilterLv3;
            this.listOrderFilterLv4 = resultLv2.listOrderFilterLv4;
            this.listRadio = resultLv2.listRadio;
            this.contact_informations = resultLv2.contact_informations;
            this.handleShowPublicTime();
        },

        handleChangeOrderLv3(itemLv3) {
            const resultLv3 = funcOrderLv3(
                itemLv3,
                this.listChecked,
                this.listOrderFilterLv3,
                this.listOrderFilterLv4,
                this.listRadio,
                this.contact_informations
            );
            this.listChecked = resultLv3.listChecked;
            this.listOrderFilterLv3 = resultLv3.listOrderFilterLv3;
            this.listOrderFilterLv4 = resultLv3.listOrderFilterLv4;
            this.listRadio = resultLv3.listRadio;
            this.contact_informations = resultLv3.contact_informations;
            this.handleShowPublicTime();
        },

        handleChangeOrderLv4(itemLv4) {
            const resultLv4 = funcOrderLv4(itemLv4, this.listChecked, this.listOrderFilterLv4, this.contact_informations);
            this.listChecked = resultLv4.listChecked;
            this.listOrderFilterLv4 = resultLv4.listOrderFilterLv4;
            this.contact_informations = resultLv4.contact_informations;
        },

        handleVisibleLv2(itemLv1, itemLv2) {
            const result = itemLv1.data.map((item) => item.id).includes(itemLv2.id);
            return result;
        },

        handleVisibleLv3(itemLv1, itemLv2, itemLv3) {
            const resultItemLv2 = itemLv2.data.find((item) => item.id === itemLv3.id);
            if (resultItemLv2) {
                return resultItemLv2.origin_parent_id === itemLv1.id;
            }
        },

        handleSaveScopeOrder() {
            this.$emit('saveScopeOrder', {
                scopeChecked: this.listChecked,
                scopeRadio: this.listRadio
            });
            this.handleShowContactInformation();
            this.handleShowPublicTime();
        },

        handleCloseScopeOrder() {
            this.listChecked = {
                msOrderLv1: [],
                msOrderLv2: [],
                msOrderLv3: [],
                msOrderLv4: []
            };
            this.listRadio = {
                msOrderLv2: {},
                msOrderLv4: {}
            };
            this.listOrderFilterLv1 = [];
            this.listOrderFilterLv2 = [];
            this.listOrderFilterLv3 = [];
            this.listOrderFilterLv4 = [];

            this.handleMapDataRegisted();
        },

        handleShowOptionOneFilterLv2(itemFilterLv2) {
            return funcOptionOneFilterLv2(itemFilterLv2);
        },

        handleShowOptionOneFilterLv3(itemFilterLv3) {
            return funcOptionOneFilterLv3(itemFilterLv3);
        },

        handleOpenScopeOrder() {
            this.configModalCommon.model = true;
            this.listOrderFilterLv1 = [];
            this.listOrderFilterLv2 = [];
            this.listOrderFilterLv3 = [];
            this.listOrderFilterLv4 = [];

            // check data be trả về
            this.listChecked.msOrderLv1.forEach((idOrigin) => {
                const obj = this.listDataMasterOrderScope.find((x) => x.id === idOrigin);
                if (obj) {
                    this.handleChangeOrderLv1(obj);
                    obj.data.forEach((lv1) => {
                        this.handleChangeOrderLv2(lv1);
                        lv1.data.forEach((lv2) => {
                            this.handleChangeOrderLv3(lv2);
                        });
                    });
                }
            });
        },

        handleMapDataRegisted() {
            funcMapDataRegisted(
                this.form,
                this.listChecked,
                this.listRadio,
                this.listOrderFilterLv1,
                this.listOrderFilterLv2,
                this.listOrderFilterLv3,
                this.listOrderFilterLv4
            );
            this.$emit('saveScopeOrder', {
                scopeChecked: this.listChecked,
                scopeRadio: this.listRadio
            });
            this.handleShowContactInformation();
            this.handleShowPublicTime();
        },

        handleShowContactInformation() {
            if (this.listChecked.msOrderLv3.length > 0) {
                let childLv3 = this.listChecked.msOrderLv3.filter((item) => item === 3 || item === 6 || item == 9 || item == 14);
                let childLv4 = this.listChecked.msOrderLv4.filter((item) => item === 10 || item === 11 || item == 12 || item == 13);

                this.form.contact_informations = [...childLv3, ...childLv4];
            } else {
                this.form.contact_informations = [];
            }
        },

        async handleOpenAssociatedCampaign() {
            if (this.configAssociatedCampaign.loading || this.listAssociatedCampaign.length > 0) return;
            this.configAssociatedCampaign.loading = true;
            try {
                const dataRes = await this.listMaster('all/campaigns');
                if (dataRes && dataRes.length > 0) {
                    this.listAssociatedCampaign = dataRes;
                }
            } catch (error) {
                console.log('error', error);
            } finally {
                this.configAssociatedCampaign.loading = false;
            }
        },

        handleChangeIPInfo(itemIP) {
            if (this.listCheckedIPInfo.includes(itemIP.id)) {
                this.form.information_ips.push({
                    value: itemIP.value,
                    master_id: itemIP.id,
                    note: {
                        detail: ''
                    }
                });
                this.form.information_ips = this.form.information_ips.sort((ipA, ipB) => ipA.master_id - ipB.master_id);
            } else {
                this.form.information_ips = this.form.information_ips.filter((item) => item.master_id !== itemIP.id);
            }
        }
    },
    computed: {}
};
</script>

<style lang="scss">
.modal-custom-tab1-cp {
    .modal-content {
        height: 100vh;
    }
}
</style>
