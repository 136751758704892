var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-modal',{attrs:{"no-close-on-backdrop":"","id":"modal-agency","title":"新規登録","title-class":"font-18","scrollable":"","hide-footer":""}},[_c('form',{staticClass:"needs-validation",attrs:{"autocomplete":"off"},on:{"submit":function($event){$event.preventDefault();return _vm.formSubmitAgency.apply(null, arguments)}}},[_c('div',{staticClass:"form-group row align-items-center"},[_c('label',{staticClass:"col-sm-3 mw--form"},[_vm._v(_vm._s(_vm.$t('agency.name'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-sm-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formAgency.name),expression:"formAgency.name"}],staticClass:"form-control",class:{
                        'is-invalid': _vm.submittedAgency && _vm.$v.formAgency.name.$error
                    },attrs:{"id":"validationCustom05","type":"text"},domProps:{"value":(_vm.formAgency.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formAgency, "name", $event.target.value)}}}),(_vm.submittedAgency && _vm.$v.formAgency.name.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.formAgency.name.required)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.required', { field: _vm.$t('agency.name') })))]):_vm._e(),(!_vm.$v.formAgency.name.maxLength)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('validateField.max255', { field: _vm.$t('agency.name') }))+" ")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group row align-items-center"},[_c('label',{staticClass:"col-sm-3 mw--form"},[_vm._v(_vm._s(_vm.$t('agency.kana_nameCompany'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-sm-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formAgency.kana_name),expression:"formAgency.kana_name"}],staticClass:"form-control",class:{
                        'is-invalid': _vm.submittedAgency && _vm.$v.formAgency.kana_name.$error
                    },attrs:{"id":"validationCustom05","type":"text"},domProps:{"value":(_vm.formAgency.kana_name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formAgency, "kana_name", $event.target.value)}}}),(_vm.submittedAgency && _vm.$v.formAgency.kana_name.$error)?_c('div',{staticClass:"invalid-feedback",class:{
                        'is-invalid': _vm.submittedAgency && _vm.$v.formAgency.kana_name.$error
                    }},[(!_vm.$v.formAgency.kana_name.required)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.required', { field: _vm.$t('agency.kana_nameCompany') })))]):_vm._e(),(!_vm.$v.formAgency.kana_name.katakana)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.incorrectFormat', { field: _vm.$t('agency.kana_nameCompany') })))]):_vm._e(),(!_vm.$v.formAgency.kana_name.maxLength)?_c('span',[_vm._v(" "+_vm._s(_vm.$t('validateField.max255', { field: _vm.$t('agency.kana_nameCompany') }))+" ")]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group row align-items-center"},[_c('label',{staticClass:"col-sm-3 mw--form"},[_vm._v(_vm._s(_vm.$t('agency.types'))+" "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-sm-9"},[_c('div',{staticClass:"d-flex flex-wrap"},_vm._l((_vm.listTypeAgency),function(item){return _c('div',{key:item.id,staticClass:"custom-control custom-checkbox mr-4 py-1",staticStyle:{"min-width":"100px"}},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formAgency.types),expression:"formAgency.types"}],staticClass:"custom-control-input",attrs:{"type":"checkbox","id":`check${item.id}`},domProps:{"value":item.id,"checked":Array.isArray(_vm.formAgency.types)?_vm._i(_vm.formAgency.types,item.id)>-1:(_vm.formAgency.types)},on:{"change":function($event){var $$a=_vm.formAgency.types,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=item.id,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.formAgency, "types", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.formAgency, "types", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.formAgency, "types", $$c)}}}}),_c('label',{staticClass:"custom-control-label",attrs:{"for":`check${item.id}`}},[_vm._v(_vm._s(item.value))])])}),0),_c('span',{class:{
                        'is-invalid': _vm.submittedAgency && _vm.$v.formAgency.types.$error
                    }}),(_vm.submittedAgency && _vm.$v.formAgency.types.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.formAgency.types.required)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.required', { field: _vm.$t('agency.types') })))]):_vm._e()]):_vm._e()])]),_c('div',{staticClass:"form-group row align-items-center"},[_c('label',{staticClass:"col-sm-3"},[_vm._v("担当者"),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('div',{staticClass:"col-sm-9"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.formAgency.namePic),expression:"formAgency.namePic"}],staticClass:"form-control",class:{
                        'is-invalid': _vm.submittedAgency && _vm.$v.formAgency.namePic.$error
                    },attrs:{"id":"validationCustom05","type":"text"},domProps:{"value":(_vm.formAgency.namePic)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.formAgency, "namePic", $event.target.value)}}}),(_vm.submittedAgency && _vm.$v.formAgency.namePic.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.formAgency.namePic.required)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.required', { field: _vm.$t('agency.namePic') })))]):_vm._e(),(!_vm.$v.formAgency.namePic.maxLength)?_c('span',[_vm._v(_vm._s(_vm.$t('validateField.max255', { field: _vm.$t('agency.namePic') })))]):_vm._e()]):_vm._e()])]),_c('div',[_c('div',{staticClass:"d-flex justify-content-center"},[_c('div',{staticClass:"text-center flex-fill"},[_c('button',{staticClass:"btn btn-primary ml-3",attrs:{"type":"submit"}},[_vm._v(" "+_vm._s(_vm.$t('btn.register'))+" ")])])])])])])
}
var staticRenderFns = []
render._withStripped = true
export { render, staticRenderFns }