import i18n from '@/i18n';
import { isValidDate } from '@/state/helpers';
import { listContactInformations } from '@/config/var-common';
import { isValidEmail } from '@/utils/format';

function getDateFromFullDate(fullDate) {
    let day = fullDate.split('-')[2];
    let month = fullDate.split('-')[1];
    let year = fullDate.split('-')[0];
    return isValidDate(day + '/' + month + '/' + year);
}
function handleValidateTab1(
    newDataInitForm,
    configCampaignStartDateTime,
    configCampaignEndDateTime,
    configSecretariatStartDateTime,
    configSecretariatEndDateTime,
    configUrlPublishTimeFrom,
    configUrlPublishTimeTo,
    configReceiptExpirationDate,
    contactInformations
) {
    let check = true;

    // Validate campaign
    if (newDataInitForm.campaign_start_date) {
        if (!getDateFromFullDate(newDataInitForm.campaign_start_date)) {
            check = false;
            configCampaignStartDateTime.error = true;
            configCampaignStartDateTime.errorDateTimeStartCampaign = i18n.t('validateField.incorrectFormat', {
                field: 'キャンペーン開始年月日'
            });
        }
    }

    if (newDataInitForm.campaign_end_date) {
        if (!getDateFromFullDate(newDataInitForm.campaign_end_date)) {
            check = false;
            configCampaignEndDateTime.error = true;
            configCampaignEndDateTime.errorDateTimeEndCampaign = i18n.t('validateField.incorrectFormat', {
                field: 'キャンペーン終了年月日'
            });
        }
    }

    if (newDataInitForm.campaign_start_date && newDataInitForm.campaign_end_date) {
        const startDateObj = new Date(newDataInitForm.campaign_start_date);
        const endDateObj = new Date(newDataInitForm.campaign_end_date);
        if (newDataInitForm.campaign_start_time && newDataInitForm.campaign_end_time) {
            let startDateTime = new Date(newDataInitForm.campaign_start_date + ' ' + newDataInitForm.campaign_start_time);
            let endDateTime = new Date(newDataInitForm.campaign_end_date + ' ' + newDataInitForm.campaign_end_time);
            if (startDateTime >= endDateTime) {
                check = false;
                configCampaignStartDateTime.error = true;
                configCampaignStartDateTime.errorDateTimeStartCampaign = '事務局終了年月日の形式は正しくありません。';
            } else {
                configCampaignStartDateTime.error = false;
                configCampaignStartDateTime.errorDateTimeStartCampaign = '';
            }
        } else {
            if (startDateObj.getTime() >= endDateObj.getTime()) {
                check = false;
                configCampaignStartDateTime.error = true;
                configCampaignStartDateTime.errorDateTimeStartCampaign = '事務局終了年月日の形式は正しくありません。';
            }
        }
    }

    // Validate secretariat
    if (newDataInitForm.secretariat_start_date) {
        if (!getDateFromFullDate(newDataInitForm.secretariat_start_date)) {
            check = false;
            configSecretariatStartDateTime.error = true;
            configSecretariatStartDateTime.errorDateTimeStartSecretariat = i18n.t('validateField.incorrectFormat', {
                field: '事務局開始年月日'
            });
        }
    }

    if (newDataInitForm.secretariat_end_date) {
        if (!getDateFromFullDate(newDataInitForm.secretariat_end_date)) {
            check = false;
            configSecretariatEndDateTime.error = true;
            configSecretariatEndDateTime.errorDateTimeEndSecretariat = i18n.t('validateField.incorrectFormat', {
                field: '事務局終了年月日'
            });
        }
    }

    if (newDataInitForm.secretariat_start_date && newDataInitForm.secretariat_end_date) {
        const startDateObj = new Date(newDataInitForm.secretariat_start_date);
        const endDateObj = new Date(newDataInitForm.secretariat_end_date);

        if (newDataInitForm.secretariat_start_time && newDataInitForm.secretariat_end_time) {
            let startDateTime = new Date(newDataInitForm.secretariat_start_date + ' ' + newDataInitForm.secretariat_start_time);
            let endDateTime = new Date(newDataInitForm.secretariat_end_date + ' ' + newDataInitForm.secretariat_end_time);
            if (startDateTime >= endDateTime) {
                check = false;
                configSecretariatStartDateTime.error = true;
                configSecretariatStartDateTime.errorDateTimeStartSecretariat = '事務局終了年月日の形式は正しくありません。';
            } else {
                configSecretariatStartDateTime.error = false;
                configSecretariatStartDateTime.errorDateTimeStartSecretariat = '';
            }
        } else {
            if (startDateObj.getTime() >= endDateObj.getTime()) {
                check = false;
                configSecretariatStartDateTime.error = true;
                configSecretariatStartDateTime.errorDateTimeStartSecretariat = '事務局終了年月日の形式は正しくありません。';
            }
        }
    }

    // Validate url publish from
    if (newDataInitForm.url_publish_time_from) {
        if (!getDateFromFullDate(newDataInitForm.url_publish_time_from)) {
            check = false;
            configUrlPublishTimeFrom.error = true;
            configUrlPublishTimeFrom.errorText = i18n.t('validateField.incorrectFormat', {
                field: '公開期間'
            });
        }
    }

    // Validate url publish to
    if (newDataInitForm.url_publish_time_to) {
        if (!getDateFromFullDate(newDataInitForm.url_publish_time_to)) {
            check = false;
            configUrlPublishTimeTo.error = true;
            configUrlPublishTimeTo.errorText = i18n.t('validateField.incorrectFormat', {
                field: '公開期間'
            });
        }
    }

    if (newDataInitForm.url_publish_time_from && newDataInitForm.url_publish_time_to) {
        const startDateObj = new Date(newDataInitForm.url_publish_time_from);
        const endDateObj = new Date(newDataInitForm.url_publish_time_to);

        if (newDataInitForm.url_publish_time_from_time && newDataInitForm.url_publish_time_to_time) {
            let startDateTime = new Date(newDataInitForm.url_publish_time_from + ' ' + newDataInitForm.url_publish_time_from_time);
            let endDateTime = new Date(newDataInitForm.url_publish_time_to + ' ' + newDataInitForm.url_publish_time_to_time);
            if (startDateTime >= endDateTime) {
                check = false;
                configUrlPublishTimeFrom.error = true;
                configUrlPublishTimeFrom.errorText = '開始日は終了日以下である必要があります。';
            } else {
                configSecretariatStartDateTime.error = false;
                configUrlPublishTimeFrom.errorText = '';
            }
        } else {
            if (startDateObj.getTime() >= endDateObj.getTime()) {
                check = false;
                configUrlPublishTimeFrom.error = true;
                configUrlPublishTimeFrom.errorText = '開始日は終了日以下である必要があります。';
            }
        }
    }

    // Validate receipt expiration date
    if (newDataInitForm.receipt_expiration_date) {
        if (!getDateFromFullDate(newDataInitForm.receipt_expiration_date)) {
            check = false;
            configReceiptExpirationDate.error = true;
            configReceiptExpirationDate.errorText = i18n.t('validateField.incorrectFormat', {
                field: 'レシート有効期限'
            });
        }
    }

    // Validate contact information
    listContactInformations.forEach((itemP) => {
        if (contactInformations && contactInformations[itemP.id]) {
            if (contactInformations[itemP.id].length >= 255) {
                check = false;
                itemP.configInput.isEmail = false;
                itemP.configInput.isMaxLength = true;
                itemP.configInput.error = true;
            } else {
                if (itemP.subText == 'メールアドレス入力') {
                    if (!isValidEmail(contactInformations[itemP.id])) {
                        itemP.configInput.isEmail = true;
                        itemP.configInput.error = true;
                        itemP.configInput.isMaxLength = false;
                        check = false;
                    }
                }
            }
        }

        if (itemP.child) {
            itemP.child.forEach((itemC) => {
                if (contactInformations && contactInformations[itemC.id] && contactInformations[itemC.id].length >= 255) {
                    check = false;
                    itemC.configInput.isMaxLength = true;
                    itemC.configInput.error = true;
                }
            });
        }
    });

    return check;
}

export { handleValidateTab1 };
